import React from 'react'
import { GameCard } from 'components'

function GamesJoinContent(props) {
  const { gamesCardsList } = props
  let color = ['team-2', 'team-5', 'team-1', 'team-3', 'team-4']
  const iterateOnColors = () => color.push(color.shift())

  return (
    <>
      <main className="site-content site-content--center page" id="wrapper">
        <div className="container container--large">
          <div className="page-heading page-heading--default text-center w-100">
            <div className="page-heading__subtitle h5">
              <span className="color-primary">please choose a title to join</span>
            </div>
            <h1 className="page-heading__title h2">Join A Competition</h1>
          </div>
          <div className="row mt-sm-auto mb-sm-auto d-flex justify-content-center">
            {
              gamesCardsList?.map((game) => {
                iterateOnColors()
                return <GameCard {...game} frameColor={color[0]} selectMode="JOIN" key={game?.overwolfId} />
              })
            }

            {/* temporary static code until other game titles will be ready   */}
            <div className="col-sm-6 col-lg-3 mb-3 py-4">
              <div className={`team-item team-item--v4 team-3`}>
                <div className="team-item__thumbnail">
                  <div className="team-item__bg-holder">
                    <div
                      className="team-item__bg"
                      style={{
                        backgroundImage:
                          "url(/assets/img/samples/team-selection-character-01-bg.jpg)"
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundImage:
                        `url("/assets/img/samples/team-selection-black-and-white-04.png")`
                    }}
                    className="team-item__img-primary"
                  />
                </div>
                <span className="team-item__subtitle h6 invisible">JOIN</span>
                <h2 className="team-item__title">Other titles<br /> coming soon</h2>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 py-4">
              <div className={`team-item team-item--v4 team-4`}>
                <div className="team-item__thumbnail">
                  <div className="team-item__bg-holder">
                    <div
                      className="team-item__bg"
                      style={{
                        backgroundImage:
                          "url(/assets/img/samples/team-selection-character-01-bg.jpg)"
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundImage:
                        `url("/assets/img/samples/team-selection-black-and-white-05.png")`
                    }}
                    className="team-item__img-primary"
                  />
                </div>
                <span className="team-item__subtitle h6 invisible">JOIN</span>
                <h2 className="team-item__title">Other titles<br /> coming soon</h2>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 py-4">
              <div className={`team-item team-item--v4 team-1`}>
                <div className="team-item__thumbnail">
                  <div className="team-item__bg-holder">
                    <div
                      className="team-item__bg"
                      style={{
                        backgroundImage:
                          "url(/assets/img/samples/team-selection-character-01-bg.jpg)"
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundImage:
                        `url("/assets/img/samples/team-selection-black-and-white-02.png")`
                    }}
                    className="team-item__img-primary"
                  />
                </div>
                <span className="team-item__subtitle h6 invisible">JOIN</span>
                <h2 className="team-item__title">Other titles<br /> coming soon</h2>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-3 py-4">
              <div className={`team-item team-item--v4 team-4`}>
                <div className="team-item__thumbnail">
                  <div className="team-item__bg-holder">
                    <div
                      className="team-item__bg"
                      style={{
                        backgroundImage:
                          "url(/assets/img/samples/team-selection-character-01-bg.jpg)"
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundImage:
                        `url("/assets/img/samples/team-selection-black-and-white-03.png")`
                    }}
                    className="team-item__img-primary"
                  />
                </div>
                <span className="team-item__subtitle h6 invisible">JOIN</span>
                <h2 className="team-item__title">Other titles<br /> coming soon</h2>
              </div>
            </div>


            <div className="col-sm-6 col-lg-3 mb-3 py-4">
              <div className={`team-item team-item--v4 team-3`}>
                <div className="team-item__thumbnail">
                  <div className="team-item__bg-holder">
                    <div
                      className="team-item__bg"
                      style={{
                        backgroundImage:
                          "url(/assets/img/samples/team-selection-character-01-bg.jpg)"
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundImage:
                        `url("/assets/img/samples/team-selection-black-and-white-06.png")`
                    }}
                    className="team-item__img-primary"
                  />
                </div>
                <span className="team-item__subtitle h6 invisible">JOIN</span>
                <h2 className="team-item__title">Other titles<br /> coming soon</h2>
              </div>
            </div>
            {/* -------------------------------------------------------------- */}

          </div>
        </div>
      </main>
    </>
  )
}

export { GamesJoinContent }
