import { web3Instance } from "../web3Instance"

export const usdcABI = [
   {
      "constant": true,
      "inputs": [

      ],
      "name": "name",
      "outputs": [
         {
            "name": "",
            "type": "string"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_spender",
            "type": "address"
         },
         {
            "name": "_value",
            "type": "uint256"
         }
      ],
      "name": "approve",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "totalSupply",
      "outputs": [
         {
            "name": "",
            "type": "uint256"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_account",
            "type": "address"
         }
      ],
      "name": "unBlacklist",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_from",
            "type": "address"
         },
         {
            "name": "_to",
            "type": "address"
         },
         {
            "name": "_value",
            "type": "uint256"
         }
      ],
      "name": "transferFrom",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "minter",
            "type": "address"
         }
      ],
      "name": "removeMinter",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "decimals",
      "outputs": [
         {
            "name": "",
            "type": "uint8"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_name",
            "type": "string"
         },
         {
            "name": "_symbol",
            "type": "string"
         },
         {
            "name": "_currency",
            "type": "string"
         },
         {
            "name": "_decimals",
            "type": "uint8"
         },
         {
            "name": "_masterMinter",
            "type": "address"
         },
         {
            "name": "_pauser",
            "type": "address"
         },
         {
            "name": "_blacklister",
            "type": "address"
         },
         {
            "name": "_owner",
            "type": "address"
         }
      ],
      "name": "initialize",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "masterMinter",
      "outputs": [
         {
            "name": "",
            "type": "address"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [

      ],
      "name": "unpause",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_to",
            "type": "address"
         },
         {
            "name": "_amount",
            "type": "uint256"
         }
      ],
      "name": "mint",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_amount",
            "type": "uint256"
         }
      ],
      "name": "burn",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "minter",
            "type": "address"
         },
         {
            "name": "minterAllowedAmount",
            "type": "uint256"
         }
      ],
      "name": "configureMinter",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_newPauser",
            "type": "address"
         }
      ],
      "name": "updatePauser",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "paused",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [
         {
            "name": "account",
            "type": "address"
         }
      ],
      "name": "balanceOf",
      "outputs": [
         {
            "name": "",
            "type": "uint256"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [

      ],
      "name": "pause",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [
         {
            "name": "minter",
            "type": "address"
         }
      ],
      "name": "minterAllowance",
      "outputs": [
         {
            "name": "",
            "type": "uint256"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "owner",
      "outputs": [
         {
            "name": "",
            "type": "address"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "symbol",
      "outputs": [
         {
            "name": "",
            "type": "string"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "pauser",
      "outputs": [
         {
            "name": "",
            "type": "address"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_to",
            "type": "address"
         },
         {
            "name": "_value",
            "type": "uint256"
         }
      ],
      "name": "transfer",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_newMasterMinter",
            "type": "address"
         }
      ],
      "name": "updateMasterMinter",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [
         {
            "name": "account",
            "type": "address"
         }
      ],
      "name": "isMinter",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_newBlacklister",
            "type": "address"
         }
      ],
      "name": "updateBlacklister",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "blacklister",
      "outputs": [
         {
            "name": "",
            "type": "address"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [
         {
            "name": "owner",
            "type": "address"
         },
         {
            "name": "spender",
            "type": "address"
         }
      ],
      "name": "allowance",
      "outputs": [
         {
            "name": "",
            "type": "uint256"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [

      ],
      "name": "currency",
      "outputs": [
         {
            "name": "",
            "type": "string"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "newOwner",
            "type": "address"
         }
      ],
      "name": "transferOwnership",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": false,
      "inputs": [
         {
            "name": "_account",
            "type": "address"
         }
      ],
      "name": "blacklist",
      "outputs": [

      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "constant": true,
      "inputs": [
         {
            "name": "_account",
            "type": "address"
         }
      ],
      "name": "isBlacklisted",
      "outputs": [
         {
            "name": "",
            "type": "bool"
         }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "minter",
            "type": "address"
         },
         {
            "indexed": true,
            "name": "to",
            "type": "address"
         },
         {
            "indexed": false,
            "name": "amount",
            "type": "uint256"
         }
      ],
      "name": "Mint",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "burner",
            "type": "address"
         },
         {
            "indexed": false,
            "name": "amount",
            "type": "uint256"
         }
      ],
      "name": "Burn",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "minter",
            "type": "address"
         },
         {
            "indexed": false,
            "name": "minterAllowedAmount",
            "type": "uint256"
         }
      ],
      "name": "MinterConfigured",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "oldMinter",
            "type": "address"
         }
      ],
      "name": "MinterRemoved",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "newMasterMinter",
            "type": "address"
         }
      ],
      "name": "MasterMinterChanged",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "_account",
            "type": "address"
         }
      ],
      "name": "Blacklisted",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "_account",
            "type": "address"
         }
      ],
      "name": "UnBlacklisted",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "newBlacklister",
            "type": "address"
         }
      ],
      "name": "BlacklisterChanged",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [

      ],
      "name": "Pause",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [

      ],
      "name": "Unpause",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "newAddress",
            "type": "address"
         }
      ],
      "name": "PauserChanged",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "owner",
            "type": "address"
         },
         {
            "indexed": true,
            "name": "spender",
            "type": "address"
         },
         {
            "indexed": false,
            "name": "value",
            "type": "uint256"
         }
      ],
      "name": "Approval",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": false,
            "name": "previousOwner",
            "type": "address"
         },
         {
            "indexed": false,
            "name": "newOwner",
            "type": "address"
         }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "name": "from",
            "type": "address"
         },
         {
            "indexed": true,
            "name": "to",
            "type": "address"
         },
         {
            "indexed": false,
            "name": "value",
            "type": "uint256"
         }
      ],
      "name": "Transfer",
      "type": "event"
   }
]
export const USDCContractAddress = '0x64544969ed7EBf5f083679233325356EbE738930'
export const USDCContract = new web3Instance.eth.Contract(usdcABI, USDCContractAddress)
