import { ConnectButton } from '@rainbow-me/rainbowkit'
import { BlockchainProvider } from 'components/blockchainProvider/BlockchainProvider'
import React from 'react'
import { hexToDecimal } from 'utils/helper'
import { useAccount, useNetwork } from 'wagmi'

function RainbowConnectButton({ children }) {
    const { isConnected } = useAccount()
    const { chain } = useNetwork()

    return (
        <BlockchainProvider>
            {
                (!isConnected || chain !== hexToDecimal(process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID)) && <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "20px 0"
                    }}
                >
                    <ConnectButton chainStatus="icon" />
                </div>
            }
            {
                isConnected && children
            }

        </BlockchainProvider>
    )
}

export { RainbowConnectButton }