import * as actions from "./index";
import * as crud from "../cruds/gameCrud";

export const setActiveGames = () => async (dispatch) => {
  try {
    const activeGames = await crud.getActiveGames();
    dispatch({
      type: actions.SET_ACTIVE_GAMES,
      payload: activeGames,
    });
  } catch (error) {
    dispatch({
      type: actions.SET_ACTIVE_GAMES_ERROR,
      error: error,
    });
  }
};

export const addActiveGame = (game) => (dispatch) => {
  try {
    dispatch({
      type: actions.ADD_ACTIVE_GAME,
      payload: game,
    });
  } catch (error) {
    dispatch({
      type: actions.ADD_ACTIVE_GAME_ERROR,
      error: error,
    });
  }
};

export const removeActiveGame = (game) => (dispatch) => {
  try {
    dispatch({
      type: actions.REMOVE_ACTIVE_GAME,
      payload: game,
    });
  } catch (error) {
    dispatch({
      type: actions.REMOVE_ACTIVE_GAME_ERROR,
      error: error,
    });
  }
};
