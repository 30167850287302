import * as actions from '../actions';


const initState = {
  loading: true,
  'token-price': "",

  'gaming-enabled': null,
  'ico-enabled': null,
  'ico-swap-enabled': null,
  'ico-fiat-enabled': null,
  'ico-wire-enabled': null,
  'ico-restricted-countries': null,

  'ico-wire-info': "",
  'ico-enabled-message': "",
  'ico-disabled-message': "",
  'ico-wire-message': "",

  'terms-of-service': "",
  'disclaimer-message': "",

  'ico-swap-usdt-enabled': "",
  'ico-swap-usdc-enabled': "",
  'ico-swap-busd-enabled': "",
  'ico-swap-dai-enabled': "",

  'kyc-disabled': null,
  'kyc-disabled-message': null,

  "email-changing-disabled": null,
  "email-changing-disabled-after-kyc": null,
  "email-changing-disabled-message": null,
  "email-changing-disabled-after-kyc-message": null,

  "username-changing-disabled": null,
  "username-changing-disabled-after-kyc": null,
  "username-changing-disabled-message": null,
  "username-changing-disabled-after-kyc-message": null,
}

export const configReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_CONFIG:
      return {
        ...state,
        ...action.payload
      }
    case actions.GET_CONFIG_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_CONFIG:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_CONFIG_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_CONFIG_CHANGES:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_CONFIG_CHANGES_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_LOADING:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
