import * as bscMainnet from './0x38';
import * as bscTestnet from './0x61';
import * as polygonMumbai from './0x13881';
import * as polygonMainnet from './0x89';
import { web3Instance } from './web3Instance';

const allNetworks = {
  '0x38': bscMainnet,
  '0x61': bscTestnet,
  '0x13881': polygonMumbai,
  '0x89': polygonMainnet,
}
const network = allNetworks[process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID]
export const {
  evolveContract, evolveContractAddress,
  swapContract, swapContractAddress,
  USDTContract, USDTContractAddress,
  USDCContract, USDCContractAddress,
  BUSDContract, BUSDContractAddress,
  DAIContract, DAIContractAddress,
  vestingContract, vestingContractAddress,
  bulkContractAddress,
  EOG_ComptitionAddress,
  stableCoins,
  stableCoinsOnEOG_Competition,
  scannerURL,
  getTokenScannerURL,
  getAddressScannerURL,
  getTxScannerURL,
  getTokenBalanceUrl,
  chain,
} = network

export const approvalAmount = web3Instance.utils.toWei('100000', 'ether');
