import React from "react";

export default function Pagination({
  transactionsPerPage,
  totalTransactions,
  setCurrentPage,
  currentPage,
}) {

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalTransactions / transactionsPerPage); i++) {
    pageNumbers.push(i);
  }
  
  return (
    <div className="text-center w-100 d-block">
        {pageNumbers.length > 1 && <>
        <button 
          type="button"
          disabled={currentPage===1}
          className="btn py-1 px-2 bg-transparent text-white rounded"
          onClick={() => setCurrentPage(--currentPage)}>
          {"«"}
        </button>
      

      {pageNumbers.map((number, index) => (
        <button
          type="button"
          key={index}
          onClick={() => setCurrentPage(number)}
          className={currentPage && currentPage===number ? "btn  py-1 px-2 bg-light text-dark rounded" : "btn  py-1 px-2 bg-transparent text-white rounded"}
        >
          {number}
        </button>
      ))}

      
        <button 
        type="button" 
        className="btn  py-1 px-2 bg-transparent text-white rounded"
        onClick={() => setCurrentPage(++currentPage)}
        disabled={pageNumbers.length===currentPage}
        >
          {"»"}
        </button>
        </>}
      
      
    </div>
  );
}
