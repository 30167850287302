import { toast } from 'react-toastify';
import { storedGame } from '../utils/storedGame'
import Parse from 'parse';
import { web3Instance } from 'smart-contract/web3Instance';
import { stableCoinsOnEOG_Competition } from 'smart-contract';

export const customCursor = () => {
  let $ = document.querySelector.bind(document);
  let $on = document.addEventListener.bind(document);
  let xmouse, ymouse;
  $on('mousemove', function (e) {
    xmouse = e.clientX || e.pageX;
    ymouse = e.clientY || e.pageY;
  });
  let ball = $('#df-custom-cursor');
  let x = void 0,
    y = void 0,
    dx = void 0,
    dy = void 0;
  let followMouse = function followMouse() {
    requestAnimationFrame(followMouse);
    if (!x || !y) {
      x = xmouse;
      y = ymouse;
    } else {
      dx = (xmouse - x) * 0.25;
      dy = (ymouse - y) * 0.25;
      if (Math.abs(dx) + Math.abs(dy) < 0.1) {
        x = xmouse;
        y = ymouse;
      } else {
        x += dx;
        y += dy;
      }
    }
    ball.style.transform = "translate(" + x + "px, " + y + "px)";
  };
  followMouse();
}

export const initialHomePage = () => {
  let landingGlitch = document.querySelectorAll('.social-menu--landing-glitch > li > a > i')
  landingGlitch.forEach(function (item) {
    let glitch = item.cloneNode(true);
    let clonedGlitch1 = glitch.cloneNode(true);
    clonedGlitch1.classList.add('glitch-layer--1')
    let clonedGlitch2 = glitch.cloneNode(true);
    clonedGlitch2.classList.add('glitch-layer--2')

    item.parentNode.prepend(clonedGlitch1);
    item.parentNode.prepend(clonedGlitch2);
  })
}

export const initialGamePageMenu = () => {
  let _windowWidth = window.innerWidth;
  let menuToggle = document.getElementsByClassName('header-menu-toggle')[0],
    siteWrapper = document.getElementsByClassName('site-wrapper')[0],
    socialToggle = document.getElementsByClassName('header-social-toggle')[0],
    account = document.getElementsByClassName('header-account')[0],
    headerPagnav = document.getElementsByClassName('header-pagination')[0],
    playerInfoNav = document.getElementsByClassName('header-player-info-navigation')[0],
    filterToggle = document.getElementsByClassName('header-filter-toggle')[0],
    topBarToggle = document.getElementsByClassName('header-top-bar-toggle')[0],
    topBar = document.getElementsByClassName('menu-panel__top-bar')[0],
    dlMenu = document.getElementsByClassName('dl-menuwrapper ul.dl-menu')[0],
    dlMenuItems = document.getElementsByClassName('dl-menuwrapper ul.dl-menu li:not(.dl-back)')[0];

  siteWrapper.classList.toggle('site-wrapper--has-menu-overlay');
  if (siteWrapper.classList.contains('site-wrapper--has-search-overlay')) {
    siteWrapper.classList.toggle('site-wrapper--has-search-overlay');
  }
  if (siteWrapper.classList.contains('site-wrapper--has-overlay')) {
    siteWrapper.classList.toggle('site-wrapper--has-overlay');
  }
  if (_windowWidth > 767) {
    socialToggle.classList.toggle('hide');
    account.classList.toggle('hide');
    headerPagnav?.classList.toggle('hide');
  }
  if (_windowWidth < 768) {
    topBarToggle.classList.toggle('hide');
    if (topBarToggle.classList.contains('toggled')) {
      topBarToggle.removeClass('toggled');
    }
    if (topBar.classList.contains('toggled')) {
      topBar.removeClass('toggled');
    }
    menuToggle.classList.toggle('toggled');
    if (playerInfoNav) {
      playerInfoNav.classList.toggle('hide');
    }
    if (filterToggle) {
      filterToggle.classList.toggle('hide');
    }
    dlMenu && dlMenu.classList.remove('dl-subview');
    dlMenuItems && dlMenuItems.classList.remove('dl-subview dl-subviewopen');
    siteWrapper.classList.toggle('site-wrapper--has-menu-overlay');
    if (menuToggle) {
      var toggleMenu = function () {
        let horizontalLayout = document.getElementsByClassName('site-layout--horizontal')[0];
        if (menuToggle.classList.contains('toggled') && horizontalLayout) {
          let scrollSpeed
          siteWrapper.mousewheel(function (e, delta) {
            if (_windowWidth > 991) {
              scrollSpeed = delta;
              if (navigator.appVersion.indexOf("Win") !== -1) {
                scrollSpeed = delta * 40;
              }
              this.scrollLeft -= scrollSpeed;
              e.preventDefault();
            }
          });
        }
        menuToggle.classList.toggle('toggled');
      }
      if (playerInfoNav) {
        playerInfoNav.classList.toggle('hide');
      }
      if (filterToggle) {
        filterToggle.classList.toggle('hide');
      }
      dlMenu && dlMenu.classList.remove('dl-subview');
      dlMenuItems && dlMenuItems.classList.remove('dl-subview dl-subviewopen');
      siteWrapper.classList.toggle('site-wrapper--has-menu-overlay');
    };
    menuToggle.addEventListener('click', function () {
      toggleMenu();
    });
  }
}

export const playPreloader = () => {
  setTimeout(() => {
    const preloaderOverlay = document.querySelector('.preloader-overlay')
    const body = document.querySelector('#body')
    if (preloaderOverlay) {
      if (body.classList.contains('preloader-is--active')) {
        body.classList.remove('preloader-is--active')
        setTimeout(() => {
          body.classList.add('scroll-is--active')
        }, 1300)
      }
    }
  }, 10)
}

export const findGameById = (id) => {
  const formattedId = Number(id)

  if (!isNaN(formattedId)) {
    return storedGame.find(game => game.overwolfId === formattedId)
  }

}

export const tokenIndexFinder = (tokenName) => {
  if (typeof tokenName === "number") {
    const result = stableCoinsOnEOG_Competition.find((token => token.index === tokenName));
    return result
  }
  if (tokenName) {
    const result = stableCoinsOnEOG_Competition.find((token => token.tokenName === tokenName));
    return result
  }
}

export const customToast = (text, stat) => {
  return (toast(<div className={`alert alert-${stat} custom-toastify-text`}>{text}</div>), {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const fromWei = (number) => {
  return web3Instance.utils.fromWei(number.toString(), 'ether');
}

export const toWei = (number) => {
  return web3Instance.utils.toWei(number.toString(), 'ether');
}

const fallbackAvatars = [
  '/assets/img/user-avatars/avatar-0.jpg',
  '/assets/img/user-avatars/avatar-1.jpg',
  '/assets/img/user-avatars/avatar-2.jpg',
  '/assets/img/user-avatars/avatar-3.jpg',
  '/assets/img/user-avatars/avatar-4.jpg',
  '/assets/img/user-avatars/avatar-5.jpg',
  '/assets/img/user-avatars/avatar-6.jpg',
  '/assets/img/user-avatars/avatar-7.jpg',
  '/assets/img/user-avatars/avatar-8.jpg',
  '/assets/img/user-avatars/avatar-9.jpg',
]

export const generateAvatar = (user) => {
  let userIDNum
  if (user) {
    userIDNum = user?.id?.split('')?.map(c => c.charCodeAt(0)).reduce((i, sum) => sum += i, 0)
  } else {
    const random = Math.floor(Math.random() * 100)
    return fallbackAvatars[random % fallbackAvatars.length]
  }
  if (userIDNum) return fallbackAvatars[userIDNum % fallbackAvatars.length]
}

var clientTimeOffset = 0;

export const syncTimeWithServer = async () => {
  const serverTime = await Parse.Cloud.run('get-server-time')
  const clientTimeWhenServerTimeFetched = new Date()
  clientTimeOffset = clientTimeWhenServerTimeFetched.getTime() - serverTime.getTime()
}

export const getServerDate = () => new Date(new Date().getTime() - clientTimeOffset)

export const userHasRequiredItems = (userParseObject, gameOverWolfId) => {
  const game = storedGame.find(game => +game.overwolfId === +gameOverWolfId)
  const requiredFields = game?.requiredItemsToPlay
  if (requiredFields) {
    const platforms = userParseObject?.get('platforms')
    // TODO: usernames or this object will be dynamic key:value
    const usernames = {
      pubgUsername: platforms?.steam?.games?.[gameOverWolfId.toString()]?.userName,
      valorantUserName: platforms?.riot?.games?.[gameOverWolfId.toString()]?.userName,
      valorantTag: platforms?.riot?.games?.[gameOverWolfId.toString()]?.tag,
      // steam: platforms?.steam?.id,
      // riot: platforms?.riot?.id
    }
    return requiredFields.every(item => usernames[item])
  }
  else return true
}

export const initPlatformObject = {
  "steam": {
    "id": "",
    "games": {
      "7764": {
        "name": "csgo"
      },
      "10906": {
        "name": "pubg",
        "userName": ""
      }
    }
  },
  "riot": {
    "id": "",
    "games": {
      "5426": {
        "name": "lol",
        "region": "EUW"
      }
    }
  }
}

export const CompetitionStatus = {
  PENDING_READY: 'PENDING_READY',                            // startedAt = null,  finishedAt = null,  stage = 1
  PENDING_READY_CANCELED: 'PENDING_READY_CANCELED',          // startedAt = null,  finishedAt != null, stage = n/a
  PENDING_READY_TIMEDOUT: 'PENDING_READY_TIMEDOUT',          // startedAt = null,  finishedAt != null, stage = n/a   
  PENDING_START_TX: 'PENDING_START_TX',                      // startedAt = null,  finishedAt = null   stage = 1
  X_START_TX_FAILED: 'X_START_TX_FAILED',                    // startedAt = null,  finishedAt != null, stage = 1
  X_START_TX_STUCK: 'X_START_TX_STUCK',                      // startedAt = null,  finishedAt = null,  stage = 1
  IN_PROGRESS: 'IN_PROGRESS',                                // startedAt != null, finishedAt = null,  stage = 2
  COMPLETED: 'COMPLETED',                                    // startedAt != null, finishedAt != null, stage = 3
  COMPLETED_TIMEDOUT: 'COMPLETED_TIMEDOUT',                  // startedAt != null, finishedAt != null, stage = 3
  X_FINISH_TX_STUCK: 'X_FINISH_TX_STUCK',                    // startedAt != null, finishedAt != null, stage = 3
  X_FINISH_TX_FAILED: 'X_FINISH_TX_FAILED',                  // startedAt != null, finishedAt != null, stage = 3
  SETTLED: 'SETTLED',                                        // startedAt != null, finishedAt != null, stage = 3
}

export const getCompetitionStatusText = (competitionStatus) => {
  const competitionStatusTexts = {
    PENDING_READY: 'Processing Data Prior to Competition Start...',
    PENDING_START_TX: 'Submitting blockchain transaction, Competition will start in a moment',
    X_START_TX_FAILED: 'start competition transaction failed, please join a new  competition',
    COMPLETED: 'Submitting blockchain transaction for the competitors in the winning team',
    COMPLETED_TIMEDOUT: 'Submitting blockchain transaction for the competitors in the winning team',
    FREE_TRIAL: 'Free Trial Competition results loading',
    X_FINISH_TX_FAILED: "We submitted the blockchain transaction to disburse the winner's earnings, but the transaction failed due to an unexpected error. We will work on the problem and fix the issue and will contact the winners if necessary. Sorry for the inconvenience and thank you for your patience.",
    X_FINISH_TX_STUCK: "We submitted the blockchain transaction to disburse the winner's earnings, but due to an unexpected error, the transaction has not been submitted. We will work on the problem and fix the issue and will contact the winners if necessary. Sorry for the inconvenience and thank you for your patience.",
    COMPLETED_SETTLED: "Blockchain transaction for winner's earnings: ",
    SETTLED: "Blockchain transaction for winner's earnings: ",
  }
  return competitionStatusTexts[competitionStatus] ? competitionStatusTexts[competitionStatus] : ''
}

export const callIPCountryWebhook = async () => {
  const curentUserSession = await Parse.Session.current()
  const sessionToken = curentUserSession.get('sessionToken')
  try {
    await fetch(process.env.REACT_APP_CLOUD_FLARE_URL, {
      method: 'GET',
      headers: {
        session: sessionToken
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const isUserCountryRestricted = (userObject, config) => {
  const icoRestrictedCountries = config['ico-restricted-countries']
  if (icoRestrictedCountries && userObject) {
    const { country, lastIpLocation, KYCAt } = userObject.attributes
    if (KYCAt) {
      return icoRestrictedCountries.includes(country)
    }
    else {
      return icoRestrictedCountries.includes(lastIpLocation)
    }
  }
}

export const recognizeEnabledStableCoins = (stableCoins, config) => {
  return stableCoins.filter(coin => {
    const coinConfigKey = `ico-swap-${coin.symbol}-enabled`.toLowerCase()
    return config[coinConfigKey] === true
  })
}

export const generateBalance = (_balance) => {
  if (isNaN(+_balance)) return '....'
  return (+_balance).toLocaleString()
}

export const hexToDecimal = (hex) => {
  return parseInt(hex, 16)
}