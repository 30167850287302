import React, { useEffect, useState } from 'react';
import { AppRoutes } from './routes/Routes'
import { BrowserRouter } from 'react-router-dom';
import { StyledCursor } from 'components';
import { customCursor } from './utils/helper';
import { AuthProvider } from 'routes/AuthProvider';
import { ErrorBoundary } from 'pages';
import Parse from 'parse';
import './styles/global.css'
import './styles/cssLibrary.css'
import '@rainbow-me/rainbowkit/styles.css';
import { connect } from 'react-redux';
import { setConfig } from 'redux/actions/configActions';
import { fetchCurrentUser } from 'redux/actions/userActions';
const Config = Parse.Object.extend('Config');

const AppComponent = (props) => {
  const { setConfig, fetchCurrentUser } = props;
  const [configSubscription, setConfigSubscription] = useState();


  useEffect(() => {
    fetchCurrentUser()

    liveConfigChanges().then(subscription => {
      setConfigSubscription(subscription)
    })

    customCursor()

    return () => {
      if (configSubscription) configSubscription.unsubscribe()
    }

    // eslint-disable-next-line 
  }, [])

  const liveConfigChanges = async () => {
    const liveConfigChangesQuery = new Parse.Query(Config);
    let configSub = await liveConfigChangesQuery.subscribe();
    configSub.on('update', (object) => {
      setConfig({ [object.get('variable')]: object.get(object.get('type') + 'Value') });
    })
  }

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AuthProvider>
          <AppRoutes />
          <StyledCursor />
        </AuthProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setConfig: (changed) => dispatch(setConfig(changed)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser())
})

const App = connect(null, mapDispatchToProps)(AppComponent);
export default App;
