/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import Parse from 'parse'
import { Link, useNavigate } from 'react-router-dom'

import { Preloader, SvgColorFilter } from 'components'
import { GamesLayout } from 'layouts'

// Components
import Steppers from './components/Steppers'
import CompetitionStages from './components/CompetitionStages'

const PAGE_TITLE = [
  'Waiting Room',
  'Competition Preparation',
  'Competition is in Progress',
  'Competition Results',
]

const COMPETITOR_STATUS = {
  WAITING_JOIN_COMPETITION: 'WAITING_JOIN_COMPETITION',
  CANCELED_IN_WAITING: 'CANCELED_IN_WAITING',
  PENDING_READY: 'PENDING_READY',
  COMPETITOR_IS_READY: 'COMPETITOR_IS_READY',
  PENDING_READY_CANCELED: 'PENDING_READY_CANCELED',
  PENDING_READY_TIMEDOUT: 'PENDING_READY_TIMEDOUT',
}

const CompetitionClass = Parse.Object.extend('Competition')
const CompetitorStatusClass = Parse.Object.extend('CompetitorStatus')

function Competition() {
  const navigate = useNavigate()

  const [stage, setStage] = useState(null)

  const [competitorStatus, setCompetitorStatus] = useState(null)
  const [competitorStatusSubscribe, setCompetitorStatusSubscribe] = useState(null)
  const [competition, setCompetition] = useState(null)
  const [competitionSubscribe, setCompetitionSubscribe] = useState(null)
  const [members, setMembers] = useState(null)
  const [membersSubscribe, setMembersSubscribe] = useState(null)

  useEffect(() => {
    getLatestCompetitorStatus()
    //eslint-disable-next-line
  }, [])

  const getLatestCompetitorStatus = async (withRetry = false, baseDelay = 400, numberOfTries = 3) => {
    const retry = async (attempt = 1) => {
      try {
        const competitorStatusCloud = await Parse.Cloud.run('get-latest-competitior-status')
        if (competitorStatusCloud) {
          if (withRetry && competitorStatus && competitorStatus.createdAt > competitorStatusCloud.createdAt) {
            if (attempt >= numberOfTries) console.log('not new competitor status finded')
            // Use an increasing delay to prevent flodding the
            // server with requests in case of a short downtime.
            const delay = baseDelay * attempt
            return new Promise((resolve) => setTimeout(() => resolve(retry(attempt + 1)), delay))
          } else {
            setCompetitorStatus(competitorStatusCloud)
            if (!competitorStatusSubscribe) {
              liveCompetitorStatus(competitorStatusCloud.id)
            }
          }
        } else {
          navigate('/')
        }
      } catch (error) {
        console.log(error)
      }
    }
    return retry()
  }

  // ****************************************************************************************************
  // effect on CompetitorStatus
  useEffect(() => {
    if (competitorStatus) {
      if (competitorStatus.get('competition')) {
        if (!competition) getCompetition(competitorStatus.get('competition').id)
        if (!competitionSubscribe) liveCompetition(competitorStatus.get('competition').id)
      } else {
        setStage(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitorStatus])
  // live CompetitorStatus by id
  const liveCompetitorStatus = async (competitorStatusId) => {
    let competitorStatusQuery = new Parse.Query(CompetitorStatusClass)
    competitorStatusQuery.include(['competition', 'preset', 'preset.game'])
    competitorStatusQuery.equalTo('objectId', competitorStatusId)
    let userCompetitorStatusSub = await competitorStatusQuery.subscribe()
    setCompetitorStatusSubscribe(userCompetitorStatusSub)

    userCompetitorStatusSub.on('open', () => {
      console.log('user competitor status subscription opened')
    })

    userCompetitorStatusSub.on('update', (object) => {
      console.log('user competitor status updated')
      setCompetitorStatus(object)
    })
  }
  // unsubscribe from live CompetitorStatus
  const unsubscribeCompetitorStatus = () => {
    if (competitorStatusSubscribe) {
      competitorStatusSubscribe.unsubscribe()
      console.log('user competitor status unsubscribe')
    }
  }
  // ****************************************************************************************************

  // ****************************************************************************************************
  // COMPETITION
  // effect on Competition
  useEffect(() => {
    if (competition) {
      if (!members) getMembers(competition.id)
      if (!membersSubscribe) liveCompetitorsStatus(competition.id)

      // handel stage by Competition
      if (!competition.get('startedAt') && !competition.get('finishedAt')) setStage(1)
      else if (competition.get('startedAt') && !competition.get('finishedAt') && competition.get('startTx') && competition.get('startTx') != "0" ) setStage(2)
      else if (competition.get('startedAt') && competition.get('finishedAt')) setStage(3)

      // handel pending ready cancel
      if (competition.get('status') === 'PENDING_READY_CANCELED') {
        resetDefault()
      } else if (competition.get('status') === 'PENDING_READY_TIMEDOUT') {
        if (competitorStatus.get('ready')) {
          resetDefault()
        } else {
          navigate(`/join/game/${competition.get('preset').get('game').get('overwolfId')}`)
        }
      } else if (!competition.get('startedAt') && competition.get('finishedAt'))
        navigate(`/join/game/${competitorStatus.get('preset').get('game').get('overwolfId')}`)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition])
  // get Competition by id
  const getCompetition = async (competitionId) => {
    const competitionQuery = new Parse.Query(CompetitionClass)
    const competition = await competitionQuery.get(competitionId)
    setCompetition(competition)
  }
  // live Competition by id
  const liveCompetition = async (competitionId) => {
    let competitionQuery = new Parse.Query(CompetitionClass)
    competitionQuery.equalTo('objectId', competitionId)
    let competitionSub = await competitionQuery.subscribe()
    setCompetitionSubscribe(competitionSub)

    competitionSub.on('open', () => {
      console.log('competition subscription opened')
    })

    competitionSub.on('update', (object) => {
      console.log('competition updated')
      setCompetition(object)
    })
  }
  // unsubscribe from live Competition
  const unsubscribeCompetition = () => {
    if (competitionSubscribe) {
      competitionSubscribe.unsubscribe()
      console.log('competition unsubscribe')
    }
  }
  // ****************************************************************************************************

  // ****************************************************************************************************
  // MEMBERS
  // get Members by Competition id
  let reloadTrip=0
  const getMembers = async (competitionId) => {
    const playerCount = competitorStatus.get('preset')?.get('players') * 2
    const competitorStatusQuery = new Parse.Query(CompetitorStatusClass)
    competitorStatusQuery.equalTo('competition', CompetitionClass.createWithoutData(competitionId))
    competitorStatusQuery.include('user')
    const members = await competitorStatusQuery.find()
   if (members.length === playerCount) {
    setMembers(members)
   }else {
    setTimeout(() => {
      if (reloadTrip <= 10) {
        reloadTrip++;
        getMembers(competitionId)
      }
    }, 1000);
   }
  }
  // live Members by Competition id
  const liveCompetitorsStatus = async (competitionId) => {
    const competitorsStatusQuery = new Parse.Query(CompetitorStatusClass)
    competitorsStatusQuery.equalTo('competition', CompetitionClass.createWithoutData(competitionId))
    let competitorsStatusSub = await competitorsStatusQuery.subscribe()
    setMembersSubscribe(competitorsStatusSub)

    competitorsStatusSub.on('open', () => {
      console.log('members subscription opened')
    })

    competitorsStatusSub.on('update', (object) => {
      console.log('members updated')
      if (members) setMembers(members.map((item) => (item.id === object.id ? { ...item, ...object } : item)))
    })
  }
  // unsubscribe from live CompetitorsStatus
  const unsubscribeCompetitorsStatus = () => {
    if (membersSubscribe) {
      membersSubscribe.unsubscribe()
      console.log('members unsubscribe')
    }
  }
  // ****************************************************************************************************

  // const changeStage = (stage) => {
  //   setStage(stage)
  // }

  useEffect(() => {
    console.log('stage', stage)
  }, [stage])

  const resetDefault = () => {
    setMembers(null)
    setMembersSubscribe(null)

    setCompetition(null)
    setCompetitionSubscribe(null)

    setCompetitorStatusSubscribe(null)

    setStage(null)
  }

  useEffect(() => {
    ; (async () => {
      if (competitorStatus && !competitorStatusSubscribe) await getLatestCompetitorStatus(true)
    })()
    //eslint-disable-next-line
  }, [competitorStatusSubscribe])

  // Will Unmount
  // Unsubscribe WS
  useEffect(() => {
    return () => {
      unsubscribeCompetitorStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitorStatusSubscribe])

  useEffect(() => {
    return () => {
      unsubscribeCompetition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionSubscribe])

  useEffect(() => {
    return () => {
      unsubscribeCompetitorsStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersSubscribe])

  return (
    <GamesLayout bgImage="bg--matches-01">
      {competition &&
        (competition.get('status') === COMPETITOR_STATUS.PENDING_READY_TIMEDOUT ||
          competition.get('status') === COMPETITOR_STATUS.PENDING_READY_CANCELED ||
          competition.get('status') === COMPETITOR_STATUS.CANCELED_IN_WAITING) ? (
        <main className="site-content site-content--center page text-center">
          <div className="container">
            <div className="site-content__center">
              <h1 className="text-danger landing-title mb-0">
                Canceled
                <span className="mt-4 text-white subtitle landing-subtitle">This competition was cancel</span>
              </h1>
              <Link to="/">
                <div className="btn btn-primary btn-lg btn--landing btn--icon-inverse">
                  <span>Go Home</span>
                </div>
              </Link>
            </div>
          </div>
        </main>
      ) : stage !== null && competitorStatus ? (
        <main className="site-content site-content--center page" id="wrapper">
          <div className="container">
            <div className="page-heading page-heading--default text-small text-center w-100">
              {stage === 0 && (
                <div className="page-heading__subtitle h5">
                  <span className="color-primary">
                    {new Date(
                      Date.parse(competition?.createdAt || competitorStatus.createdAt)
                    ).toLocaleDateString('en-CA')}{' '}
                    /{' '}
                  </span>
                  {competitorStatus.get('preset')?.get('game')?.get('title')}
                </div>
              )}
              <h1 className="page-heading__title h2">{PAGE_TITLE[stage]}</h1>
            </div>

            <Steppers step={stage} />
            <CompetitionStages
              stage={stage}
              competitorStatus={competitorStatus}
              competition={competition}
              members={members}
            />
          </div>
        </main>
      ) : (
        'Loading...'
      )}
      <Preloader />
      <SvgColorFilter />
    </GamesLayout>
  )
}

export { Competition }
