import React from 'react'

function Steppers({ step }) {
  return (
    <ul className="match-stats-links match-stats-links--main">
      <li>
        <div className={step === 0 ? 'active' : ''}>
          <svg role="img" className="df-icon df-icon--lineups">
            <use xlinkHref="/assets/img/necromancers.svg#lineups" />
          </svg>
        </div>
      </li>
      <li>
        <div className={step === 1 ? 'active' : ''}>
          <svg role="img" className="df-icon df-icon--overview">
            <use xlinkHref="/assets/img/necromancers.svg#overview" />
          </svg>
        </div>
      </li>
      <li>
        <div className={step === 2 ? 'active' : ''}>
          <svg role="img" className="df-icon df-icon--replay">
            <use xlinkHref="/assets/img/necromancers.svg#replay" />
          </svg>
        </div>
      </li>
      <li>
        <div className={step === 3 ? 'active' : ''}>
          <svg role="img" className="df-icon df-icon--stats">
            <use xlinkHref="/assets/img/necromancers.svg#stats" />
          </svg>
        </div>
      </li>
    </ul>
  )
}

export default Steppers
