import React, { useEffect } from 'react'
import { Preloader, SvgColorFilter } from 'components'
import { GamesJoinContent } from './components/GamesJoinContent'
import Parse from 'parse';
import { storedGame } from 'utils/storedGame'
import { GamesLayout } from 'layouts'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux';
import { addActiveGame, removeActiveGame, setActiveGames } from '../../redux/actions/gameActions'
import { useNavigate } from 'react-router-dom';

function GamesJoinComponent(props) {
  const GamesClass = Parse.Object.extend('Game');
  const { setActiveGames, games, addActiveGame, removeActiveGame, gamingEnabled, loading, userGamingEnabled } = props;
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      await setActiveGames();
      liveGameChanges();
    })()
    // eslint-disable-next-line 
  }, [])

  const liveGameChanges = async () => {
    const liveGameChangesQuery = new Parse.Query(GamesClass);
    let gameSub = await liveGameChangesQuery.subscribe();

    gameSub.on('update', (object) => {
      const game = storedGame.find(game => Number(game.overwolfId) === Number(object.get('overwolfId')))
      if (object.get('active') === true) {
        addActiveGame(game)
      } else {
        removeActiveGame(game)
      }
    })
  }

  useEffect(() => {
    if (!loading) {
      if (!gamingEnabled || (!userGamingEnabled && userGamingEnabled !== null)) navigate('/')
    }
    //eslint-disable-next-line
  }, [gamingEnabled, userGamingEnabled, loading])



  return (
    <GamesLayout>
      <Helmet>
        <title>EVOLVE - The Evolution Of Gaming</title>
      </Helmet>
      <GamesJoinContent gamesCardsList={games} />
      <Preloader />
      <SvgColorFilter />
    </GamesLayout>
  )
}

const mapStateToProps = (state) => {
  return {
    games: state.games,
    loading: state.config?.loading,
    gamingEnabled: state.config?.['gaming-enabled'],
    userGamingEnabled: state.user.userGamingEnabled
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActiveGames: (games) => dispatch(setActiveGames(games)),
  addActiveGame: (game) => dispatch(addActiveGame(game)),
  removeActiveGame: (game) => dispatch(removeActiveGame(game)),
})

const GamesJoin = connect(mapStateToProps, mapDispatchToProps)(GamesJoinComponent);
export { GamesJoin }