import * as actions from '../actions';


const initState = {
  tokenPrice: null,
  swapAmounts: null,
  stableCoin: '',
  bnbPrice:null
}

export const priceReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_TOKEN_PRICE:
      return {
        ...state,
        ...action.payload
      }
    case actions.GET_BNB_PRICE:
      return {
        ...state,
        ...action.payload
      }
    case actions.GET_BNB_PRICE_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_SWAP_AMOUNTS:
      return {
        ...state,
        ...action.payload
      }
    case actions.SET_STABLE_COIN:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
