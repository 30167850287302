import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Table, Preloader } from 'components'
import { storedGame } from 'utils/storedGame'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { GamesLayout } from 'layouts'
import { getBnbPrice } from 'redux/actions/priceActions'
import { getPresets } from 'redux/actions/presetActions'
import { getUserBalance } from 'redux/actions/userActions'

function JoinPage(props) {
  const { getBnbPrice, getPresets, gamingEnabled, loading, userGamingEnabled, userWalletAddress, getUserBalance } = props

  const navigate = useNavigate()

  const params = useParams()
  const gameId = params.gameId
  const [gameTitle, setGameTitle] = useState('')

  useEffect(() => {
    findGameName()
    if (gameId) getPresets(gameId)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!gamingEnabled || (!userGamingEnabled && userGamingEnabled !== null)) navigate('/')
    }
    //eslint-disable-next-line
  }, [gamingEnabled, userGamingEnabled, loading])

  useEffect(() => {
    if (userWalletAddress) {
      getUserBalance(userWalletAddress)
      getBnbPrice()
    }
    //eslint-disable-next-line
  }, [userWalletAddress])


  const findGameName = () => {
    const game = storedGame.find((game) => game.overwolfId === +gameId)
    setGameTitle(game?.gameName)
  }

  return (
    <GamesLayout bgImage="bg--texture-01 ">
      <Helmet>
        <title>Join a Competition</title>
      </Helmet>
      <div className="site-wrapper site-layout--default">
        <div className="site-overlay" />
        <main className="site-content site-content--center page mt-5" id="wrapper">
          <div className="container container--large">
            <div className="page-heading page-heading--default">
              <div className="page-heading__subtitle h5 color-primary">{gameTitle}</div>
              <h1 className="page-heading__title h2">Join a Competition</h1>
            </div>
            <div className="table-responsive mt-sm-auto mb-sm-auto">
              <Table gamePresets={props.presetsParseObject} mode={'JOIN'} />
            </div>
          </div>
        </main>
      </div>
      <Preloader />
    </GamesLayout>
  )
}

const mapStateToProps = ({ user, config, presets }) => ({
  userBalance: user.userBalance,
  presetsParseObject: presets.presetsParseObject,
  loading: config?.loading,
  gamingEnabled: config?.['gaming-enabled'],
  userGamingEnabled: user.userGamingEnabled,
  userWalletAddress: user.userAccount,
})

const mapDispatchToProps = (dispatch) => ({
  getBnbPrice: () => dispatch(getBnbPrice()),
  getPresets: (gameId) => dispatch(getPresets(gameId)),
  getUserBalance: (userWalletAddress) => dispatch(getUserBalance(userWalletAddress))
})

export const Join = connect(mapStateToProps, mapDispatchToProps)(JoinPage)
