import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { addActivePreset, removeActivePreset } from 'redux/actions/presetActions'
import Parse from 'parse'
import { JoinGameModal } from 'components'
import { toWei, fromWei } from 'utils/helper'

const PresetClass = Parse.Object.extend('CompetitionPreset')

function TableComponent(props) {
  const { gamePresets, addPreset, removePreset } = props
  const [presetSubscription, setPresetSubscription] = useState()

  useEffect(() => {
    if (!presetSubscription) {
      livePresetChanges()
    }

    return () => {
      if (presetSubscription) {
        presetSubscription.unsubscribe()
      }
    }
    //eslint-disable-next-line
  }, [])


  const livePresetChanges = async () => {
    const livePresetChangesQuery = new Parse.Query(PresetClass)
    let presetSub = await livePresetChangesQuery.subscribe()
    setPresetSubscription(presetSub)
    presetSub.on('update', (object) => {
      if (object.get('active') === true) {
        addPreset(object)
      } else {
        removePreset(object)
      }
    })
  }

  const calculateEarnPreset = (preset) => {
    const number = toWei(preset.get('feeInUSD').toString()) - toWei(preset.get('rakeInUsd').toString());
    return preset.get('feeInUSD') === 0 ? 0 : fromWei(number) * 2;
  }
  return (
    <table className="table matches-table upcoming-table">
      <tbody>
        {gamePresets?.map((preset) => {
          return (
            <tr key={preset.id}>
              {gamePresets.some((preset) => preset.get('requiresOverwolf')) ? (
                preset.get('requiresOverwolf') ? (
                  <td className="upcoming-table__date text-center color-danger p-2">
                    <a href="http://overwolf.com/">
                      <figure className="match-player__avatar">
                        <img
                          src="https://www.insightpartners.com//assets/media/2021/09/Overwolf.png"
                          alt=""
                        />
                      </figure>
                      Required OverWolf
                    </a>
                  </td>
                ) : (
                  <td />
                )
              ) : (
                <td className="min-w-0 w-0" />
              )}
              <td className="upcoming-table__date">
                <span> {preset.get('feeInUSD')} </span>USD
              </td>
              <td className="upcoming-table__date">
                <span className="upcoming-table__team-vs text-white">
                  {preset.get('players')}v{preset.get('players')}
                </span>
              </td>
              <td>
                <span className="match-team__country">Game mode</span>
                <span className="text-danger">{preset.get('settings').title}</span>
              </td>
              <td>
                <span className="upcoming-table__label">Earn</span>
                {calculateEarnPreset(preset)} USD
              </td>
              {
                preset.get('comingSoon') ?
                  <td>
                    <span className="upcoming-table__label font-18">
                      {
                        `Coming soon ${preset.get('levelRequired') ?
                          `for level ${preset.get('levelRequired')}` :
                          '...'}`
                      }
                    </span>
                  </td> :
                  <td>
                    {/* <PaymentModal
                      mode={mode}
                      account={preset?.account}
                      preset={preset}
                      paymentDetails={{
                        fee: preset.get('feeInUSD'),
                        players: preset.get('players'),
                        href: `/active-competition`,
                        gameName: preset.get('game').get('title'),
                        presetId: preset.id,
                        promotion: preset.get('promotion'),
                        KYCRequired: preset.get('KYCRequired'),
                        rake: preset.get('rakeInUsd')
                      }}
                    /> */}
                    <JoinGameModal
                      preset={preset}
                    />
                    <span className="upcoming-table__label"></span>
                  </td>
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const mapStateToProps = (state) => ({
  tokenPrice: state.config?.['token-price'],
})

const mapDispatchToProps = (dispatch) => ({
  addPreset: (preset) => dispatch(addActivePreset(preset)),
  removePreset: (preset) => dispatch(removeActivePreset(preset)),
})

const Table = connect(mapStateToProps, mapDispatchToProps)(TableComponent)
export { Table }
