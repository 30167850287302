import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Parse from 'parse'
import { fetchCurrentUser } from 'redux/actions/userActions'
import { customToast } from 'utils/helper'

const GamingProfileComponent = (props) => {
  const { fetchCurrentUser, userParseObject } = props
  const [formData, setFormData] = useState({
    steamId: null,
    riotId: null,
    pubgUsername: null,
    lolRegion: null,
    errors: {},
  })
  const { steamId, riotId, pubgUsername, lolRegion, errors } = formData

  useEffect(() => {
    if (userParseObject) {
      setFormData({
        ...formData,
        steamId: userParseObject.get('platforms')?.steam?.id || null,
        riotId: userParseObject.get('platforms')?.riot?.id || null,
        pubgUsername: userParseObject?.get('platforms')?.steam?.games?.['10906']?.userName || null,
        lolRegion: userParseObject?.get('platforms')?.riot?.games?.['5426']?.region || null,
      })
    }
    //eslint-disable-next-line
  }, [userParseObject])

  const onChangeInput = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value })
  }

  const createNewError = (name, message, type) => {
    return { [name]: { message, type } }
  }

  // Handle Errors toast
  useEffect(() => {
    Object.keys(errors).forEach((key) => {
      customToast(errors[key].message, errors[key].type)
    })
  }, [errors])

  const clearErrors = () => {
    setFormData({ ...formData, errors: {} })
  }

  const handleSaveSteamId = async () => {
    clearErrors()

    if (!steamId) {
      const newError = createNewError('steamId', 'Steam ID is required', 'danger')
      setFormData({ ...formData, errors: { ...newError } })
    } else if (userParseObject.get('platforms')?.steam?.id === steamId) {
      const newError = createNewError('steamId', 'Steam ID is already updated', 'warning')
      setFormData({ ...formData, errors: { ...newError } })
    } else {
      try {
        const platforms = userParseObject.get('platforms')

        const newPlatform = {
          ...platforms,
          steam: {
            ...platforms?.steam,
            id: steamId,
          },
        }

        userParseObject.set('platforms', newPlatform)
        await userParseObject.save()

        const newError = createNewError('steamId', 'Steam ID updated successfully', 'success')
        setFormData({ ...formData, errors: { ...newError } })

        fetchCurrentUser()
      } catch (error) {
        customToast(error.message, 'danger')
        console.log(error)
      }
    }
  }

  const handleSaveRiotId = async () => {
    clearErrors()
    if (!riotId) {
      const newError = createNewError('riotId', 'Riot ID is required', 'danger')
      setFormData({ ...formData, errors: { ...newError } })
    } else if (userParseObject.get('platforms')?.riot?.id === riotId) {
      const newError = createNewError('riotId', 'Riot ID is already updated', 'warning')
      setFormData({ ...formData, errors: { ...newError } })
    } else {
      try {
        const platforms = userParseObject.get('platforms')

        const newPlatform = {
          ...platforms,
          riot: {
            ...platforms?.riot,
            id: riotId,
          },
        }

        userParseObject.set('platforms', newPlatform)
        await userParseObject.save()

        const newError = createNewError('riotId', 'Riot ID updated successfully', 'success')
        setFormData({ ...formData, errors: { ...newError } })

        fetchCurrentUser()
      } catch (error) {
        customToast(error.message, 'danger')
      }
    }
  }

  const handleSavePubgUsername = async () => {
    clearErrors()
    if (!pubgUsername) {
      const newError = createNewError('pubgUsername', 'PUBG Username is required', 'danger')
      setFormData({ ...formData, errors: { ...newError } })
    } else if (userParseObject.get('platforms')?.steam?.games?.['10906']?.userName === pubgUsername) {
      const newError = createNewError('pubgUsername', 'PUBG Username is already updated', 'warning')
      setFormData({ ...formData, errors: { ...newError } })
    } else {
      try {
        const checkPubgUsernameCloud = await Parse.Cloud.run('check-pubg-username', {
          username: pubgUsername,
        })

        if (checkPubgUsernameCloud) {
          try {
            const platforms = userParseObject.get('platforms')

            const newPlatform = {
              ...platforms,
              steam: {
                ...platforms?.steam,
                games: {
                  ...platforms?.steam?.games,
                  10906: {
                    ...platforms?.steam?.games?.['10906'],
                    name: 'pubg',
                    userName: pubgUsername,
                  },
                },
              },
            }

            userParseObject.set('platforms', newPlatform)
            await userParseObject.save()

            const newError = createNewError('pubgUsername', 'PUBG Username updated successfully', 'success')
            setFormData({ ...formData, errors: { ...newError } })

            fetchCurrentUser()
          } catch (error) {
            customToast(error.message, 'danger')
          }
        }
      } catch (error) {
        const newError = createNewError('pubgUsername', 'PUBG Username is not valid', 'danger')
        setFormData({ ...formData, errors: { ...newError } })
      }
    }
  }

  const handleSaveLolRegion = async () => {
    clearErrors()
    if (!lolRegion) {
      const newError = createNewError('lolRegion', 'LOL Region is required', 'danger')
      setFormData({ ...formData, errors: { ...newError } })
    } else if (userParseObject.get('platforms')?.riot?.games?.['5426']?.region === lolRegion) {
      const newError = createNewError('lolRegion', 'LOL Region is already updated', 'warning')
      setFormData({ ...formData, errors: { ...newError } })
    } else {
      try {
        const platforms = userParseObject.get('platforms')

        const newPlatform = {
          ...platforms,
          riot: {
            ...platforms?.riot,
            games: {
              ...platforms?.riot?.games,
              5426: {
                ...platforms?.steam?.games?.['5426'],
                name: 'lol',
                region: lolRegion,
              },
            },
          },
        }

        userParseObject.set('platforms', newPlatform)
        await userParseObject.save()

        const newError = createNewError('lolRegion', 'LOL Region updated successfully', 'success')
        setFormData({ ...formData, errors: { ...newError } })

        fetchCurrentUser()
      } catch (error) {
        customToast(error.message, 'danger')
      }
    }
  }

  return (
    <section className="account-order p-5 bg-black  justify-content-start  flex-column profile-box-h ">
      <h4 className=" text-white border-bottom mb-5">Gaming Profile</h4>
      <div>
        <div className="row col-6 d-flex justify-content-center py-5 mt-1 ">
          <div>
            <a href="https://api.evolutionofgaming.io/oauth/steam" className="text-white">
              <img src="/assets/img/steamsignin.png" alt="Steam-login" />
            </a>
          </div>
        </div>
        <p className=" text-white my-1">Steam ID</p>
        <div className="row d-flex justify-content-between align-items-center  pb-2">
          <div className="col-md-5 text-field-container">
            <input
              type="text"
              className={`form-control bg-white p-2 ${
                formData.errors.steamId &&
                (formData.errors.steamId.type === 'danger'
                  ? 'has-error'
                  : formData.errors.steamId.type === 'success' && 'has-success')
              }`}
              id="input-steam-id"
              value={steamId}
              placeholder="Enter Steam ID"
              name="steamId"
              onChange={(e) => onChangeInput(e)}
            />
          </div>
          <div className="col-3 text-right">
            <button className="btn btn-primary btn-sm mt-2 " onClick={handleSaveSteamId}>
              Save
            </button>
          </div>
        </div>
        <p className=" text-white my-1">Riot ID</p>
        <div className="row d-flex justify-content-between align-items-center  pb-2">
          <div className="col-md-5 text-field-container">
            <input
              type="text"
              className={`form-control bg-white p-2 ${
                formData.errors.riotId &&
                (formData.errors.riotId.type === 'danger'
                  ? 'has-error'
                  : formData.errors.riotId.type === 'success' && 'has-success')
              }`}
              id="input-riot-id"
              defaultValue={riotId}
              placeholder="Enter Riot ID"
              name="riotId"
              onChange={(e) => onChangeInput(e)}
            />
          </div>
          <div className="col-3 text-right">
            <button className="btn btn-primary btn-sm mt-2 " onClick={handleSaveRiotId}>
              Save
            </button>
          </div>
        </div>
        <p className="text-white my-1">PUBG Username</p>
        <div className="row d-flex justify-content-between align-items-center  pb-2">
          <div className="col-md-5 text-field-container">
            <input
              type="text"
              className={`form-control bg-white p-2 ${
                formData.errors.pubgUsername &&
                (formData.errors.pubgUsername.type === 'danger'
                  ? 'has-error'
                  : formData.errors.pubgUsername.type === 'success' && 'has-success')
              }`}
              id="input-pubg-username"
              defaultValue={pubgUsername}
              placeholder="Enter PUBG Username"
              name="pubgUsername"
              onChange={(e) => onChangeInput(e)}
            />
          </div>
          <div className="col-3 text-right">
            <button className="btn btn-primary btn-sm mt-2 " onClick={handleSavePubgUsername}>
              Save
            </button>
          </div>
        </div>
        <p className=" text-white my-1">LOL Region</p>
        <div className="row d-flex justify-content-between align-items-center  pb-2">
          <div className="col-md-5 text-field-container">
            <input
              type="text"
              className={`form-control bg-white p-2 ${
                formData.errors.lolRegion &&
                (formData.errors.lolRegion.type === 'danger'
                  ? 'has-error'
                  : formData.errors.lolRegion.type === 'success' && 'has-success')
              }`}
              id="input-lol-region"
              defaultValue={lolRegion}
              placeholder="Enter LOL Region"
              name="lolRegion"
              onChange={(e) => onChangeInput(e)}
            />
          </div>
          <div className="col-3 text-right">
            <button className="btn btn-primary btn-sm mt-2 " onClick={handleSaveLolRegion}>
              Save
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  userParseObject: state.user.userParseObject,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})

const GamingProfile = connect(mapStateToProps, mapDispatchToProps)(GamingProfileComponent)
export { GamingProfile }
