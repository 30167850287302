import React, { useState, useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Parse from 'parse';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/button/Button'
import { ResetPasswordModal } from './ResetPasswordModal'
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { Link } from 'react-router-dom';

function LoginContent(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const inputUser = useRef()

  useEffect(() => {
    inputUser.current.focus()
  }, [isLoginFailed])

  const handleLogin = async () => {
    setLoading(true);
    const user = new Parse.User();
    user.set('username', userName);
    user.set('password', password);
    user.logIn().then((user) => {
      props.fetchCurrentUser()
      navigate('/')
      setUserName('')
      setPassword('')
    }).catch(err => {
      setLoading(false);
      setIsLoginFailed(err.message)
    });
  };

  const handleSubmit = (e) => {
    handleLogin();
    setIsLoginFailed(false)
  };

  const handleSubmitOnEnter = (e) => {
    if (e.keyCode === 13) handleSubmit()
  }

  return (
    <main className="site-content text-center" id="wrapper">
      <div className="site-content__center col-sm-4">
        <Form.Group className="mb-3 mx-5 text-left" >
          <Form.Label className="text-white ">Username</Form.Label>
          <Form.Control
            className="p-2 bg-white"
            onKeyDown={handleSubmitOnEnter}
            onChange={(e) => setUserName(e.target.value.toLowerCase())}
            name='username'
            ref={inputUser}
            placeholder="Enter Username" />
        </Form.Group>
        <Form.Group className=" mx-5 text-left" controlId="formBasicPassword">
          <Form.Label className="text-white " >Password</Form.Label>
          <Form.Control
            className="p-2 bg-white"
            onKeyDown={handleSubmitOnEnter}
            onChange={(e) => setPassword(e.target.value)}
            name='password'
            type="password"
            placeholder="Password" />
          <br />
          <ResetPasswordModal >
            Forgot your password?
          </ResetPasswordModal>
          <br />
          <Link to="/signup" className="text-white">
            <span className='text-white cursor-pointer'>I don't have account</span>
          </Link>
          {isLoginFailed && <p className="text-light text-center mt-2 display-5 border border-danger shadow-sm  mb-2 px-1">{isLoginFailed}</p>}
        </Form.Group>
        <Button onClick={handleSubmit} className='px-5 py-2 ' style={{ minHeight: '45px', minWidth: '150px' }}>
          {
            loading ? <div className="spinner-border spinner-border-sm mx-3 my-1" role="status" style={{ width: '.9rem', height: '.9rem', fontWeight: 'bold' }}>
            </div> : <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Login</span>
          }
        </Button>
      </div>
    </main>
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser())
})


export default connect(undefined, mapDispatchToProps)(LoginContent)
