import React, { useEffect, useRef, useState } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";

export function Referral() {
  const [referralId, setReferralId] = useState(null);
  const linkRef = useRef();
  const url = document.location.origin;

  useEffect(() => {
    getReferenceCode();
  }, []);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(linkRef.current.innerText);
  };

  const getReferenceCode = () => {
    const currentUser = Parse.User.current();
    setReferralId(currentUser.get('referralCode'));
  };

  return (
    <section className="account-order p-5 bg-black  justify-content-start flex-column profile-box-h">
      <h4 className="text-white border-bottom mb-5">Referral</h4>
      <div>
        <p className="text-white">
          Give this link to your friends and when they signup with this link or
          buy Stable Coin or compete games, you earn bonuses too and be eligible for
          next AirDrops
        </p>
        <div className="account-order__id">
          <span className="account-order__label">Referral Link</span>
          <Link
            ref={linkRef}
            to={`/signup?r=${referralId}`}
            className="color-success"
          >
            {`${url}/signup?r=${referralId}`}</Link>
          <svg
            style={{ cursor: "pointer" }}
            className="svg-icon ml-2 mb-1"
            onClick={() => copyToClipboard()}
            viewBox="0 0 1024 1024"
            fill="white"
            width="15"
            height="15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M640 320 192 320C156.608 320 128 348.608 128 384l0 512c0 35.328 28.608 64 64 64l448 0c35.392 0 64-28.672 64-64L704 384C704 348.608 675.392 320 640 320zM640 896 192 896 192 384l448 0L640 896zM832 128 352 128C334.336 128 320 142.336 320 160S334.336 192 352 192L832 192l0 544c0 17.664 14.336 32 32 32s32-14.336 32-32L896 192C896 156.608 867.392 128 832 128z" />
          </svg>
        </div>
      </div>
    </section>
  );
}