import React from 'react'
import { Hash } from 'components/hash/Hash'
import { storedGame } from 'utils/storedGame'

function CompetitionDetailsBox({ game, details, userAccount }) {
  const currentGame = storedGame.find((item) => item.overwolfId === +game.get('overwolfId'))
  return (
    <ul className="match-stats-widget__body">
      <li className="py-0">
        <figure className="match-team match-team--winner" role="group">
          <figure className="match-team-logo match-team-logo--team-1" role="group">
            <img
              src={currentGame.backgroundImageUrl}
              style={{ width: '53px' }}
              alt="game logo"
            />
          </figure>
        </figure>
        {game?.title}
      </li>
      {details && (
        <li>
          <span>Fee In USD</span>
          <span>{details.feeInUSD}</span>
        </li>
      )}
      <li>
        <span>Competitors</span>
        <span>
          {details.players} vs {details.players}
        </span>
      </li>
      {userAccount && (
        <li>
          <span>Address</span>
          <span>
            <Hash hash={userAccount} shorten={5} mode="address" />
          </span>
        </li>
      )}
    </ul>
  )
}

export default CompetitionDetailsBox
