import { Button } from 'components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { TwinSpin } from 'react-cssfx-loading/lib'

// utils
import { getCompetitionStatusText, getServerDate } from 'utils/helper'
import { connect } from 'react-redux'


const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`
}

const countdownFormater = (time) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60000)

  // Get the seconds left after converting minutes
  const seconds = ((time % 60000) / 1000).toFixed(0)

  //Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`
}

function InitializationComponent({ competitorStatus, competition, side1Members, side2Members, readyTimeout }) {
  const navigate = useNavigate()

  const [ready, setReady] = useState(null)
  const [allMembersAreReady, setAllMembersAreReady] = useState(false)
  const [status, setStatus] = useState(null)
  const [countdownTime, setCountdownTime] = useState(0)
  const [countdownFinishesAt, setCountdownFinishesAt] = useState(null)
  const [matchTimeout, setMatchTimeout] = useState(null)

  const pendingReadyTimeoutConfig = readyTimeout ? (readyTimeout * 60 * 1000) : (5 * 60 * 1000)

  useEffect(() => {
    const now = getServerDate().getTime()
    const timerStart = competitorStatus.get('joinedCompetitionAt').getTime()
    const timerEnd = timerStart + pendingReadyTimeoutConfig
    if (now > timerEnd) {
      // hang tight
      setCountdownTime(0)
    }
    else {
      const duration = timerEnd - now
      setCountdownTime(duration)
      setCountdownFinishesAt(timerEnd)

    }
    // eslint-disable-next-line
  }, [pendingReadyTimeoutConfig])


  useEffect(() => {
    let timer
    if (countdownTime > 0) {
      timer = setTimeout(() => {
        const now = getServerDate().getTime()
        const duration = countdownFinishesAt - now
        setCountdownTime(duration)
      }, 100)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [countdownTime, countdownFinishesAt])

  useEffect(() => {
    setReady(competitorStatus.get('ready'))
    showOneHourLater()
    //eslint-disable-next-line
  }, [competitorStatus])

  useEffect(() => {
    if (ready === false) navigate('/')
    //eslint-disable-next-line
  }, [ready])

  useEffect(() => {
    setStatus(getCompetitionStatusText(competition.get('status')))
  }, [competition])

  useEffect(() => {
    checkAllMembersReady(side1Members, side2Members)
  }, [side1Members, side2Members])

  const handleReady = async () => {
    if (!competitorStatus.get('ready')) {
      competitorStatus.set('ready', true)
      await competitorStatus.save()
      setReady(true)
    }
  }

  const handleCancel = async () => {
    const updatedCompetitorStatus = await competitorStatus.save({ ready: false })
    console.log(updatedCompetitorStatus)
    if (updatedCompetitorStatus.get('ready') === false)
      navigate(`/join/game/${competition.get('preset').get('game').get('overwolfId')}`)
  }

  function showOneHourLater() {
    const timerStart = competitorStatus.get('joinedCompetitionAt').getTime()
    const timerEnd = timerStart + (105 * 60 * 1000)
    const matchTimeout = new Date(timerEnd).toLocaleTimeString()
    setMatchTimeout(matchTimeout)
  }

  const getUserName = (competitorStatus) => {
    const playerData = competitorStatus?.toJSON().publicUserInfo
    if (playerData) {
      return playerData
    }
  }

  const checkAllMembersReady = (membersTeam1, memberTeam2) => {
    const allMembers = [...membersTeam1, ...memberTeam2]
    const allReady = allMembers.every((member) => member?.get('ready'))
    if (allReady) {
      setAllMembersAreReady(true)
    }
    else {
      setAllMembersAreReady(false)
    }
  }


  return (
    <div className="match-overview mt-sm-auto mb-sm-auto">
      <div className="match-overview__body">
        <figure className="match-team" role="group">
          <figure className="match-team-logo match-team-logo--team-1" role="group">
            <img
              src="/assets/img/samples/logo-team-a.png"
              srcSet="/assets/img/samples/logo-team-a@2x.png"
              alt="Team A Logo"
              width={180}
              height={180}
            />
          </figure>
          <figcaption>
            <div className="match-team__name">Team A</div>
            {/* <div className="match-team__country ">United States</div> */}
          </figcaption>
        </figure>
        <div className="d-flex flex-column justify-content-center align-items-center mx-5">
          <div className="d-flex">
            <table className="match-overview__table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {side1Members.map((member) => (
                  <tr className="text-nowrap">
                    <td>{getUserName(member).gamerid}</td>
                    <td>
                      <span className={member?.get('ready') ? 'color-primary' : 'color-danger'}>
                        {member?.get('ready') ? 'Ready' : 'Not Ready'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='px-2'></div>
            <table className="match-overview__table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {side2Members.map((member) => (
                  <tr className="text-nowrap">
                    <td>
                      <span className={member?.get('ready') ? 'color-primary' : 'color-danger'}>
                        {member?.get('ready') ? 'Ready' : 'Not Ready'}
                      </span>
                    </td>
                    <td>{getUserName(member).gamerid}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <TwinSpin onClick={() => alert("Clicked")} key="key" /> */}
          <div>
            {ready ? (
              <ul className="matches-tabs__navigation nav" role="tablist">
                <li className="nav-item">{status}</li>
              </ul>
            ) : (
              <Button
                onClick={handleReady}
                className="btn btn-primary btn-md mt-5 btn--landing mx-1 px-5 font-18">
                I'm Ready
              </Button>
            )}
          </div>
        </div>
        <figure className="match-team" role="group">
          <figure className="match-team-logo match-team-logo--team-2" role="group">
            <img
              src="/assets/img/samples/logo-team-b.png"
              srcSet="/assets/img/samples/logo-team-b@2x.png"
              alt="Team B Logo"
              width={180}
              height={180}
            />
          </figure>
          <figcaption>
            <div className="match-team__name">Team B</div>
            {/* <div className="match-team__country ">Japan</div> */}
          </figcaption>
        </figure>
      </div>
      <ul className="match-overview__footer list-unstyled d-flex justify-content-center">
        <li>
          <span>Match Expires By</span>{matchTimeout}
        </li>
        <li className="d-flex flex-column justify-content-center align-items-center">
          <div>
            <span>PLAYERS READY?</span>
            {
              allMembersAreReady ? <p className='ready-player-text'>ALL PLAYERS READY, HANG TIGHT...</p> :
                countdownTime <= 0 ? 'Hang Tight' : <div>{countdownFormater(countdownTime)}</div>
            }
          </div>
          <div>
            <Button onClick={handleCancel} className="btn btn-danger btn-md   mx-1 px-4 py-0">
              <span className="text-white pt-1"> Cancel</span>
            </Button>
          </div>
        </li>
        <li>
          <span>Game</span>
          {competition.get('preset').get('game').get('title')}
        </li>
      </ul>
      {/* <Redirect openModal={openRedirectModal} /> */}
    </div>
  )
}

export { InitializationComponent }

//eslint-disable-next-line
function Redirect({ openModal }) {
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    setModalShow(openModal)
  }, [openModal])

  return (
    <RedirectModal show={modalShow} onHide={() => setModalShow(false)} />
  )
}

function RedirectModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="bg-game-dark p-1"
      centered>
      <Modal.Header className={`btn text-right text-white  cursor-default `}>
        <button
          className=" text-center border-0 "
          style={{ backgroundColor: '#5e627e' }}
          onClick={props.onHide}>
          <span className="font-weight-bold">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center p-4 my-3 ">
        <main className=" site-content text-center col-10 mb-3">
          <div className="site-content__center ">
            <h3 className="text-warning ">Attention !</h3>
            <div className="d-flex justify-content-center p-3 my-4">
              <TwinSpin color="#a3ff12" width="80px" height="80px" duration=".4s" />
            </div>
            <p className="primary-green font-20 ">
              This competition has been canceled, you are redirecting to a new competition
            </p>
          </div>
        </main>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  readyTimeout: state.config?.['ready-timeout'],
})



const Initialization = connect(mapStateToProps)(InitializationComponent)
export { Initialization }
