import * as actions from '../actions'

const initState = {
  presetsParseObject: null,
  presets: null,
  loading: false,
  error: null,
}

export const presetReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_PRESETS:
      return { ...state, presetsParseObject: action.payload, loading: false }
    case actions.SET_PRESETS_ERROR:
      return {
        ...state,
        ...action.payload,
      }
    case actions.ADD_ACTIVE_PRESET:
      const preset = {
        presetId: action.payload.id,
        players: action.payload.get('players'),
        fee: action.payload.get('feeInUSD'),
        gameId: action.payload.get('game').get('overwolfId'),
        gameName: action.payload.get('game').get('title'),
      }
      const presets = [...state, preset]

      return [...presets]
    case actions.ADD_ACTIVE_PRESET_ERROR:
      return {
        ...state,
        ...action.payload,
      }
    case actions.REMOVE_ACTIVE_PRESET:
      const activePresets = state.filter((i) => i.presetId !== action.payload.id)

      return [...activePresets]
    case actions.REMOVE_ACTIVE_PRESET_ERROR:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
