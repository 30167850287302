import { web3Instance } from "../web3Instance"

const EOG_ComptitionABI =  [
   {
      "inputs": [],
      "stateMutability": "nonpayable",
      "type": "constructor"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "competitionId",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint8",
            "name": "teamSize",
            "type": "uint8"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "presetId",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "createAt",
            "type": "uint256"
         }
      ],
      "name": "NewCompetitionCreated",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "presetId",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "entryFeeInUSD",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "numberOfTeamMemebr",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "rakeAmountInUSD",
            "type": "uint256"
         }
      ],
      "name": "NewPresetCreated",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
         },
         {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
         }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "tokenIndex",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "lockAmount",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "newTotalLock",
            "type": "uint256"
         }
      ],
      "name": "PoolLocked",
      "type": "event"
   },
   {
      "anonymous": false,
      "inputs": [
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "tokenIndex",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "unlockAmount",
            "type": "uint256"
         },
         {
            "indexed": false,
            "internalType": "uint256",
            "name": "newTotalLock",
            "type": "uint256"
         }
      ],
      "name": "PoolUnlocked",
      "type": "event"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_presetId",
            "type": "uint256"
         },
         {
            "components": [
               {
                  "components": [
                     {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                     },
                     {
                        "internalType": "uint256",
                        "name": "tokenIndex",
                        "type": "uint256"
                     },
                     {
                        "internalType": "uint256",
                        "name": "payableInUSD",
                        "type": "uint256"
                     }
                  ],
                  "internalType": "struct Competitor[]",
                  "name": "competitors",
                  "type": "tuple[]"
               }
            ],
            "internalType": "struct Team[]",
            "name": "_teams",
            "type": "tuple[]"
         },
         {
            "internalType": "uint256",
            "name": "_createAt",
            "type": "uint256"
         }
      ],
      "name": "addNewCompetition",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_entryFeeInUSD",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_numberOfTeamMemebr",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_createAt",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_rakeAmountInUSD",
            "type": "uint256"
         }
      ],
      "name": "addNewPreset",
      "outputs": [
         {
            "internalType": "uint256",
            "name": "presetId",
            "type": "uint256"
         }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "contract IERC20",
            "name": "_tokenAddress",
            "type": "address"
         },
         {
            "internalType": "uint256",
            "name": "_stablePrice",
            "type": "uint256"
         }
      ],
      "name": "addToken",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
         }
      ],
      "name": "competitionList",
      "outputs": [
         {
            "internalType": "uint8",
            "name": "teamSize",
            "type": "uint8"
         },
         {
            "internalType": "enum CompetitionStatus",
            "name": "status",
            "type": "uint8"
         },
         {
            "internalType": "enum CompetitionWinner",
            "name": "winnerTeam",
            "type": "uint8"
         },
         {
            "internalType": "uint256",
            "name": "presetId",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "createAt",
            "type": "uint256"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_index",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
         }
      ],
      "name": "depositToken",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_index",
            "type": "uint256"
         },
         {
            "internalType": "contract IERC20",
            "name": "_newTokenAddress",
            "type": "address"
         }
      ],
      "name": "editTokenAddress",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [],
      "name": "getActiveTokens",
      "outputs": [
         {
            "components": [
               {
                  "internalType": "contract IERC20",
                  "name": "tokenAddress",
                  "type": "address"
               },
               {
                  "internalType": "uint256",
                  "name": "stablePrice",
                  "type": "uint256"
               },
               {
                  "internalType": "uint256",
                  "name": "totalBalance",
                  "type": "uint256"
               },
               {
                  "internalType": "uint256",
                  "name": "lockedBalance",
                  "type": "uint256"
               },
               {
                  "internalType": "bool",
                  "name": "isActive",
                  "type": "bool"
               }
            ],
            "internalType": "struct Token[]",
            "name": "",
            "type": "tuple[]"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [],
      "name": "getAllTokens",
      "outputs": [
         {
            "components": [
               {
                  "internalType": "contract IERC20",
                  "name": "tokenAddress",
                  "type": "address"
               },
               {
                  "internalType": "uint256",
                  "name": "stablePrice",
                  "type": "uint256"
               },
               {
                  "internalType": "uint256",
                  "name": "totalBalance",
                  "type": "uint256"
               },
               {
                  "internalType": "uint256",
                  "name": "lockedBalance",
                  "type": "uint256"
               },
               {
                  "internalType": "bool",
                  "name": "isActive",
                  "type": "bool"
               }
            ],
            "internalType": "struct Token[]",
            "name": "",
            "type": "tuple[]"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [],
      "name": "operator",
      "outputs": [
         {
            "internalType": "address",
            "name": "",
            "type": "address"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [],
      "name": "owner",
      "outputs": [
         {
            "internalType": "address",
            "name": "",
            "type": "address"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
         }
      ],
      "name": "presetList",
      "outputs": [
         {
            "internalType": "uint256",
            "name": "entryFeeInUSD",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "numberOfTeamMemebr",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "date",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "createAt",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "rakeAmountInUSD",
            "type": "uint256"
         },
         {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_index",
            "type": "uint256"
         }
      ],
      "name": "removeToken",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_competitionId",
            "type": "uint256"
         },
         {
            "internalType": "enum CompetitionWinner",
            "name": "_winnerTeam",
            "type": "uint8"
         }
      ],
      "name": "setCompetitionWinner",
      "outputs": [
         {
            "internalType": "bool",
            "name": "",
            "type": "bool"
         }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [],
      "name": "startCompetitionId",
      "outputs": [
         {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [],
      "name": "startPresetId",
      "outputs": [
         {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
         }
      ],
      "name": "tokens",
      "outputs": [
         {
            "internalType": "contract IERC20",
            "name": "tokenAddress",
            "type": "address"
         },
         {
            "internalType": "uint256",
            "name": "stablePrice",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "totalBalance",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "lockedBalance",
            "type": "uint256"
         },
         {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
         }
      ],
      "stateMutability": "view",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
         }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "address",
            "name": "_newOperator",
            "type": "address"
         }
      ],
      "name": "updateOperator",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_presetId",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_entryFeeInUSD",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_numberOfTeamMemebr",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_createAt",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_rakeAmountInUSD",
            "type": "uint256"
         },
         {
            "internalType": "bool",
            "name": "_isActive",
            "type": "bool"
         }
      ],
      "name": "updatePreset",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "tokenIndex",
            "type": "uint256"
         },
         {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
         }
      ],
      "name": "updateTokenActivate",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "tokenIndex",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "newRate",
            "type": "uint256"
         }
      ],
      "name": "updateTokenStablePrice",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   },
   {
      "inputs": [
         {
            "internalType": "uint256",
            "name": "_index",
            "type": "uint256"
         },
         {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
         }
      ],
      "name": "withdrawToken",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
   }
]
export const EOG_ComptitionAddress = '0x991d40F36b286c4E147445bf7E94aba89F7a9375'
export const EOG_CompetitionContract = new web3Instance.eth.Contract(EOG_ComptitionABI, EOG_ComptitionAddress)
