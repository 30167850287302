import React from "react";
import { GamesLayout } from "layouts";
import { Preloader, SvgColorFilter } from "components";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <GamesLayout bgImage="bg--texture-01 site-layout--landing-error">
          <div className="site-wrapper">
            <main className="site-content" id="wrapper">
              <div className="container">
                <div className="row">
                  <div className="error__logo-wrapper col-md-6 d-none d-md-block">
                    <img src="/assets/img/samples/page-bg-logo.png" className="error__logo" alt="evolve" />
                    <div className="ncr-page-decor">
                      <div className="ncr-page-decor__layer-3" />
                    </div>
                  </div>
                  <div className="error__txt-wrapper col-md-5 align-self-center offset-md-1">
                    <h1 className="error__title">Oops.</h1>
                    <h2 className="error__subtitle">Something Went wrong !</h2>
                    <div className="error__desc">
                      <p>
                        <a href=' ' onClick={() => {
                          window.location.reload();
                        }}  > Click here </a> to go back !
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <div className="site-overlay" />
          </div>
          <Preloader />
          <SvgColorFilter />
        </GamesLayout>
      );
    }

    return this.props.children;
  }
}