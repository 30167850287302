import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { useState, useEffect } from 'react';
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons'
import Parse from 'parse';

const User = Parse.Object.extend('User')

const VerifyIdentityComponent = (props) => {
  const [user, setUser] = useState(null);
  const [userSubscription, setUserSubscription] = useState()
  const [userIsAllowedToKYC, setUserIsAllowedToKYC] = useState(false)
  const [KYCOpeningLevel, setKYCOpeningLevel] = useState(0)
  const {
    fetchedUserParseObject,
    KYCDisabledMessage,
    KYCDisabled,
    levelRequirements,
    IdentityVerification
  } = props


  const veriff = Veriff({
    host: 'https://stationapi.veriff.com',
    apiKey: process.env.REACT_APP_VERIFF_KEY,
    parentId: 'veriff-root',
    onSession: function (err, response) {
      err && console.log(err)
      createVeriffFrame({
        url: response.verification.url,
        onEvent: function (msg) {
          switch (msg) {
            case MESSAGES.FINISHED:
              sendVeriffSession(response);
              break;
            default:
              break;
          }
        }
      })
    }
  });

  useEffect(() => {
    if (user) {
      veriff.setParams({
        vendorData: fetchedUserParseObject.id
      });
      veriff.mount({
        submitBtnText: 'Get verified'
      });
    }
    // eslint-disable-next-line
  }, [user, userIsAllowedToKYC])

  useEffect(() => {
    if (fetchedUserParseObject) {
      if (!userSubscription) {
        liveIdentificationStatus();
      }
      setUser({
        veriffSessionId: fetchedUserParseObject.get('veriffSessionId'),
        veriffSessionStatus: fetchedUserParseObject.get('veriffSessionStatus'),
      })
    }
    // eslint-disable-next-line 
  }, [fetchedUserParseObject])

  useEffect(() => {
    isUserLevelEnoughToKYC()
    // eslint-disable-next-line 
  }, [fetchedUserParseObject, levelRequirements])


  useEffect(() => {
    return () => {
      if (userSubscription) {
        userSubscription.unsubscribe()
      }
    }
  }, [userSubscription])

  const sendVeriffSession = async (response) => {
    fetchedUserParseObject.set('veriffSessionId', response?.verification?.id)
    await fetchedUserParseObject.save()
  }

  const liveIdentificationStatus = async () => {
    const identificationStatusQuery = new Parse.Query(User);
    identificationStatusQuery.equalTo('objectId', fetchedUserParseObject?.id)
    let identificationSub = await identificationStatusQuery.subscribe();
    setUserSubscription(identificationSub)
    identificationSub.on('open', () => {
      console.log('User subscription opened')
    })
    identificationSub.on('update', (object) => {
      setUser(object.toJSON())
    });
  }

  const isUserLevelEnoughToKYC = () => {
    const kycLevel = levelRequirements?.findIndex(level => level.kyc)
    const userLevel = fetchedUserParseObject?.get('level')
    setKYCOpeningLevel(kycLevel - 1)
    setUserIsAllowedToKYC(userLevel >= kycLevel - 1)
  }
  console.log(props.IdentityVerification)



  return (
    <section className="account-order p-5 bg-black  justify-content-start flex-column profile-box-h">
      <div className='d-flex justify-content-between border-bottom mb-5 align-items-center'>
        <h4 className="text-white">Identity</h4>
        {IdentityVerification ? <h5 className='color-success'>Verified ✓</h5> : <h5 className='text-danger'>Not Verified</h5>}
      </div>
      {!IdentityVerification && <div className="alert alert-warning text-left" >
        You have to provide your identity information and go through the verification process in order to use our system.
      </div>}

      {
        KYCDisabled ?
          <div className=" d-flex justify-content-center align-items-center flex-grow-1 flex-column text-white">
            <p className="text-white">
              {
                user?.veriffSessionId && user?.veriffSessionStatus === 'approved' ?
                  <>
                    <h5 className='color-success'>Your Identity Is Verified  <strong>&nbsp; ✓</strong></h5>
                    <div id='veriff-root' className='d-none'  ></div>
                  </>
                  :
                  KYCDisabledMessage ? KYCDisabledMessage : 'KYC is disabled'
              }
            </p>
            <div id='veriff-root' className='d-none'></div>
          </div>
          : !props.IdentityVerification ?
            <>
              <p className="text-white h4-5 d-inline-flex align-items-center text-center justify-content-center py-3 mt-5">
                <FontAwesomeIcon icon={faLock} size="sm" className='pb-2' /> &nbsp;  Unlock At Level 3
              </p>
              <div className=" d-flex  align-items-center flex-grow-1 flex-column">
                <h5 className='color-warning text-center text-transform-none font-weight-light px-3'>
                  You can verify your identity when you reached level {KYCOpeningLevel} . <br />
                  This will help you achieve higher levels and unlock more benefits.
                </h5>
                <div id='veriff-root' className='d-none'  ></div>
              </div>
            </>
            : <div className=" d-flex justify-content-center align-items-center flex-grow-1 flex-column">
              {
                !user?.veriffSessionId &&
                <div id='veriff-root' ></div>
              }
              {
                user?.veriffSessionId && user?.veriffSessionStatus === 'approved' &&
                <h5 className='color-success'>Your Identity Is Verified  <strong>&nbsp; ✓</strong></h5>
              }
              {
                user?.veriffSessionId && user?.veriffSessionStatus === 'declined' &&
                <h5 className='color-danger'>Your identity is not verified and your application is rejected  <strong>&nbsp; ✖</strong></h5>
              }
              {
                user?.veriffSessionId && user?.veriffSessionStatus === 'resubmission_required' &&
                <div>
                  <h5 className='color-warning text-center'>You need to resubmit document   <strong>&nbsp; !</strong></h5>
                  <div id='veriff-root'></div>
                </div>
              }
              {
                user?.veriffSessionId && !user?.veriffSessionStatus &&
                <h4 className='color-success'  >   Processing...  </h4>
              }
              <div id='veriff-root' className='d-none'  ></div>
            </div>
      }
    </section>
  )
}

const mapStateToProps = (state) => ({
  fetchedUserParseObject: state.user.userParseObject,
  KYCDisabledMessage: state.config['kyc-disabled-message'],
  KYCDisabled: state.config['kyc-disabled'],
  levelRequirements: state.config['level-requirements'],
  IdentityVerification: state.user.userParseObject?.get('KYCAt'),
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})

const VerifyIdentity = connect(mapStateToProps, mapDispatchToProps)(VerifyIdentityComponent);
export { VerifyIdentity }
