import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Parse from 'parse/lib/browser/Parse'
import { connect } from 'react-redux'
import { isUserCountryRestricted } from 'utils/helper'

function HomeContentComponent(props) {
  const {
    fetchedUserParseObject,
    icoEnabled,
    gamingEnabled,
    icoEnabledMessage,
    icoDisabledMessage,
    userGamingEnabled,
    userIcoEnabled,
    config,
  } = props

  const [activeCompetition, setActiveCompetition] = useState('')
  const [hasResult, setHasResult] = useState('')
  const [userCountryIsRestrictedToICO, setUserCountryIsRestrictedToICO] = useState(false)

  useEffect(() => {
    checkActiveCompetition()

    const isRestricted = isUserCountryRestricted(fetchedUserParseObject, config)
    setUserCountryIsRestrictedToICO(isRestricted)

  }, [fetchedUserParseObject, config])

  const checkActiveCompetition = async () => {
    try {
      const participation = await Parse.Cloud.run('get-latest-competitior-status')
      if (!participation) {
        setActiveCompetition(false)
      } else if (!participation.get('competition') || !participation.get('competition').get('finishedAt')) {
        setActiveCompetition(true)
      } else {
        setActiveCompetition(false)
        setHasResult(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const icoMessageMarkup = () => {
    let userHasICOAccess = false
    if (userGamingEnabled === false || (userGamingEnabled === null && !gamingEnabled)) {
      if (
        userIcoEnabled === true ||
        (userIcoEnabled === null && icoEnabled && !userCountryIsRestrictedToICO)
      ) {
        userHasICOAccess = true
      } else if (userIcoEnabled === true) {
        userHasICOAccess = true
      }
      if (userHasICOAccess) {
        return (
          <div>
            <span className="color-success fw-bold">{icoEnabledMessage}</span>
          </div>
        )
      } else {
        return (
          <div>
            <span className="color-success fw-bold">{icoDisabledMessage}</span>
          </div>
        )
      }
    }
  }

  return (
    <>
      <main className="site-content text-center" id="wrapper">
        <div className="site-content__center">
          <h1 className="text-white landing-title">
            <span className="subtitle landing-subtitle">THE EVOLUTION OF GAMING</span>
            EVOLVE
          </h1>
          {gamingEnabled && userGamingEnabled !== false && activeCompetition && (
            <div className="align-items-center d-flex flex-column justify-content-center">
              <span className="text-warning "> You are in an ongoing competition</span>
              <Link to={'/active-competition'}>
                <span className="btn btn-primary btn-lg btn--landing mx-1 my-1">
                  <span>Go To the Active Competition</span>
                </span>
              </Link>
              <span className="mt-2">.</span>
            </div>
          )}
          {!gamingEnabled && userGamingEnabled && activeCompetition && (
            <div className="align-items-center d-flex flex-column justify-content-center">
              <span className="text-warning "> You are in an ongoing competition</span>
              <Link to={'/active-competition'}>
                <span className="btn btn-primary btn-lg btn--landing mx-1 my-1">
                  <span>Go To the Active Competition</span>
                </span>
              </Link>
              <span className="mt-2">.</span>
            </div>
          )}
          {gamingEnabled && userGamingEnabled !== false && !activeCompetition && !hasResult && (
            <div className="align-items-center d-flex flex-column justify-content-center">
              <span className="text-warning opacity-0">.</span>
              <Link to={'/join'}>
                <span className="btn btn-primary btn-lg btn--landing mx-1 my-1">
                  <span>Start Competing</span>
                </span>
              </Link>
              <span className="mt-2">.</span>
            </div>
          )}{' '}
          {!gamingEnabled && userGamingEnabled !== false && !activeCompetition && !hasResult && (
            <div className="align-items-center d-flex flex-column justify-content-center">
              <span className="text-warning opacity-0">.</span>
              <Link to={'/join'}>
                <span className="btn btn-primary btn-lg btn--landing mx-1 my-1">
                  <span>Start Competing</span>
                </span>
              </Link>
              <span className="mt-2">.</span>
            </div>
          )}
          {gamingEnabled && userGamingEnabled !== false && !activeCompetition && hasResult && (
            <div className="align-items-center d-flex flex-column justify-content-center">
              <span className="text-warning opacity-0">.</span>
              <Link to={'/join'}>
                <span className="btn btn-primary btn-lg btn--landing mx-1 my-1">
                  <span>Start a new competition</span>
                </span>
              </Link>
              <Link to={'/active-competition'} className="text-white mt-2">
                <u> Check the result of your last competition</u>
              </Link>
            </div>
          )}
          {!gamingEnabled && userGamingEnabled && !activeCompetition && hasResult && (
            <div className="align-items-center d-flex flex-column justify-content-center">
              <span className="text-warning opacity-0">.</span>
              <Link to={'/join'}>
                <span className="btn btn-primary btn-lg btn--landing mx-1 my-1">
                  <span>Start a new competition</span>
                </span>
              </Link>
              <Link to={'/active-competition'} className="text-white mt-2">
                <u> Check the result of your last competition</u>
              </Link>
            </div>
          )}
          {icoMessageMarkup()}
        </div>
      </main>
    </>
  )
}

const mapStateToProps = (state) => ({
  fetchedUserParseObject: state.user.userParseObject,
  gamingEnabled: state.config?.['gaming-enabled'],
  icoEnabled: state.config?.['ico-enabled'],
  icoEnabledMessage: state.config?.['ico-enabled-message'],
  icoDisabledMessage: state.config?.['ico-disabled-message'],
  userGamingEnabled: state.user?.userGamingEnabled,
  userIcoEnabled: state.user?.userIcoEnabled,
  config: state.config,
})


const HomeContent = connect(mapStateToProps)(HomeContentComponent)
export { HomeContent }
