import React, { useEffect } from 'react'
import Parse from 'parse';
import { useNavigate } from 'react-router-dom';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getConfig, setLoading } from 'redux/actions/configActions'
import { callIPCountryWebhook, playPreloader } from 'utils/helper';

function AuthProviderComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getConfig, config, fetchCurrentUser, setLoading, loading, fetchedUser } = props;
  const [IPWebhookIsCalled, setIPWebhookIsCalled] = React.useState(false);



  useEffect(() => {
    (async () => {
      try {
        if (!fetchedUser) await fetchCurrentUser() // fetch and set in redux
        if (!IPWebhookIsCalled) {
          callIPCountryWebhook()
          setIPWebhookIsCalled(true)
        }
        const userVerification = Parse.User.current().get('verifiedAt'); // throw error if user doesn't exist to redirect to login
        if (!config['token-price']) {
          await getConfig();
          setLoading(false)
        }
        playPreloader()
        if (!userVerification && !location.pathname.match(/^\/profile/)) navigate('/profile/account-status')
      }
      catch (error) {
        console.log(error)
        playPreloader()
        if (!(location.pathname.match(/^\/(signup|login)/))) navigate('/login')
      }
    })()
    // eslint-disable-next-line
  }, [location, loading])

  return (
    <>
      {props.children}
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.config?.loading,
  fetchedUser: state.user?.userParseObject,
  config: state.config
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  getConfig: () => dispatch(getConfig()),
  setLoading: (bool) => dispatch(setLoading(bool))
})

const AuthProvider = connect(mapStateToProps, mapDispatchToProps)(AuthProviderComponent)
export { AuthProvider }
