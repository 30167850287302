import React from 'react'
import { GamesLayout } from 'layouts'

function SteamAuth() {
  return (
    <GamesLayout>

      <div id="user-main" className="text-white bg-game-dark p-5 mx-auto  col-sm-9 text-center mt-5" style={{ height: '70%' }}>

        <div className="" style={{ padding: 0 }}>
          <div id="user-main-content">
            <h2 className='text-white'>Automatic Match Tracking</h2>
            <div>
              <div>
                <div>
                  To enable automatic match tracking please enter your game authentication code below.
                </div>
                <div className='mt-5 '>
                  <h4 className='text-white mt-5 pt-5' >
                    Your game authentication code
                  </h4>
                  <div style={{ borderTop: '1px solid #3d404c', marginTop: '20px', marginBottom: '20px' }} />
                  <div style={{ paddingBottom: '24px' }}>
                    Valve has created a support page to help you create or access your game authentication code. <a href="https://help.steampowered.com/en/wizard/HelpWithGameIssue/?appid=730&issueid=128" target="_blank" rel="nofollow noopener noreferrer">Get your game authentication code</a>
                  </div>
                  <form method="post" action="">
                    <label style={{ display: 'block' }}>Game Authentication Code:</label>
                    <input name="game_authentication_code" type="text" />
                    <br />
                    <button className="btn btn-primary btn-sm mt-3">Add</button>
                    <input type="hidden" name="_token"  />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      );

    </GamesLayout >
  )
}

export { SteamAuth }
