/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import { FooterMenu } from 'components'
import React, { useEffect } from 'react'

function GamesLayout({ bgImage, children }) {
  useEffect(() => {
  }, [])


  return (
    <div
      id="body"
      className={`${bgImage ? bgImage : 'bg--texture-01'
        } bg-fixed bg--dotted-3x3 bg-image preloader-is--active scroll-is--active`}>
      <div className="site-wrapper site-layout--default">
        <FooterMenu />

        {children}

        <div className="site-overlay" />
        <div className="cart-panel">
          <h4 className="cart-panel__title text-white">
            Shopping Cart (<span className="cart-panel__items-count">4</span>)
          </h4>
          <div className="cart-panel__content">
            <div className="table-responsive">
              <table className="table shop-table">
                <thead>
                  <tr>
                    <th className="product__info">Product</th>
                    <th className="product__quantity">Quantity</th>
                    <th className="product__total">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="product__info">
                      <figure className="product__thumb"  >
                        <a href=" ">
                          <img src="/assets/img/cart-panel-img-01.jpg" alt='evolve' />
                        </a>
                      </figure>
                      <div className="product__info-content">
                        <h5 className="product__name">
                          <a href=" ">Magimons Black Frame Print</a>
                        </h5>
                        <span className="product__cat">Framed Prints</span>
                        <span className="product__info-details">24"x12" | Glossy</span>
                      </div>
                    </td>
                    <td className="product__quantity">
                      <div className="quantity-control">
                        <button className="quantity-control__minus">&nbsp;</button>
                        <input type="number" step={1} defaultValue={1} size={4} />
                        <button className="quantity-control__plus">&nbsp;</button>
                      </div>
                    </td>
                    <td className="product__total">
                      <span className="product__total-currency">$</span>38.00
                    </td>
                    <td className="product__remove">
                      <a href=" " className="product__remove-icon" />
                    </td>
                  </tr>
                  <tr>
                    <td className="product__info">
                      <figure className="product__thumb">
                        <a href=" ">
                          <img src="/assets/img/cart-panel-img-02.jpg" />
                        </a>
                      </figure>
                      <div className="product__info-content">
                        <h5 className="product__name">
                          <a href=" ">Necromancers Men's T-Shirt</a>
                        </h5>
                        <span className="product__cat">Clothing</span>
                        <span className="product__info-details">Black | Medium</span>
                      </div>
                    </td>
                    <td className="product__quantity">
                      <div className="quantity-control">
                        <button className="quantity-control__minus">&nbsp;</button>
                        <input type="number" step={1} defaultValue={2} size={4} />
                        <button className="quantity-control__plus">&nbsp;</button>
                      </div>
                    </td>
                    <td className="product__total">
                      <span className="product__total-currency">$</span>49.98
                    </td>
                    <td className="product__remove">
                      <a href=" " className="product__remove-icon" />
                    </td>
                  </tr>
                  <tr>
                    <td className="product__info">
                      <figure className="product__thumb">
                        <a href=" ">
                          <img src="/assets/img/cart-panel-img-03.jpg" />
                        </a>
                      </figure>
                      <div className="product__info-content">
                        <h5 className="product__name">
                          <a href=" ">Necromancers Ennamel Mug</a>
                        </h5>
                        <span className="product__cat">Coffee Mugs</span>
                        <span className="product__info-details">White</span>
                      </div>
                    </td>
                    <td className="product__quantity">
                      <div className="quantity-control">
                        <button className="quantity-control__minus">&nbsp;</button>
                        <input type="number" step={1} defaultValue={1} size={4} />
                        <button className="quantity-control__plus">&nbsp;</button>
                      </div>
                    </td>
                    <td className="product__total">
                      <span className="product__total-currency">$</span>16.00
                    </td>
                    <td className="product__remove">
                      <a href=" " className="product__remove-icon" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cart-panel__totals">
              <div className="table-responsive">
                <table className="table">
                  <tfoot>
                    <tr className="cart-panel__subtotal">
                      <th>Cart Subtotal</th>
                      <td>
                        <span className="amount">
                          <span className="amount-currency">$</span>103.98
                        </span>
                      </td>
                    </tr>
                    <tr className="cart-panel__shipping">
                      <th>Estimated Shipping</th>
                      <td>
                        <span className="amount">
                          <span className="amount-currency">$</span>14.00
                        </span>
                      </td>
                    </tr>
                    <tr className="cart-panel__order-total">
                      <th>Cart Total</th>
                      <td>
                        <span className="amount">
                          <span className="amount-currency">$</span>117.98
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <a href="shop-checkout.html" className="btn btn-primary">
                  Go to checkout
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-panel">
          <ul className="menu-panel__mobile-bar list-unstyled d-md-none">
            <li className="mobile-bar-item">
              <a
                className="mobile-bar-item__header collapsed"
                data-toggle="collapse"
                href="#mobile_collapse_1"
                role="button"
                aria-expanded="false"
                aria-controls="mobile_collapse_1">
                Main Links
                <span className="main-nav__toggle">&nbsp;</span>
              </a>
              <div id="mobile_collapse_1" className="collapse mobile-bar-item__body">
                <nav className="mobile-nav">
                  <ul className="mobile-nav__list">
                    <li >
                      <a href=" ">Main Pages</a>
                      <ul className="mobile-nav__sub">
                        <li >
                          <a href="index.html">Landing Image</a>
                        </li>
                        <li >
                          <a href="index-2.html">Landing Video</a>
                        </li>
                        <li >
                          <a href="home.html">Home page</a>
                        </li>
                        <li >
                          <a href="blog-post.html">Post Page</a>
                        </li>
                        <li >
                          <a href="blog-1.html">News v1</a>
                        </li>
                        <li >
                          <a href="blog-2.html">News v2</a>
                        </li>
                        <li >
                          <a href="blog-3.html">News v3</a>
                        </li>
                        <li >
                          <a href="blog-4.html">News v4</a>
                        </li>
                        <li >
                          <a href="shop-account-settings.html">Account Settings</a>
                        </li>
                        <li >
                          <a href="shop-account-orders.html">Account Orders</a>
                        </li>
                        <li >
                          <a href="login-register.html">Login &amp; Register</a>
                        </li>
                        <li >
                          <a href="features-about-us.html">About Us</a>
                        </li>
                        <li >
                          <a href="features-contact-us.html">Contact Us</a>
                        </li>
                        <li >
                          <a href="features-faqs.html">FAQs</a>
                        </li>
                        <li >
                          <a href="management-and-staff.html">Staff</a>
                        </li>
                        <li >
                          <a href="staff-member.html">
                            Staff Member
                            <span className="badge badge-danger">New</span>
                          </a>
                        </li>
                        <li >
                          <a href="streams-archive.html">Streams Page</a>
                        </li>
                        <li >
                          <a href="partners.html">Our Partners</a>
                        </li>
                        <li >
                          <a href="features-shortcodes.html">Shortcodes</a>
                        </li>
                        <li >
                          <a href="features-typography.html">Typography</a>
                        </li>
                        <li >
                          <a href="features-icons.html">Icons</a>
                        </li>
                        <li >
                          <a href="features-404.html">
                            404 Error <span className="badge badge-danger">New</span>
                          </a>
                        </li>
                        <li >
                          <a href="features-bg-1.html">Backgrounds</a>
                        </li>
                      </ul>
                    </li>
                    <li >
                      <a href=" ">Team Pages</a>
                      <ul className="mobile-nav__sub">
                        <li >
                          <a href="team-selection-1.html">Team Selection v1</a>
                        </li>
                        <li >
                          <a href="team-selection-2.html">Team Selection v2</a>
                        </li>
                        <li >
                          <a href="team-selection-3.html">Team Selection v3</a>
                        </li>
                        <li className="active">
                          <a href="team-selection-4.html">Team Selection v4</a>
                        </li>
                        <li >
                          <a href="team-overview.html">Team Overview v1</a>
                        </li>
                        <li >
                          <a href="team-overview-2.html">Team Overview v2</a>
                        </li>
                        <li>
                          <a href="team-overview.html?slide=1">Team Statistics</a>
                        </li>
                        <li>
                          <a href="team-overview.html?slide=2">Team Achievements</a>
                        </li>
                        <li>
                          <a href="team-overview.html?slide=3">Team Matches</a>
                        </li>
                      </ul>
                    </li>
                    <li >
                      <a href=" ">Player Pages</a>
                      <ul className="mobile-nav__sub">
                        <li>
                          <a href="team-player-1.html">Player Overview</a>
                        </li>
                        <li>
                          <a href="team-player-1.html?slide=1">Player Statistics</a>
                        </li>
                        <li>
                          <a href="team-player-1.html?slide=2">Player Achievements</a>
                        </li>
                        <li>
                          <a href="team-player-1.html?slide=3">Player Hardware</a>
                        </li>
                        <li>
                          <a href="team-player-1.html?slide=4">Player Stream</a>
                        </li>
                      </ul>
                    </li>
                    <li >
                      <a href=" ">Match Pages</a>
                      <ul className="mobile-nav__sub">
                        <li >
                          <a href="matches-scores.html">Match Scores</a>
                        </li>
                        <li >
                          <a href="matches-upcoming.html">Upcoming Matches</a>
                        </li>
                        <li >
                          <a href="matches-standings.html">Standings</a>
                        </li>
                        <li >
                          <a href="matches-overview-1.html">Match Overview v1</a>
                        </li>
                        <li >
                          <a href="matches-overview-2.html">Match Overview v2</a>
                        </li>
                        <li >
                          <a href="matches-stats-1.html">Match Stats v1</a>
                        </li>
                        <li >
                          <a href="matches-stats-2.html">Match Stats v2</a>
                        </li>
                        <li >
                          <a href="matches-stats-3.html">Match Stats v3</a>
                        </li>
                        <li >
                          <a href="matches-lineups-1.html">Match Lineups v1</a>
                        </li>
                        <li >
                          <a href="matches-lineups-2.html">Match Lineups v2</a>
                        </li>
                        <li >
                          <a href="matches-lineups-3.html">Match Lineups v3</a>
                        </li>
                        <li >
                          <a href="matches-replay.html">Match Replay</a>
                        </li>
                      </ul>
                    </li>
                    <li >
                      <a href=" ">Shop Pages</a>
                      <ul className="mobile-nav__sub">
                        <li >
                          <a href="shop.html">Shop Page v1</a>
                        </li>
                        <li >
                          <a href="shop-2.html">Shop Page v2</a>
                        </li>
                        <li >
                          <a href="shop-product.html">Product Page</a>
                        </li>
                        <li >
                          <a href="shop-checkout.html">Checkout Page</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                {/* Mobile Navigation / End */}
              </div>
            </li>
            <li className="mobile-bar-item">
              <a
                className="mobile-bar-item__header collapsed"
                data-toggle="collapse"
                href="#mobile_collapse_2"
                role="button"
                aria-expanded="false"
                aria-controls="mobile_collapse_2">
                Social Links
                <span className="main-nav__toggle">&nbsp;</span>
              </a>
              <div id="mobile_collapse_2" className="collapse mobile-bar-item__body">
                <ul className="social-menu social-menu--mobile-bar">
                  <li>
                    <a href="https://www.facebook.com/danfisher.dev/">
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/danfisher_dev">
                      <span>Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitch.tv">
                      <span>Twitch</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://discordapp.com">
                      <span>Discord</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mobile-bar-item mobile-bar-item--info">
              <a
                className="mobile-bar-item__header collapsed"
                data-toggle="collapse"
                href="#mobile_collapse_3"
                role="button"
                aria-expanded="false"
                aria-controls="mobile_collapse_3">
                Get in Touch!
                <span className="main-nav__toggle">&nbsp;</span>
              </a>
              <div id="mobile_collapse_3" className="collapse mobile-bar-item__body">
                <div className="mobile-bar-item__inner">
                  <ul className="list-unstyled">
                    <li className="info-box">
                      <div className="info-box__label">Max Parker - Recruiter</div>
                      <div className="info-box__content">
                        <a href="mailto:#">
                          mp-recruit<span className="color-primary">@</span>
                          necromancers.com
                        </a>
                      </div>
                    </li>
                    <li className="info-box">
                      <div className="info-box__label">Be our partner!</div>
                      <div className="info-box__content">
                        <a href="mailto:#">
                          partners<span className="color-primary">@</span>
                          necromancers.com
                        </a>
                      </div>
                    </li>
                    <li className="info-box">
                      <div className="info-box__label">General inquiries</div>
                      <div className="info-box__content">
                        <a href="mailto:#">
                          inquiries<span className="color-primary">@</span>
                          necromancers.com
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="mobile-bar-item mobile-bar-item--partners">
              <a
                id="mobile_collapse_4h"
                className="mobile-bar-item__header collapsed"
                data-toggle="collapse"
                href="#mobile_collapse_4"
                role="button"
                aria-expanded="false"
                aria-controls="mobile_collapse_4">
                Our Partners
                <span className="main-nav__toggle">&nbsp;</span>
              </a>
              <div id="mobile_collapse_4" className="collapse mobile-bar-item__body">
                <div className="mobile-bar-item__inner">
                  <ul className="widget-partners-mobile-carousel">
                    <li>
                      <img src="/assets/img/samples/partner-carousel-img-01.png" />
                    </li>
                    <li>
                      <img src="/assets/img/samples/partner-carousel-img-02.png" />
                    </li>
                    <li>
                      <img src="/assets/img/samples/partner-carousel-img-03.png" />
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="mobile-bar-item">
              <a className="mobile-bar-item__header" href="shop-account-settings.html">
                Account Settings
              </a>
            </li>
            <li className="mobile-bar-item">
              <a className="mobile-bar-item__header" href="login-register.html">
                Logout
              </a>
            </li>
          </ul>
          <div className="menu-panel__top-bar"></div>
          <div className="menu-panel__content d-none d-md-flex">
            <div className="menu-panel__navigation">
              <div id="dl-menu" className="dl-menuwrapper">
                {/* <button className="dl-trigger">Open Menu</button> */}
                <ul className="dl-menu dl-menuopen">
                  <li >
                    <a href="home.html">Home</a>
                  </li>
                  <li className="active">
                    <a href="team-selection-2.html">Teams</a>
                    <ul className="dl-submenu">
                      <li className="active">
                        <a href=" ">Team Selections</a>
                        <ul className="dl-submenu">
                          <li >
                            <a href="team-selection-1.html">Team Selection v1</a>
                          </li>
                          <li >
                            <a href="team-selection-2.html">Team Selection v2</a>
                          </li>
                          <li >
                            <a href="team-selection-3.html">Team Selection v3</a>
                          </li>
                          <li className="active">
                            <a href="team-selection-4.html">Team Selection v4</a>
                          </li>
                        </ul>
                      </li>
                      <li >
                        <a href="team-overview.html">Team Overview</a>
                        <ul className="dl-submenu">
                          <li >
                            <a href="team-overview.html">Team Overview v1</a>
                          </li>
                          <li >
                            <a href="team-overview-2.html">Team Overview v2</a>
                          </li>
                        </ul>
                      </li>
                      <li >
                        <a href="team-player-1.html">Player Page</a>
                      </li>
                      <li >
                        <a href="management-and-staff.html">Staff Page</a>
                      </li>
                      <li >
                        <a href="staff-member.html">
                          Staff Member<span className="badge badge-danger">New</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li >
                    <a href=" ">Matches</a>
                    <ul className="dl-submenu">
                      <li >
                        <a href="matches-scores.html">Match Scores</a>
                      </li>
                      <li >
                        <a href="matches-upcoming.html">Upcoming Matches</a>
                      </li>
                      <li >
                        <a href="matches-standings.html">Standings</a>
                      </li>
                      <li >
                        <a href=" ">Match Stats</a>
                        <ul className="dl-submenu">
                          <li >
                            <a href="matches-stats-1.html">Match Stats v1</a>
                          </li>
                          <li >
                            <a href="matches-stats-2.html">Match Stats v2</a>
                          </li>
                          <li >
                            <a href="matches-stats-3.html">Match Stats v3</a>
                          </li>
                        </ul>
                      </li>
                      <li >
                        <a href=" ">Match Lineups</a>
                        <ul className="dl-submenu">
                          <li >
                            <a href="matches-lineups-1.html">Match Lineups v1</a>
                          </li>
                          <li >
                            <a href="matches-lineups-2.html">Match Lineups v2</a>
                          </li>
                          <li >
                            <a href="matches-lineups-3.html">Match Lineups v3</a>
                          </li>
                        </ul>
                      </li>
                      <li >
                        <a href=" ">Match Overviews</a>
                        <ul className="dl-submenu">
                          <li >
                            <a href="matches-overview-1.html">Match Overview v1</a>
                          </li>
                          <li >
                            <a href="matches-overview-2.html">Match Overview v2</a>
                          </li>
                        </ul>
                      </li>
                      <li >
                        <a href="matches-replay.html">Match Replay</a>
                      </li>
                    </ul>
                  </li>
                  <li >
                    <a href="blog-1.html">News</a>
                  </li>
                  <li>
                    <a href=" ">Features</a>
                    <ul className="dl-submenu dl-megamenu">
                      <div className="row">
                        <ul className="col-md-6 col-lg-4">
                          <li >
                            <a href="index.html">Landing Image</a>
                          </li>
                          <li >
                            <a href="index-2.html">Landing Video</a>
                          </li>
                          <li >
                            <a href="home.html">Home page</a>
                          </li>
                          <li >
                            <a href="blog-1.html">News v1</a>
                          </li>
                          <li >
                            <a href="blog-2.html">News v2</a>
                          </li>
                          <li >
                            <a href="blog-3.html">News v3</a>
                          </li>
                          <li >
                            <a href="blog-4.html">News v4</a>
                          </li>
                          <li >
                            <a href="blog-classic.html">News Classic</a>
                          </li>
                          <li >
                            <a href="blog-post.html">Post Page</a>
                          </li>
                          <li >
                            <a href="shop-account-settings.html">Account Settings</a>
                          </li>
                        </ul>
                        <ul className="col-md-6 col-lg-4">
                          <li >
                            <a href="shop-account-orders.html">Account Orders</a>
                          </li>
                          <li >
                            <a href="login-register.html">Login &amp; Register</a>
                          </li>
                          <li >
                            <a href="features-about-us.html">About Us</a>
                          </li>
                          <li >
                            <a href="features-faqs.html">FAQs</a>
                          </li>
                          <li >
                            <a href="features-contact-us.html">Contact Us</a>
                          </li>
                          <li >
                            <a href="management-and-staff.html">Management &amp; Staff</a>
                          </li>
                          <li >
                            <a href="staff-member.html">
                              Staff Member
                              <span className="badge badge-danger">New</span>
                            </a>
                          </li>
                          <li >
                            <a href="streams-archive.html">Streams Page</a>
                          </li>
                          <li >
                            <a href="partners.html">Our Partners</a>
                          </li>
                        </ul>
                        <ul className="col-md-12 col-lg-4">
                          <li >
                            <a href="features-shortcodes.html">Shortcodes</a>
                          </li>
                          <li >
                            <a href="features-typography.html">Typography</a>
                          </li>
                          <li >
                            <a href="features-icons.html">Icons</a>
                          </li>
                          <li >
                            <a href="features-404.html">
                              404 Error <span className="badge badge-danger">New</span>
                            </a>
                          </li>
                          <li >
                            <a href="features-bg-1.html">Backgrounds</a>
                          </li>
                        </ul>
                      </div>
                    </ul>
                  </li>
                  <li >
                    <a href="shop.html">Shop</a>
                    <ul className="dl-submenu">
                      <li >
                        <a href="shop.html">Shop Page v1</a>
                      </li>
                      <li >
                        <a href="shop-2.html">Shop Page v2</a>
                      </li>
                      <li >
                        <a href="shop-product.html">Product Page</a>
                      </li>
                      <li >
                        <a href="shop-checkout.html">Checkout Page</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menu-panel__widget-area">
              <div className="row">
                <div className="col-md-12 col-lg-6 col-xl-5">
                  {/* Widget: Text */}
                  <section className="widget widget-text">
                    <h5 className="widget__title">Join our team</h5>
                    <div className="widget__content">
                      <p>
                        We’re always looking for new talent to join our teams. If you
                        wanna join us, just send us and email and we’ll get back to you!
                      </p>
                      <div className="info-box">
                        <div className="info-box__label">Max Parker - Recruiter</div>
                        <div className="info-box__content">
                          <a href="mailto:#">
                            mp-recruit<span className="color-primary">@</span>
                            necromancers.com
                          </a>
                        </div>
                      </div>
                      <div className="info-box">
                        <div className="info-box__label">Be our partner!</div>
                        <div className="info-box__content">
                          <a href="mailto:#">
                            partners<span className="color-primary">@</span>
                            necromancers.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-5 offset-xl-2 mt-5 mt-lg-0">
                  <section className="widget widget-contact-info">
                    <h5 className="widget__title">Contact Info</h5>
                    <div className="widget__content">
                      <p>
                        If you have any questions, just send us and email and don’t forget
                        to follow and like all our social accounts to keep updated!
                      </p>
                      <div className="info-box">
                        <div className="info-box__label">General inquiries</div>
                        <div className="info-box__content">
                          <a href="mailto:#">
                            inquiries<span className="color-primary">@</span>
                            necromancers.com
                          </a>
                        </div>
                      </div>
                      <ul className="social-menu social-menu--default">
                        <li>
                          <a href="https://facebook.com/EVOLVEcoinOfficial" />
                        </li>
                        <li>
                          <a href="https://twitter.com/EVOLVE_coin" />
                        </li>
                        <li>
                          <a href="https://linkedin.com/in/evolvecoin" />
                        </li>
                        <li>
                          <a href="https://discord.gg/QktpKRtE" />
                        </li>
                        <li>
                          <a href="https://www.instagram.com/evolvecoin/" />
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { GamesLayout }
