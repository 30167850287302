// import { USDCContract } from "../0x61/usdc"
// import { BUSDContract } from "../0x61/busd"
// import { DAIContract } from "../0x61/dai"
import { USDTContract } from "./usdt"

export const stableCoins = [
  { symbol: 'USDT', contract: USDTContract, swapIndex: 0,  decimals: 6 },
  // { symbol: 'USDC', contract: USDCContract, swapIndex: 1 },
  // { symbol: 'BUSD', contract: BUSDContract, swapIndex: 2 },
  // { symbol: 'DAI', contract: DAIContract, swapIndex: 3 },
]

export const stableCoinsOnEOG_Competition = [
  {
    tokenName: 'USDT',
    index: 0,
  },
  // {
  //   tokenName: 'USDC',
  //   index: 1,
  // },
  // {
  //   tokenName: 'BUSD',
  //   index: 2,
  // },
  // {
  //   tokenName: 'DAI',
  //   index: 3,
  // }
]
