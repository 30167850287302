import * as actions from './index'

export const setApprovedTokens = (approvedTokens) => {
  return {
    type: actions.SET_APPROVED_TOKENS,
    payload: {
      approvedTokens: approvedTokens
    }
  }
}

export const setIsTokenApproved = (bool) => {
  return {
    type: actions.SET_IS_TOKEN_APPROVED,
    payload: {
      tokenApproval: bool
    }
  }
}