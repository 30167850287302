import { useState } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import Joi from 'joi';
import { customToast } from "utils/helper";

const ChangePasswordComponent = (props) => {
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const { fetchedUserParseObject } = props

  const handleChangePassword = async () => {
    const schema = Joi.object({
      password: Joi.required(),
      confirmPassword: Joi.any().valid(Joi.ref('password')).required().error(() => {
        return new Error('Passwords do not match !')
      })
    });
    try {
      const value = await schema.validateAsync({ password: password, confirmPassword: repeatPassword });
      if (!value.error) {
        try {
          if (fetchedUserParseObject.password !== password && password) {
            fetchedUserParseObject.set('password', password)
            await fetchedUserParseObject.save()
            customToast('Password updated successfully', 'success')
            setUser({ ...user, password })
          }
          else if (user.password === password) {
            customToast('Password is already updated', 'warning')
          }
          else if (!password) {
            customToast('Password is required', 'danger')
          }
          else {
            customToast('Password is already saved', 'warning')
          }
        }
        catch (error) {
          customToast(error.message, 'danger')
      console.log(error)

        }
      }
    }
    catch (err) {
      setPassword(false)
      setRepeatPassword(false)
      console.log(err)
      customToast(err.message, 'danger')
    }
  }

  return (
    <section className="account-order p-5 bg-black  justify-content-start flex-column profile-box-h">
      <h4 className=" text-white border-bottom mb-5">Password</h4>
      <div>
        <p className=" text-white my-1">Enter New Password</p>
        <div className="row d-flex justify-content-between align-items-center  pb-4">
          <div className="col-md-5  ">
            <input type="password"
              className={`form-control bg-white  p-2 ${password === false ? 'has-error' : ''}`}
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)} />
          </div>
        </div>
        <p className=" text-white my-1">Repeat New Password</p>
        <div className="row d-flex justify-content-between align-items-center  pb-4">
          <div className="col-md-5 ">
            <input
              type="password"
              className={`form-control bg-white  p-2 ${repeatPassword === false ? 'has-error' : ''}`}
              // placeholder='********'
              onChange={(e) => setRepeatPassword(e.target.value)} />
          </div>
          <div className="col-3 text-right">
            <button
              className='btn btn-primary btn-sm mt-2 mt-sm-0'
              onClick={handleChangePassword}>
              Save
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  fetchedUserParseObject: state.user.userParseObject
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})

const ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordComponent);
export { ChangePassword }
