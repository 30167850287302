import React, { useEffect, useState } from 'react'
import { Preloader, SvgColorFilter } from "components";
import { customCursor } from "utils/helper";
import { GamesLayout } from "layouts";
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { Helmet } from 'react-helmet'
import { Outlet, Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'

function ProfileComponent(props) {
  const {
    userGamingEnabled,
    gamingEnabled,
    verifiedAt,
    isEmailVerified,
    IdentityVerification
  } = props

  useEffect(() => {
    initProfile()
    // eslint-disable-next-line 
  }, [])


  const initProfile = async () => {
    customCursor()
    await props.fetchCurrentUser()
  }

  const handleStatusLight = (condition) => <div className={`p-2 rounded-circle mx-2 ${condition ? 'bg-success' : 'bg-danger'}`}></div>


  return (
    <GamesLayout>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className="site-wrapper site-layout--default  ">
        <main className="site-content account-page container-xxl px-0 px-sm-5 bg-game-dark" id="wrapper">
          <div className="account-navigation bg-game-dark">
            <div className="account-navigation__header ">
              <h2 className=' text-white'>
                Profile
              </h2>
            </div>
            <span className='d-block d-lg-none'>
              <BarMenu
                verifiedAt={verifiedAt}
                gamingEnabled={gamingEnabled}
                userGamingEnabled={userGamingEnabled}
              />
            </span>
            <ul className="account-navigation__menu mt-1 d-none d-lg-block">
              <ul className="account-navigation__menu mt-1 overflow-auto custom-scrollbar">
                <li className='d-flex justify-content-between align-items-center'><Link className="text-white" to="/profile/account-status" >Account Status</Link>{handleStatusLight(verifiedAt)}</li>
                <li className='d-flex justify-content-between align-items-center'><Link className="text-white" to="/profile/verify-identity" > Identity</Link>{handleStatusLight(IdentityVerification)}</li>
                <li className='d-flex justify-content-between align-items-center'><Link className="text-white" to="/profile/change-email" > Email</Link>{handleStatusLight(isEmailVerified)}</li>
                {
                  verifiedAt &&
                  <li><Link className="text-white" to="/profile/referral" >Referral</Link></li>
                }
                <li><Link className="text-white" to="/profile/change-username" >Change Username</Link></li>
                <li><Link className="text-white" to="/profile/change-password" >Change Password</Link></li>
                {/* <li><Link className="text-white" to="/airdropped-tokens" >Airdropped Tokens </Link></li> */}
              </ul>
            </ul>
          </div>
          <div className="account-content align-items-center d-flex justify-content-center">
            <ul className="account-orders d-flex flex-column h-100 justify-content-center w-100 ml-4">
              <Outlet />
            </ul>
          </div>
        </main>
        <div className="site-overlay" />
      </div>
      <SvgColorFilter />
      <Preloader />
    </GamesLayout>

  );
}

const mapStateToProps = (state) => ({
  fetchedUserParseObject: state.user.userParseObject,
  userGamingEnabled: state.user.userGamingEnabled,
  gamingEnabled: state.config?.['gaming-enabled'],
  verifiedAt: state.user.userParseObject?.get('verifiedAt'),
  isEmailVerified: state.user.userParseObject?.get('emailVerified'),
  IdentityVerification: state.user.userParseObject?.get('KYCAt'),

})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})


const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
export { Profile }


function BarMenu(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const {
    verifiedAt
  } = props

  const handleStateChange = state => {
    setMenuOpen(state.isOpen)
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  const closeMenu = () => {
    setMenuOpen(false)
  }

  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: '36px',
      top: '36px'
    },
    bmBurgerBars: {
      background: '#ccc'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#373a47',
      fontSize: '1.15em'
    },
    bmItemList: {
      color: '#b8b7ad',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    },
    overflow: {
      overflow: 'scroll'
    }
  }
  return (
    <Menu styles={styles} right isOpen={menuOpen} onStateChange={state => handleStateChange(state)}>
      <ul className="bar-menu" style={{ listStyle: 'none' }}>
        <li><Link className="text-white" to="/profile/account-status" onClick={() => closeMenu()} >Account Status</Link></li>
        <li><Link className="text-white" to="/profile/verify-identity" onClick={() => closeMenu()}> Identity</Link></li>
        <li><Link className="text-white" to="/profile/change-email" onClick={() => closeMenu()}> Email</Link></li>
        {
          verifiedAt &&
          <li><Link className="text-white" to="/profile/referral" onClick={() => closeMenu()}>Referral</Link></li>
        }
        <li><Link className="text-white" to="/profile/change-username" onClick={() => closeMenu()}>Change Username</Link></li>
        <li><Link className="text-white" to="/profile/change-password" onClick={() => closeMenu()}>Change Password</Link></li>
        {/* <li><Link className="text-white" to="/airdropped-tokens" onClick={() => closeMenu()}>Locked Stable Coin</Link></li> */}
      </ul>
    </Menu>
  );
}