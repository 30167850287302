import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { chain } from 'smart-contract';
import {
  metaMaskWallet,
  trustWallet,
  coinbaseWallet,
} from '@rainbow-me/rainbowkit/wallets';
const { provider, chains } = configureChains(
  [chain],
  [
    jsonRpcProvider({
      rpc: chain => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ chains }),
    ],
  },
  {
    groupName: 'Alternative',
    wallets: [
      trustWallet({ chains }),
      coinbaseWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

export const BlockchainProvider = ({ children }) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} showRecentTransactions={true} coolMode>
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  )
}