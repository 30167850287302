/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { AccountUserAvatar } from "components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";




function FooterMenuComponent(props) {
  const {
    gamingEnabled,
    userGamingEnabled,
  } = props;

  // const [userCountryIsRestrictedToICO, setUserCountryIsRestrictedToICO] = useState(false)

  // useEffect(() => {

  //   const isRestricted = isUserCountryRestricted(userParseObject, config)
  //   setUserCountryIsRestrictedToICO(isRestricted)

  // }, [userParseObject, config])

  // const handleUserLevel = (userlevel) => {
  //   if (userlevel || userlevel === 0) return ` Level ${userlevel}`
  // }

  return (
    <header id="header" className="site-header site-header--bottom">
      <div className="header-logo header-logo--img logo-footer">
        <Link to="/">
          <img
            src="/assets/img/logo.png"
            srcSet="/assets/img/logo@2x.png 2x"
            alt="Home"
          />
        </Link>
      </div>
      <nav className="main-nav">
        <ul className="main-nav__list d-none d-sm-block">
          <li>
            <Link to="/">Home</Link>
          </li>
          {/* <li>
            <Link to=" ">EvolveCoin</Link>
            <ul className="main-nav__sub">
              <li>
                <a href="https://evolvecoin.io/white-paper">
                  WHITEPAPER
                </a>
              </li>
              <li>
                <a href={tokenURL}>
                  CONTRACT ADDRESS
                </a>
              </li>
            </ul>
          </li> */}
          {/* {userIcoEnabled && <li >
            <Link to="/ico">ICO</Link>
          </li>}
          {userIcoEnabled === null && icoEnabled && !userCountryIsRestrictedToICO && <li>
            <Link to="/ico">ICO</Link>
          </li>} */}
          {userGamingEnabled && <li >
            <Link to="/join">Games</Link>
          </li>}
          {userGamingEnabled === null && gamingEnabled && <li >
            <Link to="/join">Games</Link>
          </li>}
          <li>
            <a href="https://github.com/EvolutionOfGaming/EOG-Beta-Bug-Reporting/issues" target={"_blank"} >Report a bug</a>
          </li>
          <li className="p-0">
            <div className="header-social-toggle d-none d-md-block mr-5">
              <svg role="img" className="df-icon df-icon--thumb-up">
                <use xlinkHref="/assets/img/necromancers.svg#thumb-up" />
              </svg>
              <span className="header-social-toggle__plus">&nbsp;</span>
              <ul className="social-menu social-menu--header">
                <li>
                  <a href="https://www.instagram.com/theevolutionofgaming/">
                    <span className="link-subtitle">Instagram</span>@theevolutionofgaming
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/EOGcompetitions/">
                    <span className="link-subtitle">Twitter</span>@EOGcompetitions
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/theevolutionofgaming/">
                    <span className="link-subtitle">LinkedIn</span>@theevolutionofgaming
                  </a>
                </li>
                <li>
                  <a href="https://discord.com/invite/K8hFuGY63y">
                    <span className="link-subtitle">Discord</span>@EVOLVE_coin
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/theevolutionofgaming/"
                    target="_blank"
                  >
                    <span className="link-subtitle">Facebook</span>
                    @theevolutionofgaming
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <ul className="main-nav__sub"></ul>
        </ul>
      </nav>
      <div className="header-actions">
        {/* <div className="d-flex align-items-center ml-2">
          <div className="header-account__name font-weight-bold">
            <Link to="/profile/account-status" className="text-white text-center link-profile">
              {handleUserLevel(props.level)}
            </Link>
          </div>


        </div> */}

        <AccountUserAvatar />
        <div className="header-account hide">
          <div className="header-account__icon">
            <a href="login-register.html">
              <svg role="img" className="df-icon df-icon--logout">
                <use xlinkHref="/assets/img/necromancers.svg#logout" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = (state) => ({
  gamingEnabled: state.config?.['gaming-enabled'],
  icoEnabled: state.config?.['ico-enabled'],
  userIcoEnabled: state.user?.userIcoEnabled,
  userGamingEnabled: state.user?.userGamingEnabled,
  userParseObject: state.user.userParseObject,
  config: state.config,
  level: state.user?.userParseObject?.get('level'),
});

const FooterMenu = connect(mapStateToProps)(FooterMenuComponent);
export { FooterMenu };
