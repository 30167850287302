import React from 'react'

function Button(props) {
  return (
    <button disabled={props.loading} {...props} id='login-button' className={`btn btn-primary btn--landing ${props.className}`} style={{ zIndex: 100 }} >
      <span>{props.children} </span>
    </button >
  )
}

export { Button }
