import Parse from "parse/lib/browser/Parse"
import { storedGame } from "utils/storedGame";

const GamesClass = Parse.Object.extend('Game');

export const getActiveGames = async () => {
    const gamesQuery = new Parse.Query(GamesClass);
    const games = await gamesQuery.equalTo('active', true).find()

    const activeGames = games.map(item => ({
      title: item.get('title'),
      overwolfId: item.get('overwolfId'),
    }))

    const generatedGamesList = activeGames.map(activeGame => {
      return storedGame.find(game => {
        return Number(activeGame.overwolfId) === game.overwolfId
      })
    })
    
    return generatedGamesList;
}
