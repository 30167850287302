import React from 'react'

// Components
import { WaitingRoom } from './WaitingRoom'
import { Initialization } from './Initialization'
import { Progress } from './Progress'
import { Result } from './Result'

function CompetitionStages({ stage, competitorStatus, competition, members }) {
  if (stage === 0) {
    return (
      <WaitingRoom
        user={competitorStatus.get('user')}
        game={competitorStatus.get('preset').get('game')}
        preset={competitorStatus.get('preset')}
      />
    )
  }

  if (competition && members) {
    if (stage === 1) {
      return (
        <Initialization
          competitorStatus={competitorStatus}
          competition={competition}
          side1Members={members.filter((m) => m.get('side') === 1)}
          side2Members={members.filter((m) => m.get('side') === 2)}
        />
      )
    }
    if (stage === 2) {
      return (
        <Progress
          side1Members={members.filter((m) => m.get('side') === 1)}
          side2Members={members.filter((m) => m.get('side') === 2)}
          preset={competitorStatus.get('preset')}
          competitorStatus={competitorStatus}
          competition={competition}
        />
      )
    }
    if (stage === 3) {
      return (
        <Result
          competitorStatus={competitorStatus}
          competition={competition}
          side1Members={members.filter((m) => m.get('side') === 1)}
          side2Members={members.filter((m) => m.get('side') === 2)}
        //   presetFee={presetFee}
        />
      )
    }
  }

  return null
}

export default CompetitionStages
