/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Parse from 'parse';
import { Button } from 'components/button/Button'
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { Link } from 'react-router-dom';
import { LoginLayout } from 'layouts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { customToast } from 'utils/helper';
import { UsernameTextField } from 'components';
import { Preloader } from 'components'
import { getConfig } from 'redux/actions/configActions'
import { Modal } from 'react-bootstrap'
import sanitizeHtml from 'sanitize-html';


function SignupComponent(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [usernameAvailable, setUsernameAvailable] = useState(null);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const referralId = searchParams.get('r')

  const { termsOfService, getConfig, disclaimerMessage } = props;

  useEffect(() => {
    getConfig()
    setTimeout(() => {
      setOpenModal(true)
    }, 2000)
    //eslint-disable-next-line
  }, [])


  const handleRegister = async () => {
    if (!usernameAvailable) {
      customToast('Username is not available', 'danger')
      return
    } else if (!email) {
      customToast('Please enter your email', 'danger')
      return
    } else if (!password) {
      customToast('Please enter your password', 'danger')
      return
    } else if (!isTermsAccepted) {
      customToast('Please accept the terms and conditions', 'danger')
      return
    }

    const user = new Parse.User();
    user.set('username', userName);
    user.set('password', password);
    user.set('email', email);
    user.set('referrer', referralId);
    try {
      setLoading(true);
      await user.signUp()
      customToast('You have successfully registered. ', 'success')
      setTimeout(async () => {
        await props.fetchCurrentUser()
        navigate('/')
      }, 1500);
    }
    catch (error) {
      setLoading(false);
      customToast(error.message, 'danger')
    }
  };

  return (
    <LoginLayout>
      <main className="site-content text-center" id="wrapper"
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleRegister()
          }
        }}
      >
        <div className="site-content__center col-sm-4">
          <Form.Group className={`mx-5 text-left ${usernameAvailable ? 'mb-3' : ''}`}>
            <Form.Label className="text-white ">Username</Form.Label>
            <UsernameTextField
              username={(username) => setUserName(username)}
              usernameAvailability={(availability) => setUsernameAvailable(availability)}
              className="p-2 bg-white form-control"
              containerClassName="text-left"
              placeholder="Enter Username"
            />
            {
              usernameAvailable === false && <span className="text-danger font-weight-bold">that username is taken,try another</span>
            }
          </Form.Group>
          <Form.Group className="mb-3 mx-5 text-left" >
            <Form.Label className="text-white ">Email</Form.Label>
            <div className={`user-name-text-field-container text-left`}>
              <input
                type="email"
                className='p-2 bg-white form-control'
                autoComplete="new-email"
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)} />
            </div>
          </Form.Group>
          <Form.Group className=" mx-5 text-left" >
            <Form.Label className="text-white ">Password</Form.Label>
            <div className={`user-name-text-field-container text-left mb-3`}>
              <input
                type="password"
                className='p-2 bg-white form-control'
                autoComplete="new-password"
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className='d-flex align-items-center justify-content-start'>
              <input type="checkbox" id='termsCheckBox' className='px-3 cursor-pointer mt-0' onChange={(e => {
                setIsTermsAccepted(e.target.checked)
              })} />
              <label htmlFor='termsCheckBox' class="checkbox-bootstrap mb-0 ">
                <span className='text-white pl-2 pr-1'>
                  I agree with
                </span>
              </label>
              <div className='text-white font-weight-bold  cursor-pointer mt-0'>
                <TermsAndService content={termsOfService} />
              </div>
            </div>
          </Form.Group>
          <div>
            <div className='d-flex justify-content-center mt-3 mb-0'>

            </div>
            <div className='d-flex flex-column align-items-center mt-3' >
              <Button onClick={handleRegister} className='px-5 py-2 mt-0' style={{ minHeight: '45px', minWidth: '150px' }} >
                {
                  loading ?
                    <div className="spinner-border spinner-border-sm mx-4 my-1" role="status" style={{ width: '.9rem', height: '.9rem', fontWeight: 'bold' }}>
                    </div> :
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Sign Up</span>
                }
              </Button>
              <Link to="/login" className="text-white mt-3">
                <span className='text-white font-weight-bold cursor-pointer' >I have an account </span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Disclaimer openModal={openModal} disclaimerMessage={disclaimerMessage} />
      <Preloader />
    </LoginLayout>

  )
}




function Disclaimer({ openModal, disclaimerMessage }) {
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    setModalShow(openModal)
  }, [openModal])

  return (
    <DisclaimerModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      disclaimerMessage={disclaimerMessage}
    />
  )
}

function DisclaimerModal(props) {
  const { disclaimerMessage } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="bg-game-dark p-1"
      centered>
      <Modal.Header className={`btn text-right text-white  cursor-default `}>
        <button
          className=" text-center border-0 "
          style={{ backgroundColor: '#5e627e' }}
          onClick={props.onHide}>
          <span className="font-weight-bold">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center pt-0">
        <main className=" site-content text-center col-10 mb-3">
          <div className="site-content__center ">
            <h4 className="text-warning mt-0 pb-3 pt-0">Attention !</h4>
            <p className="text-white mt-5">
            </p>
            <div className='text-white' dangerouslySetInnerHTML={{ __html: sanitizeHtml(disclaimerMessage) }} />
            <Button onClick={props.onHide} > OKAY </Button>
          </div>
        </main>
      </Modal.Body>
    </Modal>
  )
}



function TermsAndService({ openModal, content }) {
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    setModalShow(openModal)
  }, [openModal])

  return (
    <>
      <span className="text-white link-blue" type='button' onClick={() => setModalShow(true)}>
        terms and conditions
      </span>
      <TermsAndServiceModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        content={content}
      />
    </>
  )
}

function TermsAndServiceModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="bg-game-dark p-1"
      centered>
      <Modal.Header className={`btn text-right text-white  cursor-default `}>
        <button
          className=" text-center border-0 "
          style={{ backgroundColor: '#5e627e' }}
          onClick={props.onHide}>
          <span className="font-weight-bold">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center pt-0">
        <main className=" site-content text-center col-10 mb-3">
          <div className="site-content__center ">
            <h4 className="text-warning mt-0 pb-3 pt-0">Terms and Conditions</h4>
            <p className="text-white  mt-5">
            </p>
            <div className='text-white' dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.content) }} />
            <Button onClick={props.onHide} > OKAY </Button>
          </div>
        </main>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  termsOfService: state.config['terms-of-service'],
  disclaimerMessage: state.config['disclaimer-message'],
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  getConfig: () => dispatch(getConfig()),
})

const Signup = connect(mapStateToProps, mapDispatchToProps)(SignupComponent)
export { Signup }


