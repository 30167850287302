import React from 'react'
import { GameCard } from 'components'

function GamesHostContent(props) {

  const { gamesCardsList } = props

  let color = ['team-2', 'team-5', 'team-1', 'team-3', 'team-4']

  const iterateOnColors = () => color.push(color.shift())


  return (
    <>
      <main className="site-content site-content--center page" id="wrapper">
        <div className="container container--large">
          <div className="page-heading page-heading--default text-center w-100">
            <div className="page-heading__subtitle h5">
              <span className="color-primary">please choose a title to Host</span>
            </div>
            <h1 className="page-heading__title h2">Host A Competition</h1>
          </div>
          <div className="row mt-sm-auto mb-sm-auto">
            {
              gamesCardsList.map((game) => {
                iterateOnColors()
                return <GameCard {...game} frameColor={color[0]}  selectMode="HOST"/>
              })
            }
          </div>
        </div>
      </main>
    </>
  )
}

export { GamesHostContent }
