/* eslint-disable eqeqeq */
import { Hash } from 'components'
import { Messaging } from 'react-cssfx-loading/lib'
import { connect } from 'react-redux'
import { generateAvatar, CompetitionStatus, getCompetitionStatusText, tokenIndexFinder } from 'utils/helper'
//import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components';

function ResultComponent({ competition, side1Members, side2Members, competitorStatus }) {
  const side1Score = competition.get('sideScores')?.find((side) => 1 == side.side).score // changed from eqeqeq to eqeq to safety
  const side2Score = competition.get('sideScores')?.find((side) => 2 == side.side).score
  const winnerSide = side1Score > side2Score ? 1 : side1Score < side2Score ? 2 : null
  const winnerSignClass =
    winnerSide === 1 ? 'match-result--winner-left' : winnerSide === 2 ? 'match-result--winner-right ' : ''
  const winnerAmountInUSD = competition.get('preset').get('feeInUSD') * 2
  const rakeAmount = competition.get('preset').get("rakeInUsd")
  const winnerAmount = winnerAmountInUSD - (rakeAmount * 2)
  const fixedWinnerAmount = winnerAmount.toFixed(2)
  const selectedStableCoinIndex = competitorStatus.get("selectedStableCoin")
  const currentStableCoin = tokenIndexFinder(selectedStableCoinIndex)
  const navigate = useNavigate()
  //const [currency, setCurrency] = useState();

  // useEffect(() => {
  //   const stableCoin = competitorStatus.get('selectedStableCoin');
  //   if(stableCoin || stableCoin === 0) {
  //     tokenIndexFinder(stableCoin)
  //     setCurrency(tokenIndexFinder(stableCoin))
  //   }
  //   console.log(currency)
  //   //eslint-disable-next-line
  // }, [])

  if (competition.get('status') === CompetitionStatus.SETTLED)
    return (
      <div className="container container--large">
        <div className="match-overview mt-sm-auto mb-sm-auto">
          <div className="match-overview__body">
            <figure className="match-team" role="group">
              <figure className="match-team-logo match-team-logo--team-1" role="group">
                <img
                  src="/assets/img/samples/logo-team-a.png"
                  srcSet="/assets/img/samples/logo-team-a@2x.png"
                  alt="Team A Logo"
                  width={180}
                  height={180}
                />
              </figure>
              <figcaption>
                <div className="match-team__name">Team A</div>
                <div className="match-team__country ">{winnerSide === 1 ? 'Winner' : ''}</div>
              </figcaption>
            </figure>
            <div className="col-md-5">
              <div className="match-overview__result">
                <div className={`match-result ${winnerSignClass}`}>
                  <span className="match-result__score">
                    {side1Score} : {side2Score}
                  </span>
                  <span className="match-result__label">Final score</span>
                </div>
              </div>
            </div>
            <figure className="match-team" role="group">
              <figure className="match-team-logo match-team-logo--team-2" role="group">
                <img
                  src="/assets/img/samples/logo-team-b.png"
                  srcSet="/assets/img/samples/logo-team-b@2x.png"
                  alt="Team B Logo"
                  width={180}
                  height={180}
                />
              </figure>
              <figcaption>
                <div className="match-team__name">Team B</div>
                <div className="match-team__country ">{winnerSide === 2 ? 'Winner' : ''}</div>
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="text-center">
          {competition.get('finishTx') && competition.get('finishTx') === '0' && (
            <>
              <h6 className="text-success">TRANSACTION SENT...</h6>
            </>
          )}
          {competition.get('finishTx') && competition.get('finishTx') !== '0' && (
            <>
              {winnerSide && winnerSide === competitorStatus.get('side') && (
                <p className="text-success font-18 font-weight-bold mt-5">
                  {`${competition.get("blockchainID") !== 0 ? "As a member of the winning team, you've earned " : `Winning Team : ${winnerSide === 1 ? "TEAM A" : "TEAM B"}`}`}
                  <strong className="font-20  text-white">
                    {winnerAmountInUSD ? `${fixedWinnerAmount} ${currentStableCoin.tokenName}` : ''}
                  </strong>
                </p>
              )}
              {winnerSide && winnerSide !== competitorStatus.get('side') && (
                <p className="text-success font-18 font-weight-bold mt-5">
                  {`${competition.get("blockchainID") !== 0 ? "Winning Team Earned" : `Winning Team : ${winnerSide === 1 ? "TEAM A" : "TEAM B"}`}`}
                  <strong className="font-20  text-white">
                    {winnerAmountInUSD ? `${fixedWinnerAmount} ${currentStableCoin.tokenName}` : ''}
                  </strong>
                </p>
              )}
              {!winnerSide && (
                <p className="text-success font-18 font-weight-bold mt-5">
                  The result is a TIE {`${competition.get("blockchainID") !== 0 ? ", you got your money back, minus the Admin Fee." : ""}`}
                </p>
              )}
              {competition.get("blockchainID") !== 0 && (
                <p className="text-success font-weight-bold">
                  TRANSACTION: &nbsp;
                  <span className="text-white font-weight-bold">
                    [
                    <u>
                      <Hash hash={competition.get('finishTx')} shorten={5} clickable={true} />
                    </u>
                    ]
                  </span>
                </p>
              )}
            </>
          )}
        </div>
        <div className="table-responsive my-sm-2">
          <div className="matches-result__label">Team A</div>
          <table className="table matches-table standings-table">
            <thead>
              <tr>
                <th>Competitor</th>
                <th>Kills</th>
                <th>
                  {side1Members[0].get('preset').get('game').get('overwolfId') === '10906'
                    ? 'Headshot Kills'
                    : 'Deaths'}
                </th>
                {/* <th>Assists</th> */}
                <th>Win Place</th>
                <th>Map Name</th>
              </tr>
            </thead>
            <tbody>
              {side1Members.map((member, index) => {
                return (
                  <tr key={index}>
                    <td className="standings-table__team d-flex align-items-center">
                      {member.get('user')?.avatar ? (
                        <img
                          src={member.get('user')?.avatar?.url()}
                          alt="Ice Panthers Logo"
                          className="mx-2"
                          style={{ width: '34px' }}
                        />
                      ) : (
                        <img
                          src={generateAvatar(member.get('user'))}
                          alt="Ice Panthers Logo"
                          className="mx-2"
                        />
                      )}
                      <div className="match-team__name">{member.get('user')?.username}</div>
                    </td>
                    <td className="standings-table__wins">
                      {member.get('preset').get('game').get('overwolfId') === '10906'
                        ? member.get('apiStats')?.kills ?? '-'
                        : member.get('apiStats')?.kills ?? '-'}
                    </td>
                    <td>
                      {member.get('preset').get('game').get('overwolfId') === '10906'
                        ? member.get('apiStats')?.headshotKills ?? '-'
                        : member.get('apiStats')?.deaths ?? '-'}
                    </td>
                    {/* <td>
                      {member.get('preset').get('game').get('overwolfId') === '10906'
                        ? member.get('apiStats')?.assists ?? '-'
                        : member.get('apiStats')?.assists ?? '-'}
                    </td> */}
                    <td>
                      {member.get('preset').get('game').get('overwolfId') === '10906'
                        ? member.get('apiStats')?.winPlace ?? '-'
                        : '-'}
                    </td>
                    <td>
                      {member.get('preset').get('game').get('overwolfId') === '10906'
                        ? member.get('apiStats')?.mapName ?? '-'
                        : '-'}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="table-responsive my-sm-2">
          <div className="matches-result__label">Team B</div>
          <table className="table matches-table standings-table">
            <thead>
              <tr>
                <th>Competitor</th>
                <th>Kills</th>
                <th>
                  {side2Members[0].get('preset').get('game').get('overwolfId') === '10906'
                    ? 'Headshot Kills'
                    : 'Deaths'}
                </th>
                {/* <th>Assists</th> */}
                <th>Win Place</th>
                <th>Map Name</th>
              </tr>
            </thead>
            <tbody>
              {side2Members.map((member, index) => (
                <tr key={index}>
                  <td className="standings-table__team d-flex align-items-center">
                    {member.get('user')?.avatar ? (
                      <img
                        src={member.get('user')?.avatar?.url()}
                        alt="Ice Panthers Logo"
                        className="mx-2"
                        style={{ width: '34px' }}
                      />
                    ) : (
                      <img
                        src={generateAvatar(member.get('user'))}
                        alt="Ice Panthers Logo"
                        className="mx-2"
                      />
                    )}
                    <div className="match-team__name">{member.get('user')?.username}</div>
                  </td>
                  <td className="standings-table__wins">
                    {member.get('preset').get('game').get('overwolfId') === '10906'
                      ? member.get('apiStats')?.kills ?? '-'
                      : member.get('apiStats')?.kills ?? '-'}
                  </td>
                  <td>
                    {member.get('preset').get('game').get('overwolfId') === '10906'
                      ? member.get('apiStats')?.headshotKills ?? '-'
                      : member.get('apiStats')?.deaths ?? '-'}
                  </td>
                  {/* <td>
                    {member.get('preset').get('game').get('overwolfId') === '10906'
                      ? member.get('apiStats')?.assists ?? '-'
                      : member.get('apiStats')?.assists ?? '-'}
                  </td> */}
                  <td>
                    {member.get('preset').get('game').get('overwolfId') === '10906'
                      ? member.get('apiStats')?.winPlace ?? '-'
                      : '-'}
                  </td>
                  <td>
                    {member.get('preset').get('game').get('overwolfId') === '10906'
                      ? member.get('apiStats')?.mapName ?? '-'
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  else if (
    competition.get('status') === CompetitionStatus.PENDING_READY ||
    competition.get('status') === CompetitionStatus.COMPLETED_TIMEDOUT
  )
    return (
      <div className="match-overview m-sm-auto bg-dark-fade p-5">
        <div className="d-flex justify-content-center flex-column p-4 pt-5">
          <h5 className="text-warning text-center">
            {competition.get('status') && competition.get("blockchainID") !== 0
              ? getCompetitionStatusText(competition.get('status'))
              : competition.get("blockchainID") === 0 ? getCompetitionStatusText("FREE_TRIAL") : 'This competition has been finished'}
          </h5>
        </div>

      </div>
    )
    else if (
      competition.get('status') === CompetitionStatus.X_FINISH_TX_FAILED ||
      competition.get('status') === CompetitionStatus.X_FINISH_TX_STUCK ||
      competition.get('status') === CompetitionStatus.X_START_TX_FAILED
    )
      return (
        <div className="match-overview m-sm-auto bg-dark-fade p-5">
          <div className="d-flex justify-content-center flex-column p-4 pt-5">
            <h5 className="text-warning text-center">
              {competition.get('status') && competition.get("blockchainID") !== 0
                ? getCompetitionStatusText(competition.get('status'))
                : competition.get("blockchainID") === 0 ? getCompetitionStatusText("FREE_TRIAL") : 'This competition has been finished'}
            </h5>
          </div>
          <div className='text-center'>
            <Button onClick={() => navigate("/join")} className="btn btn-primary btn-lg mt-4" >
              Join a new game
            </Button>
          </div>
        </div>
      )
  else if (competition.get('status') === CompetitionStatus.COMPLETED) {
    return (
      <div className="match-overview m-sm-auto bg-dark-fade p-5">
        <div className="d-flex justify-content-center flex-column align-items-center ">
          <h4 className="primary-green text-center">{competition.get("blockchainID") !== 0 ?
            getCompetitionStatusText(competition.get('status')) :
            getCompetitionStatusText("FREE_TRIAL")
          }</h4>
          <div className="py-4 mt-4 ">
            <Messaging color="#a3ff12" width="1.2rem" height="1.2rem" duration=".7s" />
          </div>
        </div>
      </div>
    )
  } else
    return (
      <div className="match-overview m-sm-auto bg-dark-fade p-5">
        <div className="d-flex justify-content-center flex-column align-items-center ">
          <h3 className="primary-green text-center">Compiling competition results</h3>
          <div className="py-4 mt-4">
            <Messaging color="#a3ff12" width="1.2rem" height="1.2rem" duration=".7s" />
          </div>
        </div>
      </div>
    )
}

const mapStateToProps = (state) => ({
  tokenPrice: state.config['token-price'],
})

const Result = connect(mapStateToProps)(ResultComponent)
export { Result }
