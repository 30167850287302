import React from 'react'

export function SvgColorFilter() {
  return (
    <>
      {/* Duotone SVG color filter */}
      <svg xmlns="http://www.w3.org/2000/svg" className="svg-filters">
        <filter id="duotone_base">
          <feColorMatrix
            type="matrix"
            result="grayscale"
            values="1 0 0 0 0
								1 0 0 0 0
								1 0 0 0 0
								0 0 0 1 0"
          />
          <feComponentTransfer
            colorInterpolationFilters="sRGB"
            result="duotone_base_filter"
          >
            <feFuncR
              type="table"
              tableValues="0.082352941176471 0.419607843137255"
            />
            <feFuncG
              type="table"
              tableValues="0.090196078431373 0.443137254901961"
            />
            <feFuncB type="table" tableValues="0.125490196078431 0.6" />
            <feFuncA type="table" tableValues="0 1" />
          </feComponentTransfer>
        </filter>
      </svg>
    </>
  )
}

