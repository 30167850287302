import React, { useState } from 'react'
import CompetitionDetailsBox from 'components/competition/CompetitionDetailsBox'
import Hypnosis from 'react-cssfx-loading/lib/Hypnosis'
import { Button } from 'components'
import Parse from 'parse/lib/browser/Parse'
import { useNavigate } from 'react-router-dom'

function WaitingRoom({ user, game, preset }) {
  const [cancelButtonLoading, setCanselButtonLoading] = useState(false)
  const navigate = useNavigate()

  const cancelCompetition = async () => {
    setCanselButtonLoading(true)
    const isCanceled = await Parse.Cloud.run('cancel-join-competition')
    if (isCanceled) navigate(`/join/game/${game.get('overwolfId')}`)
    else setCanselButtonLoading(false)
  }

  return (
    <div className="matches-tabs__content tab-content">
      <div className="match-stats-container tab-pane fade show active" id="tab-1" role="tabpanel">
        <div className="match-stats-widget match-stats-widget--general">
          <div className="match-stats-widget__header ">Competition Details</div>
          <CompetitionDetailsBox
            game={game}
            details={{
              feeInUSD: preset.get('feeInUSD'),
              players: preset.get('players'),
              setting: preset.get('setting'),
            }}
          />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mx-5 my-3 text-white">
          <Hypnosis color="#a3ff12" width="100px" height="100px" duration="2s" />
          {/* <Hypnosis onClick={() => alert("Clicked")} key="key" /> */}
          <span className="mt-4 font-weight-bold">Matchmaking in Progress...</span>
        </div>
        <div className="match-stats-widget match-stats-widget--leaders">
          <div className="match-stats-widget__header">Game Player</div>
          <ul className="match-stats-widget__item">
            <li>
              <figure className="match-player team-1" role="group">
                <figure className="match-player__avatar">
                  <img src="/assets/img/samples/match-player-01-90x70.png" alt="evolve" />
                </figure>
                <figcaption>
                  <span>{user.username}</span>
                </figcaption>
              </figure>
              <figure className="match-team-logo match-team-logo--team-1" role="group">
                <img
                  src="/assets/img/samples/logo-necromancers-30.png"
                  srcSet="/assets/img/samples/logo-necromancers-30@2x.png 2x"
                  alt="Necromancers Logo"
                />
              </figure>
            </li>
            <li className="d-flex justify-content-center">
              <Button
                className="btn btn-danger btn-sm"
                disabled={cancelButtonLoading}
                onClick={cancelCompetition}>
                Cancel competition
              </Button>
            </li>
          </ul>
        </div>
      </div>
      <div className="match-stats-container tab-pane fade" id="tab-2" role="tabpanel">
        <div className="match-stats-widget match-stats-widget--general">
          <div className="match-stats-widget__header">General Stats</div>
          <ul className="match-stats-widget__body">
            <li>
              <figure className="match-team match-team--winner" role="group">
                <figure className="match-team-logo match-team-logo--team-1" role="group">
                  <img
                    src="/assets/img/samples/logo-necromancers-30.png"
                    srcSet="/assets/img/samples/logo-necromancers-30@2x.png 2x"
                    alt="NCR Logo"
                  />
                </figure>
                <figcaption>
                  <div className="match-team__name">NCR</div>
                  <div className="match-team__result ">won</div>
                </figcaption>
              </figure>
              <div className="match-result">
                <span className="match-result__score">3 : 1</span>
              </div>
              <figure className="match-team" role="group">
                <figure className="match-team-logo match-team-logo--team-2" role="group">
                  <img
                    src="/assets/img/samples/logo-rhinos-30.png"
                    srcSet="/assets/img/samples/logo-rhinos-30@2x.png 2x"
                    alt="RRH Logo"
                  />
                </figure>
                <figcaption>
                  <div className="match-team__name">RRH</div>
                  <div className="match-team__result ">lost</div>
                </figcaption>
              </figure>
            </li>
            <li>
              <span>Map / Level</span>
              <span>El Plateado</span>
            </li>
            <li>
              <span>Mission</span>
              <span>Escort</span>
            </li>
            <li>
              <span>Duration</span>
              <span>34:57</span>
            </li>
            <li>
              <span>Status</span>
              <span>Final Score</span>
            </li>
          </ul>
        </div>
        <div className="match-stats-widget match-stats-widget--matchup">
          <div className="match-stats-widget__header">Teams Matchup</div>
          <ul className="match-stats-widget__body">
            <li>
              <div className="match-stats-progress">
                <div className="match-stats-progress__label">Total Kills</div>
                <div className="match-stats-progress__score">37</div>
                <div className="match-stats-progress__bar">
                  <span className="team-1" style={{ width: '58%' }}>
                    &nbsp;
                  </span>
                  <span className="team-2">&nbsp;</span>
                </div>
                <div className="match-stats-progress__score">31</div>
              </div>
            </li>
            <li>
              <div className="match-stats-progress">
                <div className="match-stats-progress__label">Damage Dealt (K)</div>
                <div className="match-stats-progress__score">115.2</div>
                <div className="match-stats-progress__bar">
                  <span className="team-1" style={{ width: '83%' }}>
                    &nbsp;
                  </span>
                  <span className="team-2">&nbsp;</span>
                </div>
                <div className="match-stats-progress__score">81.8</div>
              </div>
            </li>
            <li>
              <div className="match-stats-progress">
                <div className="match-stats-progress__label">Healing Done (K)</div>
                <div className="match-stats-progress__score">62.1</div>
                <div className="match-stats-progress__bar">
                  <span className="team-1" style={{ width: '52%' }}>
                    &nbsp;
                  </span>
                  <span className="team-2">&nbsp;</span>
                </div>
                <div className="match-stats-progress__score">59.4</div>
              </div>
            </li>
            <li className="match-stats-widget__achievements">
              <ul>
                <li>
                  <img src="/assets/img/icon-medal-gold.png" alt="evolve" />2
                </li>
                <li>
                  <img src="/assets/img/icon-medal-silver.png" alt="evolve" />2
                </li>
                <li>
                  <img src="/assets/img/icon-medal-bronze.png" alt="evolve" />0
                </li>
              </ul>
              <ul>
                <li>
                  <img src="/assets/img/icon-medal-gold.png" alt="evolve" />1
                </li>
                <li>
                  <img src="/assets/img/icon-medal-silver.png" alt="evolve" />0
                </li>
                <li>
                  <img src="/assets/img/icon-medal-bronze.png" alt="evolve" />2
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="match-stats-widget match-stats-widget--leaders">
          <div className="match-stats-widget__header">Game Leaders</div>
          <ul className="match-stats-widget__item">
            <li>
              <figure className="match-player team-2" role="group">
                <figure className="match-player__avatar">
                  <img src="/assets/img/samples/match-player-unknow-left-90x70.png" alt="evolve" />
                </figure>
                <figcaption>
                  <span className="match-player__nickname">Lionel-Reese</span>
                  <span className="match-player__name">Marcus Jhonson</span>
                </figcaption>
              </figure>
              <figure className="match-team-logo match-team-logo--team-2" role="group">
                <img
                  src="/assets/img/samples/logo-rhinos-30.png"
                  srcSet="/assets/img/samples/logo-rhinos-30@2x.png 2x"
                  alt="Raging Rhinos Logo"
                />
              </figure>
            </li>
            <li>
              <span>Most damage dealt</span>
              <span>48.365</span>
            </li>
          </ul>
          <ul className="match-stats-widget__item">
            <li>
              <figure className="match-player team-1" role="group">
                <figure className="match-player__avatar">
                  <img src="/assets/img/samples/match-player-01-90x70.png" alt="evolve" />
                </figure>
                <figcaption>
                  <span className="match-player__nickname">DaVikinger90</span>
                  <span className="match-player__name">James Spiegel</span>
                </figcaption>
              </figure>
              <figure className="match-team-logo match-team-logo--team-1" role="group">
                <img
                  src="/assets/img/samples/logo-necromancers-30.png"
                  srcSet="/assets/img/samples/logo-necromancers-30@2x.png 2x"
                  alt="Necromancers Logo"
                />
              </figure>
            </li>
            <li>
              <span>Most healing done</span>
              <span>29.088</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="match-stats-container tab-pane fade" id="tab-3" role="tabpanel">
        <div className="match-stats-widget match-stats-widget--general">
          <div className="match-stats-widget__header">General Stats</div>
          <ul className="match-stats-widget__body">
            <li>
              <figure className="match-team" role="group">
                <figure className="match-team-logo match-team-logo--team-1" role="group">
                  <img
                    src="/assets/img/samples/logo-necromancers-30.png"
                    srcSet="/assets/img/samples/logo-necromancers-30@2x.png 2x"
                    alt="NCR Logo"
                  />
                </figure>
                <figcaption>
                  <div className="match-team__name">NCR</div>
                  <div className="match-team__result ">lost</div>
                </figcaption>
              </figure>
              <div className="match-result">
                <span className="match-result__score">1 : 3</span>
              </div>
              <figure className="match-team match-team--winner" role="group">
                <figure className="match-team-logo match-team-logo--team-2" role="group">
                  <img
                    src="/assets/img/samples/logo-rhinos-30.png"
                    srcSet="/assets/img/samples/logo-rhinos-30@2x.png 2x"
                    alt="RRH Logo"
                  />
                </figure>
                <figcaption>
                  <div className="match-team__name">RRH</div>
                  <div className="match-team__result ">won</div>
                </figcaption>
              </figure>
            </li>
            <li>
              <span>Map / Level</span>
              <span>El Plateado</span>
            </li>
            <li>
              <span>Mission</span>
              <span>Escort</span>
            </li>
            <li>
              <span>Duration</span>
              <span>34:17</span>
            </li>
            <li>
              <span>Status</span>
              <span>Final Score</span>
            </li>
          </ul>
        </div>
        <div className="match-stats-widget match-stats-widget--matchup">
          <div className="match-stats-widget__header">Teams Matchup</div>
          <ul className="match-stats-widget__body">
            <li>
              <div className="match-stats-progress">
                <div className="match-stats-progress__label">Total Kills</div>
                <div className="match-stats-progress__score">27</div>
                <div className="match-stats-progress__bar">
                  <span className="team-1" style={{ width: '34%' }}>
                    &nbsp;
                  </span>
                  <span className="team-2">&nbsp;</span>
                </div>
                <div className="match-stats-progress__score">38</div>
              </div>
            </li>
            <li>
              <div className="match-stats-progress">
                <div className="match-stats-progress__label">Damage Dealt (K)</div>
                <div className="match-stats-progress__score">56.4</div>
                <div className="match-stats-progress__bar">
                  <span className="team-1" style={{ width: '16%' }}>
                    &nbsp;
                  </span>
                  <span className="team-2">&nbsp;</span>
                </div>
                <div className="match-stats-progress__score">94.7</div>
              </div>
            </li>
            <li>
              <div className="match-stats-progress">
                <div className="match-stats-progress__label">Healing Done (K)</div>
                <div className="match-stats-progress__score">58.7</div>
                <div className="match-stats-progress__bar">
                  <span className="team-1" style={{ width: '48%' }}>
                    &nbsp;
                  </span>
                  <span className="team-2">&nbsp;</span>
                </div>
                <div className="match-stats-progress__score">61.3</div>
              </div>
            </li>
            <li className="match-stats-widget__achievements">
              <ul>
                <li>
                  <img src="/assets/img/icon-medal-gold.png" alt="evolve" />0
                </li>
                <li>
                  <img src="/assets/img/icon-medal-silver.png" alt="evolve" />2
                </li>
                <li>
                  <img src="/assets/img/icon-medal-bronze.png" alt="evolve" />1
                </li>
              </ul>
              <ul>
                <li>
                  <img src="/assets/img/icon-medal-gold.png" alt="evolve" />2
                </li>
                <li>
                  <img src="/assets/img/icon-medal-silver.png" alt="evolve" />0
                </li>
                <li>
                  <img src="/assets/img/icon-medal-bronze.png" alt="evolve" />1
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="match-stats-widget match-stats-widget--leaders">
          <div className="match-stats-widget__header">Game Leaders</div>
          <ul className="match-stats-widget__item">
            <li>
              <figure className="match-player team-1" role="group">
                <figure className="match-player__avatar">
                  <img src="/assets/img/samples/match-player-01-90x70.png" alt="evolve" />
                </figure>
                <figcaption>
                  <span className="match-player__nickname">DaVikinger90</span>
                  <span className="match-player__name">James Spiegel</span>
                </figcaption>
              </figure>
              <figure className="match-team-logo match-team-logo--team-1" role="group">
                <img
                  src="/assets/img/samples/logo-necromancers-30.png"
                  srcSet="/assets/img/samples/logo-necromancers-30@2x.png 2x"
                  alt="Necromancers Logo"
                />
              </figure>
            </li>
            <li>
              <span>Most damage dealt</span>
              <span>48.365</span>
            </li>
          </ul>
          <ul className="match-stats-widget__item">
            <li>
              <figure className="match-player team-2" role="group">
                <figure className="match-player__avatar">
                  <img src="/assets/img/samples/match-player-unknow-left-90x70.png" alt="evolve" />
                </figure>
                <figcaption>
                  <span className="match-player__nickname">Neko Krisser</span>
                  <span className="match-player__name">Tess Griffin</span>
                </figcaption>
              </figure>
              <figure className="match-team-logo match-team-logo--team-2" role="group">
                <img
                  src="/assets/img/samples/logo-rhinos-30.png"
                  srcSet="/assets/img/samples/logo-rhinos-30@2x.png 2x"
                  alt="Raging Rhinos Logo"
                />
              </figure>
            </li>
            <li>
              <span>Most healing done</span>
              <span>29.088</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { WaitingRoom }
