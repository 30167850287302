import Parse from 'parse'

const CompetitionPreset = Parse.Object.extend('CompetitionPreset')
const Game = Parse.Object.extend('Game')

export const getPresetsByOverwolfId = async (overwolfId) => {
  const gameQuery = new Parse.Query(Game)
  gameQuery.equalTo('overwolfId', overwolfId)

  const competitionPresetQuery = new Parse.Query(CompetitionPreset)
  competitionPresetQuery
    .include('game')
    .matchesQuery('game', gameQuery)
    .equalTo('active', true)

  return await competitionPresetQuery.find()
}
