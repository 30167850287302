import React from 'react'
import { Preloader, SvgColorFilter } from 'components'
import { Link } from 'react-router-dom'
import { GamesLayout } from 'layouts'

function PageNotFound() {

  return (
    <GamesLayout bgImage="bg--texture-01 site-layout--landing-error">
      <div className="site-wrapper">
        <main className="site-content" id="wrapper">
          <div className="container">
            <div className="row">
              <div className="error__logo-wrapper col-md-6 d-none d-md-block">
                <img src="/assets/img/samples/team-selection-character-02-full.png" className="error__logo" alt="evolve" />
                <div className="ncr-page-decor">
                  <div className="ncr-page-decor__layer-3" />
                </div>
              </div>
              <div className="error__txt-wrapper col-md-5 align-self-center offset-md-1">
                <h1 className="error__title">404</h1>
                <h2 className="error__subtitle">Page Not Found</h2>
                <div className="error__desc">
                  <p>
                    Sorry! The page that you’re trying to see does not exist or was moved.
                    <Link to="/">Click here</Link> to go back and keep browsing!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="site-overlay" />
      </div>
      <Preloader />
      <SvgColorFilter />
    </GamesLayout>
  );
}

export { PageNotFound }
