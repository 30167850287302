import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { customToast } from "utils/helper";
import Parse from 'parse';

const ChangeEmailComponent = (props) => {
  const [user, setUser] = useState(null);
  const { fetchedUserParseObject } = props

  useEffect(() => {
    if (fetchedUserParseObject) {
      setUser({
        email: fetchedUserParseObject.get('email'),
        isEmailVerified: fetchedUserParseObject.get('emailVerified'),
      })
    }
    //eslint-disable-next-line
  }, [fetchedUserParseObject])

  const sendEmailVerification = async () => {
    try {
      await Parse.Cloud.run('resend-email-verification')
      customToast('Email verification sent successfully', 'success')
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <section className="account-order p-5 bg-black  justify-content-start flex-column profile-box-h">
      <div className='d-flex justify-content-between border-bottom mb-5 align-items-center'>
        <h4 className="text-white">Email</h4>
        {user && user.isEmailVerified ? <h5 className='color-success'>Verified ✓</h5> : <h5 className='text-danger'>Not Verified</h5>}
      </div>
      <div>
        <p className='text-white'>
          {user && user.isEmailVerified ? <h6 className='text-white'>{user.email}</h6> :
            <>
              <div className="alert alert-warning text-left" >
                We sent a verification email to <u>{user?.email}</u>, please go to your email app, find our email (also make sure to check your Spam or Junk folder) and follow the instructions in there.
              </div>
              <div className='d-flex justify-content-between'>
                <h6 className='text-white pt-1'>
                  {user?.email}
                </h6>
                <p>
                  <button className='btn btn-info  px-2 py-1 px-lg-3 px-xl-3' onClick={sendEmailVerification}>
                    <u className='text-decoration-none'>Send Verifiction Email Again</u>
                  </button><br />
                </p>
              </div>

            </>
          }
        </p>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  fetchedUserParseObject: state.user.userParseObject,
  emailChangingDisabled: state.config['email-changing-disabled'],
  emailChangingDisabledMessage: state.config['email-changing-disabled-message'],
  emailChangingDisabledAfterKYC: state.config['email-changing-disabled-after-kyc'],
  emailChangingDisabledAfterKYCMessage: state.config['email-changing-disabled-after-kyc-message'],
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})

const ChangeEmail = connect(mapStateToProps, mapDispatchToProps)(ChangeEmailComponent);
export { ChangeEmail }
