import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Table, Preloader} from 'components'
import { findGameById } from 'utils/helper'
import { storedGame } from 'utils/storedGame'
import Parse from 'parse'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { GamesLayout } from 'layouts'

function HostPage(props) {
  const params = useParams()
  const gameId = params.gameId
  const [presets, setPresets] = React.useState([])
  const [gameTitle, setGameTitle] = React.useState('')

  useEffect(() => {
    (async () => {
      findGameName()
      props.getBnbPrice()
      try {
        await fetchPresets()
      } catch (err) {
        console.log(err)
      }
    })()
    //eslint-disable-next-line
  }, [])

  const fetchPresets = async () => {
    const query = new Parse.Query('CompetitionPreset')
    query.include('game')
    const Presets = await query.find()
    const filteredPresets = Presets.filter((preset) => {
      const game = preset.get('game')
      const id = game.get('overwolfId')
      return id === gameId
    })

    const presetsList = filteredPresets.map((preset) => ({
      players: preset.get('players'),
      fee: preset.get('feeInUSD'),
      gameId,
      presetId: preset.id,
      gameName: findGameById(+gameId).gameName
    }))
    setPresets(presetsList)
  }

  const findGameName = () => {
    const game = storedGame.find((game) => game.overwolfId === +gameId)
    setGameTitle(game?.gameName)
  }


  return (
    <GamesLayout bgImage="bg--texture-01 ">
      <Helmet>
        <title>Host a Competition</title>
      </Helmet>
      <div className="site-wrapper site-layout--default">
        <div className="site-overlay" />
        <main className="site-content site-content--center page mt-5" id="wrapper">
          <div className="container container--large">
            <div className="page-heading page-heading--default">
              <div className="page-heading__subtitle h5 color-primary">{gameTitle}</div>
              <h1 className="page-heading__title h2">Host a Competition</h1>
            </div>


            <div className="table-responsive mt-sm-auto mb-sm-auto">
              <Table gamePresets={presets} mode={"HOST"} />
            </div>
          </div>
        </main>
      </div>
      <Preloader />
    </GamesLayout>
  )
}

const mapStateToProps = (state) => ({
  userBalance: state.user.userBalance,
})

export const Host = connect(mapStateToProps)(HostPage)
