import * as actions from './index'
import * as crud from '../cruds/presetCrud'

export const getPresets = (gameId) => async (dispatch) => {
  try {
    const presets = await crud.getPresetsByOverwolfId(gameId)

    dispatch({
      type: actions.SET_PRESETS,
      payload: presets,
    })
  } catch (error) {
    dispatch({
      type: actions.SET_PRESETS_ERROR,
      error: error,
    })
  }
}

export const addActivePreset = (preset) => (dispatch) => {
  try {
    dispatch({
      type: actions.ADD_ACTIVE_PRESET,
      payload: preset,
    })
  } catch (error) {
    dispatch({
      type: actions.ADD_ACTIVE_PRESET_ERROR,
      error: error,
    })
  }
}

export const removeActivePreset = (preset) => (dispatch) => {
  try {
    dispatch({
      type: actions.REMOVE_ACTIVE_PRESET,
      payload: preset,
    })
  } catch (error) {
    dispatch({
      type: actions.REMOVE_ACTIVE_PRESET_ERROR,
      error: error,
    })
  }
}
