import React, { useEffect, useState } from 'react'
import { BouncingBalls } from "react-cssfx-loading";
import { customToast, hexToDecimal, tokenIndexFinder, toWei, fromWei } from 'utils/helper'
import * as Parse from 'parse';
import { stableCoinsOnEOG_Competition } from 'smart-contract';
import { Button, RainbowConnectButton } from 'components';
import { debounce } from "debounce";
import { web3Instance } from 'smart-contract/web3Instance';
import { stableCoins, EOG_ComptitionAddress, approvalAmount } from 'smart-contract';
import { useAccount } from 'wagmi';
import { useNetwork } from 'wagmi/dist';

function TokenSelector(props) {
  const [selectedStableCoin, setSelectedStableCoin] = useState('')
  const [loading, setLoading] = useState(false)
  const [isTokenApproved, setIsTokenApproved] = useState(false)
  const [stableCoinBalance, setStableCoinBalance] = useState(0)
  const [hasSufficientStableCoin, setHasSufficientStableCoin] = useState(false)
  const [hasMoreThanOneWallet, setHasMoreThanOneWallet] = useState(false)
  const chainId = process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID
  const chainIdInDecimalNumbers = hexToDecimal(chainId)

  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const {
    nextStep,
    preset,
    setTokenBalance,
    setTokenBlockchainIndex
  } = props

  useEffect(() => {
    if (window.ethereum?.providers) {
      setHasMoreThanOneWallet(true)
    }
  }, [])

  const handleChangeStableCoin = async (e) => {
    setLoading(true)
    const stableCoin = e.target.value
    const currentTokenIndex = tokenIndexFinder(e.target.value)
    if (currentTokenIndex) {
      setTokenBlockchainIndex(currentTokenIndex.index)
      const currentStableCoinObject = stableCoins.find(coin => coin.symbol === stableCoin)
      setSelectedStableCoin(currentStableCoinObject)
      await checkUserStableCoinAmount(stableCoin)
      checkCurrentTokenApproval(currentStableCoinObject)
    }
  }

  const checkCurrentTokenApproval = async (stableCoinObject) => {
    try {
      const stableCoinContract = stableCoinObject.contract
      const result = await stableCoinContract.methods.allowance(account, EOG_ComptitionAddress).call()
      const currentTokenApprovalAmount = +fromWei(result)
      const feeInUSD = +toWei(preset.get('feeInUSD'))
      if (currentTokenApprovalAmount > feeInUSD) {
        setIsTokenApproved(true)
      }
      else {
        setIsTokenApproved(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const checkUserStableCoinAmount = async (symbol) => {

    const debounceCheck = debounce(async () => {
      try {
        const currentStableCoinObject = stableCoins.find(coin => coin.symbol === symbol)
        const result = await currentStableCoinObject.contract.methods.balanceOf(account).call()
        console.log('balance', result)
        let userStableCoinBalance;
        if (currentStableCoinObject.decimals) {
          userStableCoinBalance = result / 10 ** currentStableCoinObject.decimals
        }
        else {
          userStableCoinBalance = +fromWei(result)
        }
        setStableCoinBalance(userStableCoinBalance)
        setTokenBalance(userStableCoinBalance)
        if (userStableCoinBalance >= preset.get('feeInUSD')) {
          setHasSufficientStableCoin(true)
          setLoading(false)
        }
        else {
          setHasSufficientStableCoin(false)
          setLoading(false)
        }

      } catch (error) {
        console.log(error)
      }
    }, 300)
    await debounceCheck()

  }

  const approveToken = async () => {
    if (selectedStableCoin) {
      setLoading(true)
      const currentStableCoinContract = selectedStableCoin.contract
      const approve = await currentStableCoinContract.methods.approve(EOG_ComptitionAddress, toWei(approvalAmount))
      const transaction = {
        from: web3Instance.utils.toChecksumAddress(account),
        to: web3Instance.utils.toChecksumAddress(currentStableCoinContract._address),
      };

      approve
        .send(transaction)
        .on("transactionHash", (txHash) => {
          setIsTokenApproved(true)
          const ContractApproval = Parse.Object.extend('ContractApproval')
          const approval = new ContractApproval()
          approval.save({
            user: Parse.User.current(),
            tx: txHash,
            contract: currentStableCoinContract._address,
            spender: EOG_ComptitionAddress,
            amount: +approvalAmount,
            userWallet: account,
            symbol: selectedStableCoin.symbol,
            netId: chainId,
          })
        })
        .on("receipt", (result) => {
          setIsTokenApproved(true)
          setLoading(false)
        })
        .on("error", (error) => {
          setIsTokenApproved(false)
          setLoading(false)
          console.log(error)
          customToast('Transaction failed', 'danger')
        })
    }
  }

  return (
    <div>
      <div className="site-content__center text-center ">
        {
          hasMoreThanOneWallet && <div className="alert alert-warning text-left mx-5" >
            Caution: You have more than one web3 wallet extension. Please make sure the active one is the one that you want to use with this website, through the settings in the extensions.          </div>
        }
        <RainbowConnectButton >
          <div style={{ padding: '0 55px' }}>
            <p className={`col primary-green font-16 mb-3 py-2`} style={{ border: '1px solid #a3ff12' }}  >
              <span className={`primary-green  brighten-on-hover`} >
                {selectedStableCoin?.symbol} Balance  ≃ <span className='font-weight-bold' >
                  {(stableCoinBalance || stableCoinBalance == 0) && Math.round(stableCoinBalance * 1000) / 1000}
                </span>
              </span>
            </p>
            {loading &&
              <div className='token-selector-loading mb-3'>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <BouncingBalls color="#a3ff12" width="13px" height="13px" duration="0.5s" />
                </div>
              </div>
            }
            <div className='col-12 p-0'>
              <select
                disabled={chain?.id !== chainIdInDecimalNumbers || loading}
                defaultValue={"DEFAULT"}
                className="w-100 form-select-lg py-2 mx-auto ml-3 font-weight-bold primary-green"
                style={{ backgroundColor: '#151720', border: '1px solid #a3ff12', cursor: 'pointer' }}
                aria-label="form-select-lg example"
                onChange={handleChangeStableCoin}
              >
                <option value="DEFAULT" disabled>Select Token</option>
                {
                  stableCoinsOnEOG_Competition.map(token => {
                    return (
                      <option value={token.tokenName} key={token.index}>{token.tokenName}</option>
                    )
                  })
                }
              </select>
            </div>

            {
              selectedStableCoin ?
                isTokenApproved && hasSufficientStableCoin &&
                <Button disabled={chain?.id !== chainIdInDecimalNumbers || loading} onClick={nextStep} className="btn btn-primary btn-lg mt-4" >
                  {loading ? 'Loading...' : 'Continue'}
                </Button>
                :
                <Button disabled={true} className="btn btn-primary btn-lg mt-4" >
                  {loading ? 'Loading...' : 'Continue'}
                </Button>
            }
            {
              selectedStableCoin && !isTokenApproved &&
              <Button disabled={chain?.id !== chainIdInDecimalNumbers || loading} onClick={approveToken} className="btn btn-primary btn-lg mt-4" >
                {loading ? 'Loading...' : 'Approve'}
              </Button>
            }
          </div>
          {
            selectedStableCoin && isTokenApproved && !hasSufficientStableCoin && !loading &&
            <div className='my-3 font-18 text-white '>
              {`You don't have enough ${selectedStableCoin.symbol} balance.`}
            </div>
          }
        </RainbowConnectButton>
      </div>
    </div>
  )
}

export { TokenSelector }
