import React from 'react'
import { Preloader } from 'components'
import { LoginLayout } from 'layouts'
import LoginContent from './components/LoginContent'

function Login() {

  return (
    <LoginLayout>
      <LoginContent />
      <Preloader />
    </LoginLayout >
  )
}
export { Login }
