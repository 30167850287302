import React, { useEffect } from 'react'
import { Preloader, SvgColorFilter } from 'components'
import { GamesHostContent } from './components/GamesHostContent'
import Parse from 'parse';
import { storedGame } from 'utils/storedGame'
import { GamesLayout } from 'layouts'

function GamesHost() {
  const [activeGamesList, setActiveGamesList] = React.useState([]);

  useEffect(() => {
    (async () => {
      await getData()
    })();
    // eslint-disable-next-line 
  }, [])

  const getData = async () => {
    const GamesClass = Parse.Object.extend('Game');
    const gamesQuery = new Parse.Query(GamesClass);
    const games = await gamesQuery.equalTo('active', true).find()
    const activeGames = games.map(item => ({
      title: item.get('title'),
      overwolfId: item.get('overwolfId'),
    }))
    const generatedGamesList = activeGames.map(activeGame => {
      return storedGame.find(game => {
        return Number(activeGame.overwolfId) === game.overwolfId
      })
    })
    setActiveGamesList(generatedGamesList)
  }

  return (
    <GamesLayout>
      <GamesHostContent gamesCardsList={activeGamesList} />
      <Preloader />
      <SvgColorFilter />
    </GamesLayout>
  )
}

export { GamesHost }