import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Parse from 'parse'
import { Provider } from 'react-redux'
import { store } from 'redux/store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { syncTimeWithServer } from 'utils/helper'

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JAVASCRIPT_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL
Parse.liveQueryServerURL = process.env.REACT_APP_PARSE_LIVEQUERY_SERVER_URL
syncTimeWithServer()

ReactDOM.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer
      toastClassName="customized-react-toast"
      bodyClassName="customized-react-toast-container"
      position="bottom-right"
      autoClose={4000}
      hideProgressBar={true}
      closeOnClick
      pauseOnHover
      closeButton={false}
    />
  </>,
  document.getElementById('root')
)
