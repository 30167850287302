import React from 'react'

function LoginFooter() {
  return (
    <>
      <footer id="footer" className="footer text-center pb-4">
      <ul className="social-menu social-menu--landing social-menu--landing-glitch">
          <li>
            <a href="https://www.instagram.com/theevolutionofgaming/" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram" />
              <span className="link-subtitle">Instagram</span>EOG INSTAGRAM
            </a>
          </li>
          <li>
            <a href="https://twitter.com/EOGcompetitions/" target="_blank" rel="noreferrer">
              <i className="fab fa-twitter" />
              <span className="link-subtitle">Twitter</span>EOG TWITTER
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/theevolutionofgaming/" target="_blank" rel="noreferrer">
              <i className="fab fa-linkedin" />
              <span className="link-subtitle">Linkedin</span>EOG LINKEDIN
            </a>
          </li>
          <li>
            <a href="https://discord.com/invite/K8hFuGY63y" target="_blank" rel="noreferrer">
              <i className="fab fa-discord" />
              <span className="link-subtitle">DISCORD</span>EOG DISCORD
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/theevolutionofgaming/" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook" />
              <span className="link-subtitle">FACEBOOK</span>EOG FACEBOOK
            </a>
          </li>

        </ul>
      </footer>
    </>
  )
}

export { LoginFooter }
