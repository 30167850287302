import React from 'react'
import { UserMenu } from 'components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'



function HomeHeaderComponent(props) {

  const { gamingEnabled, userGamingEnabled } =
    props


  // const handleUserLevel = (userlevel) => {
  //   if (userlevel || userlevel === 0) return `level ${userlevel}`
  // }


  return (
    <header id="header" className="site-header site-header--landing">
      <nav className="main-nav">
        <ul className="main-nav__list">
          {/* {userIcoEnabled && <li >
            <Link to="/ico">ICO</Link>
          </li>}
          {userIcoEnabled === null && icoEnabled && !userCountryIsRestrictedToICO && <li >
            <Link to="/ico">ICO</Link>
          </li>} */}
          <ul className="main-nav__sub"></ul>
          
          {userGamingEnabled && <li >
            <Link to="/join">Games</Link>
          </li>}
          {userGamingEnabled === null && gamingEnabled && <li >
            <Link to="/join">Games</Link>
          </li>}
          {/* <li >
            <a href=" " onClick={(e) => { e.preventDefault() }} >EvolveCoin</a>
            <ul className="main-nav__sub">
              <li >
                <a href="https://evolvecoin.io" target={"_blank"} rel="noreferrer">
                  About
                </a>
              </li>
              <li >
                <a href="https://evolvecoin.io/white-paper" target={"_blank"} rel="noreferrer">
                  Whitepaper
                </a>
              </li>
              <li >
                <a href={tokenURL} target={"_blank"} rel="noreferrer">
                  Contract Address
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </nav>
      <div className="header-logo header-logo--img">
        <img
          src="/assets/img/logo.png"
          srcSet="/assets/img/logo@2x.png 2x"
          alt="EVOLVE"
        />
      </div>
      <nav className="main-nav">
        <ul className="main-nav__list">
{/* 
          {userGamingEnabled && <li >
            <Link to="/join">Games</Link>
          </li>}
          {userGamingEnabled === null && gamingEnabled && <li >
            <Link to="/join">Games</Link>
          </li>} */}
          {/* <li ><Link to="/profile/account-status">
            {handleUserLevel(props.level)}
          </Link></li> */}
          <UserMenu username={props.username} userlevel={props.level} />
        </ul>
      </nav>
    </header>
  )
}


const mapStateToProps = (state) => ({
  username: state.user.username,
  gamingEnabled: state.config?.['gaming-enabled'],
  icoEnabled: state.config?.['ico-enabled'],
  userIcoEnabled: state.user?.userIcoEnabled,
  userGamingEnabled: state.user?.userGamingEnabled,
  userParseObject: state.user.userParseObject,
  config: state.config,
  level: state.user?.userParseObject?.get('level'),
})

const mapDispatchToProps = (dispatch) => ({
})

const HomeHeader = connect(mapStateToProps, mapDispatchToProps)(HomeHeaderComponent)
export { HomeHeader }
