import * as actions from "../actions";

const initState = [

];

export const gameReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_ACTIVE_GAMES:
      return [
        ...action.payload,
      ]
    case actions.SET_ACTIVE_GAMES_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case actions.ADD_ACTIVE_GAME:
        const games = [...state, action.payload]
      return [
        ...games
      ]
    case actions.ADD_ACTIVE_GAME_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case actions.REMOVE_ACTIVE_GAME:
      const activeGames = state.filter(
        (i) => Number(i.overwolfId) !== Number(action.payload.overwolfId)
      );
      return [
        ...activeGames,
      ]
    case actions.REMOVE_ACTIVE_GAME_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
