import * as actions from './index';
import * as crud from '../cruds/priceCrud'

export const getBnbPrice = () => async (dispatch) => {
  try {
    const jsonBnbData = await crud.fetchBnbPrice()
    const result = await jsonBnbData.json()
    dispatch({
      type: actions.GET_BNB_PRICE,
      payload: {
        bnbPrice: result.binancecoin.usd
      }
    })
  } catch (error) {
    dispatch({
      type: actions.GET_BNB_PRICE_ERROR,
      error: error
    })
  }
}

export const setSwapAmounts = (amounts) => {
  return {
    type: actions.SET_TOKEN_PRICE,
    payload: {
      swapAmounts: amounts
    }
  }
}

export const setStableCoin = (coin) => {
  return {
    type: actions.SET_STABLE_COIN,
    payload: {
      stableCoin: coin
    }
  }
}