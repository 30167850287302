import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'components/button/Button'
import Parse from 'parse';
import Form from 'react-bootstrap/Form'
import { customToast } from 'utils/helper';
import { validateEmail } from 'utils/regex';

export function ResetPasswordModal(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span className="text-white mt-2" type='button' onClick={() => setModalShow(true)}>
        {props.children}
      </span>
      <ResetModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

function ResetModal(props) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { onHide } = props;

  useEffect(() => {


  }, [loading])

  function resetPassword() {
    setLoading(true);
    if (validateEmail(email)){
      Parse.User.requestPasswordReset(email).then(function () {
        setLoading(false);
        onHide()
        customToast("Password reset request was sent successfully", 'success');
      }).catch(function (error) {
        setLoading(false);
        customToast(error.message, "danger");
        console.log(error.message)
      });
    }
    else{
      setLoading(false);
      customToast("Please enter a valid email address", "danger");
    }
  }

  const handleRegister = () => {
    resetPassword()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName='modal-background-dark'
      centered
    >
      <Modal.Header className={`btn text-right text-white modal-background-dark cursor-default mb-2`}>
        <button className=' text-center border-0 ' style={{ backgroundColor: '#5e627e' }} onClick={props.onHide}><span className='font-weight-bold'>×</span></button>
      </Modal.Header>
      <Modal.Body className="modal-background-dark d-flex justify-content-center ">
        <main className=" site-content text-center col-7 mb-5" >
          <div className="site-content__center ">
            <h4 className="text-success pb-3 my-5">
              RESET PASSWORD
            </h4>
            <Form.Group className="mb-3 mx-5 text-left" >
              <Form.Label className="text-white ">Email</Form.Label>
              <Form.Control className="p-2 bg-white" onChange={(e) => setEmail(e.target.value)} name='email' placeholder="Enter Your Email" />
            </Form.Group>
            <Button onClick={handleRegister} className='btn-md mt-5' >
              {loading ? <div className="spinner-border text-dark" style={{ width: '.9rem', height: '.9rem', fontWeight: 'bold' }} role="status" ></div> : 'RESET'}
            </Button>
          </div>
        </main>
      </Modal.Body>
    </Modal>
  );
}
