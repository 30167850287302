import React from 'react'

function LoginHeader() {
  return (
    <header id="header" className="site-header site-header--landing">
      <div className="header-logo header-logo--img ">
          <img
            src="/assets/img/logo.png"
            srcSet="/assets/img/logo@2x.png 2x"
            alt="EVOLVE"
          />
      </div>
    </header>
  )
}

export { LoginHeader }
