import { web3Instance } from "../web3Instance"

export const vestingABI = [
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"token",
           "type":"address"
        }
     ],
     "stateMutability":"nonpayable",
     "type":"constructor"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":false,
           "internalType":"uint256",
           "name":"lockIndex",
           "type":"uint256"
        }
     ],
     "name":"LockCreated",
     "type":"event"
  },
  {
     "anonymous":false,
     "inputs":[
        {
           "indexed":true,
           "internalType":"address",
           "name":"previousOwner",
           "type":"address"
        },
        {
           "indexed":true,
           "internalType":"address",
           "name":"newOwner",
           "type":"address"
        }
     ],
     "name":"OwnershipTransferred",
     "type":"event"
  },
  {
     "inputs":[
        
     ],
     "name":"_token",
     "outputs":[
        {
           "internalType":"contract IERC20",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"_unlocked",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address[]",
           "name":"accounts",
           "type":"address[]"
        },
        {
           "internalType":"uint256",
           "name":"lockIndex",
           "type":"uint256"
        }
     ],
     "name":"addToLock",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"claimAll",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"amount",
           "type":"uint256"
        },
        {
           "internalType":"uint8",
           "name":"startUnlockPercent",
           "type":"uint8"
        },
        {
           "internalType":"uint8",
           "name":"startUnlockInDays",
           "type":"uint8"
        },
        {
           "internalType":"uint8",
           "name":"fullUnlockInDays",
           "type":"uint8"
        },
        {
           "internalType":"uint8",
           "name":"expireUnlockInDays",
           "type":"uint8"
        }
     ],
     "name":"defineLock",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"amount",
           "type":"uint256"
        },
        {
           "internalType":"address",
           "name":"from",
           "type":"address"
        }
     ],
     "name":"deposit",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"listingDate",
     "outputs":[
        {
           "internalType":"uint32",
           "name":"",
           "type":"uint32"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"",
           "type":"uint256"
        }
     ],
     "name":"locks",
     "outputs":[
        {
           "internalType":"uint256",
           "name":"amount",
           "type":"uint256"
        },
        {
           "internalType":"uint32",
           "name":"created",
           "type":"uint32"
        },
        {
           "internalType":"uint8",
           "name":"startUnlockPercent",
           "type":"uint8"
        },
        {
           "internalType":"uint8",
           "name":"startUnlockInDays",
           "type":"uint8"
        },
        {
           "internalType":"uint8",
           "name":"fullUnlockInDays",
           "type":"uint8"
        },
        {
           "internalType":"uint8",
           "name":"expireUnlockInDays",
           "type":"uint8"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"account",
           "type":"address"
        }
     ],
     "name":"locksOf",
     "outputs":[
        {
           "components":[
              {
                 "components":[
                    {
                       "internalType":"uint256",
                       "name":"amount",
                       "type":"uint256"
                    },
                    {
                       "internalType":"uint32",
                       "name":"created",
                       "type":"uint32"
                    },
                    {
                       "internalType":"uint8",
                       "name":"startUnlockPercent",
                       "type":"uint8"
                    },
                    {
                       "internalType":"uint8",
                       "name":"startUnlockInDays",
                       "type":"uint8"
                    },
                    {
                       "internalType":"uint8",
                       "name":"fullUnlockInDays",
                       "type":"uint8"
                    },
                    {
                       "internalType":"uint8",
                       "name":"expireUnlockInDays",
                       "type":"uint8"
                    }
                 ],
                 "internalType":"struct Vesting.Lock",
                 "name":"lock",
                 "type":"tuple"
              },
              {
                 "internalType":"uint256",
                 "name":"claimed",
                 "type":"uint256"
              },
              {
                 "internalType":"uint256",
                 "name":"claimable",
                 "type":"uint256"
              },
              {
                 "internalType":"uint8",
                 "name":"claimStage",
                 "type":"uint8"
              }
           ],
           "internalType":"struct Vesting.LockStatus[]",
           "name":"accountLocks",
           "type":"tuple[]"
        },
        {
           "internalType":"uint256",
           "name":"totalClaimable",
           "type":"uint256"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"owner",
     "outputs":[
        {
           "internalType":"address",
           "name":"",
           "type":"address"
        }
     ],
     "stateMutability":"view",
     "type":"function"
  },
  {
     "inputs":[
        
     ],
     "name":"renounceOwnership",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint32",
           "name":"timestampInSeconds",
           "type":"uint32"
        }
     ],
     "name":"setListingDate",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"address",
           "name":"newOwner",
           "type":"address"
        }
     ],
     "name":"transferOwnership",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  },
  {
     "inputs":[
        {
           "internalType":"uint256",
           "name":"amount",
           "type":"uint256"
        },
        {
           "internalType":"address",
           "name":"to",
           "type":"address"
        }
     ],
     "name":"withdraw",
     "outputs":[
        
     ],
     "stateMutability":"nonpayable",
     "type":"function"
  }
]
export const vestingContractAddress = '0xcFC0157247d5250d1109a4Dd7D2a54cD5cA521Ce'
export const vestingContract = new web3Instance.eth.Contract(vestingABI, vestingContractAddress)
