import { UsernameTextField } from '../../../components/username-text-field/UsernameTextField'
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { customToast } from "utils/helper";


const ChangeUserNameComponent = (props) => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(null);
  const [usernameAvailable, setUsernameAvailable] = useState(null);
  const {
    fetchedUserParseObject,
    usernameChangingDisabled,
    usernameChangingDisabledAfterKYC,
    usernameChangingDisabledMessage,
    usernameChangingDisabledAfterKYCMessage,
  } = props;

  useEffect(() => {
    if (fetchedUserParseObject) {
      setUser({
        username: fetchedUserParseObject.get('username'),
      })
    }
  }, [fetchedUserParseObject])

  const handleChangeUsername = async () => {
    try {
      if (user.username !== username && username) {
        fetchedUserParseObject.set('username', username)
        await fetchedUserParseObject.save()
        customToast('Username updated successfully', 'success')
        setUser({ ...user, username })
        props.fetchCurrentUser()
      }
      else if (user.username === username) {
        customToast('Username is already updated', 'warning')
      }
      else if (!username) {
        customToast('Username is required', 'danger')
      }
      else {
        customToast('Username is already saved', 'warning')
      }
    } catch (error) {
      customToast(error.message, 'danger')
    }
  }

  return (
    <section className="account-order p-5 bg-black  justify-content-start flex-column profile-box-h">
      <h4 className=" text-white border-bottom mb-5">Username</h4>

      {
        usernameChangingDisabled ?

          <div className=" d-flex justify-content-center align-items-center flex-grow-1 flex-column text-white">
            <p className="text-white">
              {usernameChangingDisabledMessage ? usernameChangingDisabledMessage : 'Username Changing is disabled.'}
            </p>
          </div>

          : usernameChangingDisabledAfterKYC && fetchedUserParseObject.get('KYCAt') ?
            <div className=" d-flex justify-content-center align-items-center flex-grow-1 flex-column text-white">
              <p className="text-white">
                {usernameChangingDisabledAfterKYCMessage ? usernameChangingDisabledAfterKYCMessage : 'Email Changing is disabled after KYC.'}
              </p>
            </div>

            : <>
              <p className=" text-white my-1">Enter New Username</p>
              <div className="row d-flex justify-content-between align-items-center  pb-2">
                <UsernameTextField
                  placeholder={user?.username}
                  username={(username) => setUsername(username)}
                  usernameAvailability={(availability) => setUsernameAvailable(availability)}
                  className="form-control bg-white  p-2  "
                  containerClassName="col-md-5 d-flex" />
                <div className="col-3 text-right">
                  <button
                    className='btn btn-primary btn-sm mt-2 mt-sm-0'
                    disabled={!usernameAvailable}
                    onClick={handleChangeUsername} >
                    Save
                  </button>
                </div>
              </div>
            </>
      }
    </section>
  )
}


const mapStateToProps = (state) => ({
  fetchedUserParseObject: state.user.userParseObject,
  usernameChangingDisabled: state.config['username-changing-disabled'],
  usernameChangingDisabledAfterKYC: state.config['username-changing-disabled-after-kyc'],
  usernameChangingDisabledMessage: state.config['username-changing-disabled-message"'],
  usernameChangingDisabledAfterKYCMessage: state.config['username-changing-disabled-after-kyc-message'],
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})

const ChangeUserName = connect(mapStateToProps, mapDispatchToProps)(ChangeUserNameComponent);
export { ChangeUserName }
