// all contract files are for 0x61 netId -- BSC TESTNET
import { polygon } from '@wagmi/chains'
export { evolveContract, evolveContractAddress } from './evolve'
export { swapContract, swapContractAddress } from './swap'
export { USDTContract, USDTContractAddress } from './usdt'
export { USDCContract, USDCContractAddress } from './usdc'
export { BUSDContract, BUSDContractAddress } from './busd'
export { DAIContract, DAIContractAddress } from './dai'
export { vestingContract, vestingContractAddress } from './vesting'
export { bulkContractAddress } from './bulk'
export { stableCoins, stableCoinsOnEOG_Competition } from './stableCoins'
export { EOG_ComptitionAddress } from './EOG_Competition'

export const chain = polygon
export const scannerURL = 'https://polygonscan.com'
export const getTokenScannerURL = contractAddress => `${scannerURL}/token/${contractAddress}`
export const getAddressScannerURL = address => `${scannerURL}/address/${address}`
export const getTxScannerURL = txHash => `${scannerURL}/tx/${txHash}`
export const getTokenBalanceUrl = (contractAddress, walletAddress) => `${scannerURL}/token/${contractAddress}?a=${walletAddress}`
