import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'redux/actions/userActions'
import { isUserCountryRestricted } from 'utils/helper';



const AccountStatusComponent = (props) => {
  const [user, setUser] = useState(null);
  const [userCountryIsRestrictedToICO, setUserCountryIsRestrictedToICO] = useState(false)
  const {
    fetchedUserParseObject,
    icoEnabled,
    icoEnabledMessage,
    icoDisabledMessage,
    userIcoEnabled,
    // KYCRequire,
    // emailVerificationRequired,
    config,
    // IdentityVerification,
    isEmailVerified,
    verifiedAt,
    //  level,
    levelRequirements
  } = props
  // const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    setUserDataInState()

    const isRestricted = isUserCountryRestricted(fetchedUserParseObject, config)
    setUserCountryIsRestrictedToICO(isRestricted)

    //eslint-disable-next-line
  }, [fetchedUserParseObject, config])

  const setUserDataInState = async () => {
    const fetchedUser = fetchedUserParseObject
    if (fetchedUser) {
      const platforms = fetchedUser?.get('platforms')
      const lolSettingIndex = platforms?.riot?.games?.[5624]
      const user = {
        username: fetchedUser.get('username'),
        email: fetchedUser.get('email'),
        steamId: platforms?.steam?.id,
        riotId: platforms?.riot?.id,
        date: fetchedUser.createdAt,
        IdentityVerification: fetchedUser.get('KYCAt'),
        platforms: platforms,
        lolSettingIndex: lolSettingIndex,
        isEmailVerified: fetchedUser.get('emailVerified'),
        verifiedAt: fetchedUser.get('verifiedAt'),
        KYCAt: fetchedUser.get('KYCAt'),
      }
      setUser(user)
    }
  }

  // const sendEmailVerification = async () => {
  //   try {
  //     await Parse.Cloud.run('resend-email-verification')
  //     customToast('Email verification sent successfully', 'success')
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }


  // eslint-disable-next-line no-lone-blocks
  {
    levelRequirements && levelRequirements.map((items, index) => {
      if (index === 0) return false
      return (
        <li className='info-box__content text-white  d-flex justify-content-end row p-2 mx-1' key={index}>
          <span className='col-4 m-0 p-0 pl-3'>level {index}</span>
          {Object.keys(items).map((key) => {
            return (
              <li key={key + index}>{key}:{items[key]}</li>
            )
          })
          }
        </li>
      )
    })
  }

  // const userLevelTable = () => {
  //   return levelRequirements.map((items, index) => {
  //     // showing 1 passed and 1 next level
  //     if (index < level - 1 || index > level + 1) return false
  //     //Passed level
  //     if (index < level) {
  //       if (index === 0) return false
  //       return <li className='info-box__content text-secondary  d-flex justify-content-end row p-2 mx-1' key={index}>
  //         <span className='col-1'>✓</span>
  //         <span className='col-2 m-0 p-0 '><del>level {index}</del> </span>
  //         {Object.keys(items).map((key) => {
  //           if (items[key] !== 0) {
  //             return (
  //               <span className='col p-0 text-left ' key={key + index}><del>{dynamicText(key, items[key])}</del> </span>
  //             )
  //           } else {
  //             return false
  //           }
  //         })}
  //       </li>
  //     }
  //     else if (index === level) {
  //       //current level
  //       if (index === 0) return false
  //       return <li className='info-box__content color-primary row p-2 mx-1' key={index}>
  //         <span className='col-1'></span>
  //         <span className='col-2 m-0 p-0 '>level {index}</span>
  //         {Object.keys(items).map((key) => {
  //           if (items[key] !== 0) {
  //             return (
  //               <span className='col p-0 text-left ' key={key + index}>{dynamicText(key, items[key])}</span>
  //             )
  //           } else {
  //             return false
  //           }
  //         })}
  //       </li>
  //     } else {
  //       return <li className='info-box__content text-white d-flex justify-content-end row p-2 mx-1' key={index}>
  //         <span className='col-1'></span>
  //         <span className='col-2 m-0 p-0 '>level {index}</span>
  //         {Object.keys(items).map((key) => {
  //           if (items[key] !== 0) {
  //             return (
  //               <span className='col p-0 text-left' key={key + index}>{dynamicText(key, items[key])}</span>
  //             )
  //           } else {
  //             return false
  //           }
  //         })}
  //       </li>

  //     }

  //   })
  // }

  // const nextLevels = (level) => {
  //   return levelRequirements.map((items, index) => {
  //     if (index > level + 1) {
  //       return <li className='info-box__content text-white row p-2 mx-1' key={index}>
  //         <span className='col-1'></span>
  //         <span className='col-2 m-0 p-0  '>level {index}</span>
  //         {Object.keys(items).map((key) => {
  //           if (Number(items[key]) !== 0) {
  //             return (
  //               <span className='col p-0 text-left ' key={key + index}>{dynamicText(key, items[key])}</span>
  //             );
  //           }
  //           return false
  //         })}
  //       </li>
  //     }
  //     return false
  //   })

  // }



  const icoMessageMarkup = () => {
    let userHasICOAccess = false
    // if (userGamingEnabled === false || (userGamingEnabled === null && !gamingEnabled)) {
    if (userIcoEnabled === true || (userIcoEnabled === null && icoEnabled && !userCountryIsRestrictedToICO)) {
      userHasICOAccess = true
    }
    else if (userIcoEnabled === true && !userCountryIsRestrictedToICO) {
      userHasICOAccess = true
    }
    if (userHasICOAccess) {
      return (
        <div><span className='color-success fw-bold'>{icoEnabledMessage}</span></div>
      )
    } else {
      return (
        <div><span className='color-success fw-bold'>{icoDisabledMessage}</span></div>
      )
    }
    // }
  }



  return (
    <section className="account-order p-5 bg-black  justify-content-start flex-column profile-box-h">
      <div className='d-flex justify-content-between border-bottom mb-5 align-items-center'>
        <h4 className="text-white">Account Status</h4>
        {/* {verifiedAt ? <h5 className='color-success'>(level {level})  Active ✓</h5> : <h5 className='text-danger'>Not Active</h5>} */}
      </div>


      <div className="account-order__status mb-3">
        <ul className='match-stats-widget__body'>
          {/* {levelRequirements && userLevelTable()} */}

          {/* {showMore && nextLevels(level)}
          {levelRequirements && levelRequirements.length > level + 2 && <li className='info-box__content text-white  d-flex justify-content-end row p-2 mx-1'>
            <button
              onClick={() => setShowMore(!showMore)}
              className="btn btn-link info-box__content col-12 text-right mr-2"
              style={{ fontSize: '16px', padding: 0 }}>
              {showMore ? 'Hide ▲' : 'More ▼'}
            </button>
          </li>} */}

        </ul>
      </div>



      {/* <div>
        <div className="row">
          <div className="col col-sm-8">
            <div className="row">
              <div className="account-order__status col-sm-6">
                <span className="account-order__label">Username</span>
                <span className="color-success">
                  {user?.username}
                </span>
              </div>

            </div>
          </div>
          <div className="account-order__date col-sm-4">
            <span className="account-order__label" >email</span>
            <div className='color-success'>
              {
                user?.isEmailVerified ?
                  `${user.email}  ✓` :
                  <>
                    <button className='btn btn-info  px-2 py-1 px-lg-3 px-xl-3' onClick={sendEmailVerification}>
                      <u className='text-decoration-none'>Send Again</u>
                    </button><br />
                  </>
              }
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-8">
            <div className="row">
              <div className="account-order__status col-sm-6">
                <span className="account-order__label">Identity Verification</span>
                {
                  user?.IdentityVerification ?
                    <span className="badge badge-pill badge-primary">Verified</span> :
                    <Link to='/profile/verify-identity'><button className='btn btn-info px-2 py-1 px-lg-3 px-xl-3'><u className='text-decoration-none'>Identify yourself</u></button></Link>
                }
              </div>
            </div>
          </div>
          <div className="account-order__date col-sm-4">
            <span className="account-order__label">Member Since</span>
            <div className='text-white'>
              {user?.date?.toLocaleDateString("en-US")}
            </div>
          </div>
        </div>
      </div> */}
      <div className=" text-center  d-flex  flex-column justify-content-center pt-5 ">
        {/* {!user?.isEmailVerified && emailVerificationRequired &&
          <div className="alert alert-warning text-left" >
            We sent a verification email to <u>{user?.email}</u>, please go to your email app, find our email (also make sure to check your Spam or Junk folder) and follow the instructions in there.
          </div>
        }
        {!user?.verifiedAt && KYCRequire && !user?.IdentityVerification &&
          <div className="alert alert-warning text-left" >
            You have to provide your identity information and go through the verification process in order to use our system. Please
            <Link to='/profile/verify-identity' className='px-1'><u>go to the Verify Identity page</u></Link>
            to do that.
          </div>
        } */}
        {!isEmailVerified && <div className="alert alert-warning text-left" >
          We sent a verification email to <u>{user?.email}</u>, please go to your email app, find our email (also make sure to check your Spam or Junk folder) and follow the instructions in there.
        </div>}
        {/* {!IdentityVerification && <div className="alert alert-warning text-left" >
          You have to provide your identity information and go through the verification process in order to use our system. Please
          <Link to='/profile/verify-identity' className='px-1'><u>go to the Verify Identity page</u></Link>
          to do that.
        </div>} */}
        {/* {
          !verifiedAt && <div className="alert alert-warning text-left" >
            After your account is verified, you will be able to use the platform.
          </div>
        } */}
        {verifiedAt ?
          <span className="color-success  border border-success border-1 p-4  fw-bold font-18 overflow-wrap">Your profile is verified <strong>&nbsp; ✓</strong>{icoMessageMarkup()}</span> : <div className='border border-danger color-danger p-3 mt-5'>Please complete your profile to compete games or participate in our ico.</div>
        }

      </div>
    </section >
  )
}

const mapStateToProps = (state) => ({
  fetchedUserParseObject: state.user.userParseObject,
  gamingEnabled: state.config?.['gaming-enabled'],
  icoEnabled: state.config?.['ico-enabled'],
  icoEnabledMessage: state.config?.['ico-enabled-message'],
  icoDisabledMessage: state.config?.['ico-disabled-message'],
  userGamingEnabled: state.user?.userGamingEnabled,
  userIcoEnabled: state.user?.userIcoEnabled,
  KYCRequire: state.config['verification-requires-kyc'],
  emailVerificationRequired: state.config['verification-requires-email'],
  config: state.config,
  IdentityVerification: state.user.userParseObject?.get('KYCAt'),
  isEmailVerified: state.user.userParseObject?.get('emailVerified'),
  verifiedAt: state.user.userParseObject?.get('verifiedAt'),
  level: state.user?.userParseObject?.get('level'),
  levelRequirements: state.config['level-requirements']
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})

const AccountStatus = connect(mapStateToProps, mapDispatchToProps)(AccountStatusComponent);
export { AccountStatus }
