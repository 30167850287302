export const storedGame = [
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-01.png",
    overwolfId: 21626,
    href: "",
    gameName: "WARZONE",
    requiredItemsToPlay: [],
    platformTitle: ""
  },
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-02.png",
    overwolfId: 21216,
    href: "",
    gameName: "FORTNITE",
    requiredItemsToPlay: [],
    platformTitle: ""
  },
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-03.png",
    overwolfId: 21566,
    href: "",
    gameName: "APEX LEGENDS",
    requiredItemsToPlay: [],
    platformTitle: ""
  },
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-04.png",
    overwolfId: 10906,
    href: "",
    gameName: "PUBG",
    requiredItemsToPlay: ['pubgUsername'],
    platformTitle: "steam"
  },
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-01.png",
    overwolfId: 5426,
    href: "",
    gameName: "LEAGUE OF LEGENDS",
    requiredItemsToPlay: ['riot'],
    platformTitle: ""
  },
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-01.png",
    overwolfId: 7764,
    href: "",
    gameName: "CS:GO",
    requiredItemsToPlay: ['steam'],
    platformTitle: ""
  },
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-03.png",
    overwolfId: 7314,
    href: "",
    gameName: "DOTA2",
    requiredItemsToPlay: [],
    platformTitle: ""
  },
  {
    backgroundImageUrl: "/assets/img/samples/team-selection-character-08.png",
    overwolfId: 21640,
    href: "",
    gameName: "VALORANT",
    requiredItemsToPlay: ['valorantUserName', 'valorantTag'],
    platformTitle: "riot"

  }
]