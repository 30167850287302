import { combineReducers } from 'redux'
import { userReducer } from './userReducer'
import { priceReducer } from './priceReducer'
import { approvalReducer } from './approvalReducer'
import { configReducer } from './configReducer'
import { gameReducer } from './gameReducer'
import { presetReducer } from './presetReducer'

const rootReducers = combineReducers({
  user: userReducer,
  price: priceReducer,
  approval: approvalReducer,
  config: configReducer,
  games: gameReducer,
  presets: presetReducer,
})

export default rootReducers