import * as crud from 'redux/cruds/userCrud';
import * as actions from './index';
import { fromWei } from 'utils/helper';

export const getUserBalance = (account) => async (dispatch) => {
  try {
    const result = await crud.getUserBalanceParse(account)
    dispatch({
      type: actions.GET_USER_BALANCE,
      payload: {
        userBalance: fromWei(result)
      }
    })
  } catch (err) {
    dispatch({
      type: actions.GET_USER_BALANCE_ERROR,
      error: err
    })
  }
}

export const fetchCurrentUser = () => async dispatch => {
  try {
    const result = await crud.fetchUserParse()
    dispatch({
      type: actions.FETCH_CURRENT_USER,
      payload: {
        userParseObject: result,
        userAvatarUrl: result.get('avatar')?.url(),
        username: result.get('username'),
        userIcoEnabled: result.get('icoEnabled') === undefined ? null : result.get('icoEnabled'),
        userGamingEnabled: result.get('gamingEnabled') === undefined ? null : result.get('gamingEnabled'),
        userSwapEnabled: result.get('icoSwapEnabled') === undefined ? null : result.get('icoSwapEnabled'),
        userFiatEnabled: result.get('icoFiatEnabled') === undefined ? null : result.get('icoFiatEnabled'),
        userWireEnabled: result.get('icoWireEnabled') === undefined ? null : result.get('icoWireEnabled')
      }
    })
  } catch (err) {
    dispatch({
      type: actions.FETCH_CURRENT_USER_ERROR,
      payload: {
        error: true,
        userParseObject: null,
        userAvatarUrl: '',
        username: ''
      }
    })
  }
}

export const setHasSufficientStableCoin = (bool) => {
  return {
    type: actions.SET_HAS_SUFFICIENT_STABLE_COIN,
    payload: {
      hasSufficientStableCoin: bool
    }
  }
}

export const setApprovedTokens = (approvedTokens) => {
  return {
    type: actions.SET_APPROVED_TOKENS,
    payload: {
      approvedTokens
    }
  }
}

export const getUserWalletApproval = (address) => async dispatch => {
  try {
    const result = await crud.getWalletApproval(address)
    dispatch({
      type: actions.SET_USER_WALLET_APPROVAL,
      payload: {
        isUserWalletApproved: result ? result : false
      }
    })
  } catch (err) {
    dispatch({
      type: actions.SET_USER_WALLET_APPROVAL_ERROR,
      error: err
    })
  }
}

export const setPresetId = (presetId) => {
  return {
    type: actions.SET_PRESET_ID,
    payload: {
      presetId
    }
  }
}