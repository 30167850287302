import React from 'react'
import { Link } from 'react-router-dom'

export function GameCard(props) {
  const { backgroundImageUrl, gameName, frameColor, selectMode, overwolfId } = props
  return (
    <div className="col-sm-6 col-lg-3 mb-3 py-4">
      <div className={`team-item team-item--v4 ${frameColor}`}>
        <Link to={`game/${overwolfId}`} className="team-item__thumbnail">
          <div className="team-item__bg-holder">
            <div
              className="team-item__bg"
              style={{
                backgroundImage:
                  "url(/assets/img/samples/team-selection-character-01-bg.jpg)"
              }}
            />
          </div>
          <div
            style={{
              backgroundImage:
                `url(${backgroundImageUrl})`
            }}
            className="team-item__img-primary"
          />
        </Link>
        <span className="team-item__subtitle h6">{selectMode}</span>
        <h2 className="team-item__title">{gameName}</h2>
      </div>
    </div>
  )
}

