import React, { useState } from 'react'
import { connect } from 'react-redux'
import Parse from 'parse'
import { fetchCurrentUser } from 'redux/actions/userActions'
import { customToast } from 'utils/helper'
import { storedGame } from 'utils/storedGame'

const inputsConfig = [
  {
    name: 'steam',
    title: 'Steam ID',
    placeholder: 'Enter Steam ID',
  },
  {
    name: 'pubgUsername',
    title: 'PUBG Username',
    placeholder: 'Enter PUBG Username',
  },
  {
    name: 'valorantUserName',
    title: 'Valorant Username',
    placeholder: 'Enter Valorant Usename',

  },
  {
    name: 'valorantTag',
    title: "Valorant Tag",
    placeholder: 'Enter Valorant Tag'

  }
]

function WizardGameFormComponent(props) {
  const [id, setId] = useState()
  const [tagId, setTagId] = useState()
  const [loading, setLoading] = useState()
  const { gameId, userParseObject } = props
  const requiredItem = storedGame.find((game) => game.overwolfId === +gameId).requiredItemsToPlay
  const gameID = storedGame.find((game) => game.overwolfId === +gameId).overwolfId

  const handleSavePubgUsername = async () => {
    if (id) {
      setLoading(true)
      try {
        const checkPubgUsernameCloud = await Parse.Cloud.run('check-pubg-username', {
          username: id,
        })

        if (checkPubgUsernameCloud) {
          try {
            const platforms = userParseObject.get('platforms')

            const newPlatform = {
              ...platforms,
              steam: {
                ...platforms?.steam,
                games: {
                  ...platforms?.steam?.games,
                  10906: {
                    ...platforms?.steam?.games?.['10906'],
                    name: 'pubg',
                    userName: id,
                  },
                },
              },
            }

            userParseObject.set('platforms', newPlatform)
            await userParseObject.save()

            customToast('Pubg username saved successfully', 'success')

            fetchCurrentUser()
            props.ready(true , true)
            setLoading(false)
          } catch (error) {
            customToast(error.message, 'danger')
            console.log(error)
          }
        }
      } catch (error) {
        customToast('PUBG Username is not valid', 'danger')
        console.log(error)
        setLoading(false)
      }
    } else if (!id) {
      customToast('Please fill the input', 'warning')
      setLoading(false)
    }
  }
  const handleSaveValorantIdandTag = async () => {
    setLoading(true)
    try {
      const valorantPlayerPlatformData = await Parse.Cloud.run('check-valorant-username', {
        username: id.trim(),
        tag: tagId.trim(),
      })
      if (valorantPlayerPlatformData) {
        if (id) {
          try {
            const platforms = userParseObject.get('platforms')
            const newPlatform = {
              ...platforms,
              riot: {
                ...platforms?.riot,
                games: {
                  ...platforms?.riot?.games,
                  21640: {
                    ...platforms?.riot?.games?.['21640'],
                    name: 'valorant',
                    userName: valorantPlayerPlatformData.name,
                    tag: valorantPlayerPlatformData.tag
                  },
                }
              },
            }
            userParseObject.set('platforms', newPlatform)
            await userParseObject.save()

            customToast('Valorant ID saved successfully', 'success')
            fetchCurrentUser()
            props.ready(true , true)
            setLoading(false)

          } catch (error) {
            customToast(error.message, 'danger')
            console.log(error)
          }
        } else if (!id) {
          customToast('Please fill the inputs', 'warning')
          setLoading(false)
        }
      }
    } catch (error) {
      customToast('Valorant ID or Tag are not valid', 'danger')
      setLoading(false)
      console.log(error)
    }
  }

  const stateHandler = {
    0: setId,
    1: setTagId
  }

  const saveIdEvents = {
    10906: handleSavePubgUsername,
    21640: handleSaveValorantIdandTag
  }

  return (
    <div className=" p-4 mt-5" style={{ border: '2px solid #343a40' }}>
      <p className=" text-white mb-5 mt-3">Please Enter requirement field to enter this game</p>
      <div className="row d-flex justify-content-between align-items-center  pb-2">
        {requiredItem.map((requiredItemToPlay, index) => {
          const inputConfig = inputsConfig.find((input) => input.name === requiredItemToPlay)
          const stateHandleFunction = stateHandler[index]
          return (
            <div key={index} className='col-md-5'>
              <p className="text-left text-white">{inputConfig.title}</p>
              <input
                type="text"
                className="form-control bg-white  p-2"
                id="input-steam-id"
                placeholder={inputConfig.placeholder}
                onChange={(e) => stateHandleFunction(e.target.value)}
              />
            </div>
          )
        }
        )}
        <div className="col-md-2 d-flex align-items-end mt-4 ">
          <button disabled={loading} className="btn btn-primary btn-sm mt-2" onClick={saveIdEvents[gameID]}>
            {loading ? <div className="spinner-border text-dark" style={{ width: '.7rem', height: '.1rem', zIndex: 100, padding: '5px' }} role="status" ></div> : 'Save'}
          </button>
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = (state) => ({
  userParseObject: state.user.userParseObject,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
})

const WizardGameForm = connect(mapStateToProps, mapDispatchToProps)(WizardGameFormComponent)
export { WizardGameForm }







