import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  Home,
  GamesJoin,
  GamesHost,
  Login,
  Signup,
  Profile,
  Join,
  Host,
  Competition,
  SteamAuth,
  // Ico,
  // Swap,
  // Wire,
  // OnlinePayment,
  // Success,
  // Cancel,
  // Transaction,
 // AirdroppedTokens,
  PageNotFound,
} from "pages";
import {
  AccountStatus,
  VerifyIdentity,
  GamingProfile,
  ChangeUserName,
  ChangeEmail,
  ChangePassword,
  Referral,
} from "pages/profile/pages/index";
import { LogOut } from "./LogOut";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="join" element={<GamesJoin />} />
      <Route path="join/game/:gameId" element={<Join />} />
      <Route path="host" element={<GamesHost />} />
      <Route path="host/game/:gameId" element={<Host />} />
      <Route path="active-competition" element={<Competition />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      {/* <Route path="transactions" element={<Transaction />} /> */}
      {/* <Route path="airdropped-tokens" element={<AirdroppedTokens/>}/> */}
      <Route path="logout" element={<LogOut />} />
      <Route path="steam-auth" element={<SteamAuth />} />
      {/* <Route path="ico" element={<Ico />} />
      <Route path="ico/swap" element={<Swap />} />
      <Route path="ico/wire" element={<Wire />} />
      <Route path="ico/online-payment" element={<OnlinePayment />} />
      <Route path="ico/online-payment/success" element={<Success />} />
      <Route path="ico/online-payment/canceled" element={<Cancel />} /> */}
      <Route path="profile" element={<Profile />}>
        <Route path="referral" element={<Referral />} />
        <Route path="change-email" element={<ChangeEmail />} />
        <Route path="change-username" element={<ChangeUserName />} />
        <Route path="account-status" element={<AccountStatus />} />
        <Route path="verify-identity" element={<VerifyIdentity />} />
        <Route path="gaming-profile" element={<GamingProfile />} />
        <Route path="change-password" element={<ChangePassword />} />
      </Route>
      <Route path="404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export { AppRoutes };
