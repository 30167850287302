import React, { useEffect } from 'react'
import Parse from 'parse/lib/browser/Parse'

function UsernameTextField(props) {
  const { className, id, placeholder, containerClassName } = props
  const [available, setAvailable] = React.useState(false)
  const [value, setValue] = React.useState('')

  useEffect(() => {
    const apiCallTimeOut = setTimeout(() => {
      if (value !== '') callUsernameAvailabilityService()
    }, 1000)

    return () => clearTimeout(apiCallTimeOut)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, available])

  const callUsernameAvailabilityService = async () => {
    try {
      const usernameAvailability = await Parse.Cloud.run('check-username-availability', { username: value })
      setAvailable(usernameAvailability)
      props.usernameAvailability(usernameAvailability)
      props.username(value)
    } catch (error) {
      console.log(error.message)
    }
  }

  const generateCssClass = () => {
    if (value && available) return ` username-input ${className}  has-success`
    else if (value && !available) return ` username-input ${className}  has-error`
    else return ` username-input ${className} `
  }

  return (
    <div className={`text-field-container ${containerClassName}`}>
      <input
        type="text"
        className={generateCssClass()}
        id={id}
        placeholder={placeholder ? placeholder : 'Username'}
        onChange={(event) => setValue(event.target.value.toLowerCase())}
      />
    </div>
  )
}

export { UsernameTextField }
