import React, { useEffect } from 'react'
import Parse from 'parse';
import { Preloader } from 'components';
import { Navigate } from "react-router-dom";

function LogOut() {
  
  useEffect(() => {
    (async () => {
      await Parse.User.logOut()
    })()
  }, [])

  return (
    <>
    <Preloader/>
    <Navigate to={'/login'}/>
    </>
  )
}

export { LogOut }