import React from 'react'
import { Preloader } from 'components'
import { HomeLayout } from 'layouts'
import { HomeContent } from './components/HomeContent'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <HomeLayout>
      <Helmet>
        <title>EVOLVE - The Evolution Of Gaming</title>
      </Helmet>
      <HomeContent />
      <Preloader />
    </HomeLayout >
  )
}
export { Home }
