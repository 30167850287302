import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { findGameById } from 'utils/helper';
import { WizardGameForm } from 'components/wizardGameForm/WizardGameForm';
import { userHasRequiredItems } from 'utils/helper';

function GameRequirementChecker({ user, nextStep , backStep }) {
  const { gameId } = useParams()

  const gameAvatarHref = findGameById(+gameId).backgroundImageUrl

  useEffect(() => {
    const hasRequiredItems = userHasRequiredItems(user, gameId)
    if (hasRequiredItems) {
      nextStep()
    }
    // eslint-disable-next-line 
  }, [])


  return (
    <main className=" site-content text-center col-10 mb-5 " >
      <div className="site-content__center ">
        <div className="match-stats-widget match-stats-widget--general my-3">
          <ul className="match-stats-widget__body" id='list'>
            <li>
              <figure className="match-team match-team--winner" role="group">
                <figure
                  className="match-team-logo match-team-logo--team-1"
                  role="group">
                  <img
                    style={{ width: '100px' }}
                    src={gameAvatarHref}
                    srcSet={gameAvatarHref}
                    alt="Logo"
                  />
                </figure>
              </figure>
            </li>
          </ul>
          <WizardGameForm gameId={gameId} ready={(bool , first) => {
            if (bool && first) {
              backStep()
            } else if (bool){
              nextStep()
            }
          }}
          />
        </div>
      </div>
    </main>
  )
}

export { GameRequirementChecker }