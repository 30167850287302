import React, { useEffect, useState } from 'react'
import { CompetitionStatus, findGameById } from 'utils/helper'

function Progress({ side1Members, side2Members, preset, competitorStatus, competition }) {
  const [gameTitle, setGameTitle] = useState('')
  const [gameMode, setGameMode] = useState('')
  const [userHasApiStats, setUserHasApiStats] = useState(false)
  const date = new Date();

  useEffect(() => {
    const game = findGameById(+preset?.get('game')?.get('overwolfId'))
    const gameMode = preset?.get('settings')?.title
    setGameTitle(game?.gameName)
    setGameMode(gameMode)
  }, [preset])

  useEffect(() => {
    if (competitorStatus?.get('apiStats') || competitorStatus?.get('apiRespAfter')) {
      setUserHasApiStats(true)
    }
  }, [competitorStatus])



  const getUserName = (competitorStatus) => {
    const playerData = competitorStatus?.toJSON().publicUserInfo
    if (playerData) {
      return playerData
    }
  }

  return (
    <div className="matches-tabs mt-sm-3 mb-sm-auto">
      <div className="d-flex flex-column align-items-center">
        <div className="matches-tabs__content tab-content">
          {!userHasApiStats && (
            <div className="alert alert-info" role="alert">
              {preset.get('description') || (
                <>
                  Open <strong className="font-18">{gameTitle}</strong> and start playing in{' '}
                  <strong className="font-18">{gameMode}</strong> mode
                </>
              )}
            </div>
          )}
          {
            userHasApiStats && competition.get('status') === CompetitionStatus.IN_PROGRESS && competitorStatus.get('status') === 'FINISH_BY_API' &&
            <div className="alert alert-info" role="alert">
              It seems that your match in the game has ended and we have your result, but others are still playing so you should wait until everybody is finished with their match...
            </div>
            
          }
          {
            userHasApiStats && competition.get('status') === CompetitionStatus.COMPLETED &&
            <div className="alert alert-info" role="alert">
              It seems that everybody is finished with their matches, Processing match results and concluding Stable Coin competition result...
            </div>
          }
          <div className="match-lineups-container tab-pane fade show active" id="tab-1" role="tabpanel">
            <table className="matches-table lineups-table lineups-table--style-1">
              <thead>
                <tr>
                  <th style={{ fontSize: '30px', textShadow: '0px 1px 2px #fff' }}>TEAM A</th>
                  <th className="td-hero td-d-none-sm td-center-left ">Status</th>
                  <th className="td-hero td-d-none-sm td-center-right ">Status</th>
                  <th style={{ fontSize: '30px', textShadow: '0px 1px 2px #fff' }}>TEAM B</th>
                </tr>
              </thead>
              <tbody>
                {side1Members.map((member, index) => (
                  <tr key={index}>
                    <td>
                      <figure className="match-player team-1" role="group">
                        <figure className="match-player__avatar">
                          <img src="/assets/img/samples/match-player-01-90x60.png" alt="evolve" />
                        </figure>
                        <figcaption>
                          <span className="match-player__nickname">
                          {getUserName(side1Members[index]).gamerid}
                          </span>
                          <span className="match-player__name">
                            {getUserName(side1Members[index]).username}

                          </span>
                        </figcaption>
                      </figure>
                    </td>
                    <td className="td-d-none-sm td-center-left">
                      <span className="match-player__status font-16 font-weight-light">
                        {side1Members[index]?.get('balance') || side1Members[index]?.get('matchEndedAt') > date || !side1Members[index]?.get('matchEndedAt') ? (
                          'Waiting for match to finish'
                        ) : (
                          <>Game Finished</>
                        )}
                      </span>
                    </td>
                    <td className="td-d-none-sm td-center-right">
                      <span className="match-player__status font-16 font-weight-light">
                        {side2Members[index]?.get('balance') || side2Members[index]?.get('matchEndedAt') > date || !side2Members[index]?.get('matchEndedAt') ? (
                          'Waiting for match to finish'
                        ) : (
                          <>Game Finished</>
                        )}
                      </span>
                    </td>
                    <td>
                      <figure className="match-player team-2" role="group">
                        <figure className="match-player__avatar">
                          <img src="/assets/img/samples/match-player-unknow-right-90x60.png" alt="evolve" />
                        </figure>
                        <figcaption>
                        <span className="match-player__nickname">
                          {getUserName(side2Members[index]).gamerid}
                          </span>
                          <span className="match-player__name">
                            {getUserName(side2Members[index]).username}
                          </span>
                        </figcaption>
                      </figure>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Progress }
