import * as actions from './index';
import * as crud from '../cruds/configCrud'


export const getConfig = () => async (dispatch) => {
  try {
    const result = await crud.fetchConfig()
    const configs = Object.fromEntries(result.map(configObj => {
      const type = configObj.get('type') === 'md' ? 'html' : configObj.get('type')
      return [configObj.get('variable'), configObj.get(type + 'Value')]
    }));
    dispatch({
      type: actions.SET_CONFIG,
      payload: configs
    })
  } catch (error) {
    dispatch({
      type: actions.SET_CONFIG_ERROR,
      error: error
    })
  }
}

export const setConfig = (changed) => async (dispatch) => {
  try {
    dispatch({
      type: actions.SET_CONFIG_CHANGES,
      payload: changed
    })
  } catch (error) {
    dispatch({
      type: actions.SET_CONFIG_CHANGES_ERROR,
      payload: changed
    })
  }
}

export const setLoading = (bool) => {
  return {
    type: actions.SET_LOADING,
    payload: {
      loading: bool
    }
  }
}
